import * as types from './types';

const initialState = {
  modalParams: {},
  [types.DRAWER_PARAM_ADD_DEAL]: false,
  [types.DRAWER_PARAM_DEAL_PREVIEW]: false,
  [types.DRAWER_PARAM_ADD_CONTACT]: false,
  [types.DRAWER_PARAM_CONTACT_PREVIEW]: false,
  [types.DRAWER_PARAM_IMPORT]: false,
  [types.DRAWER_PARAM_MATCH_LIST_FILTERS]: false,
  [types.DEAL_FILTERS_DRAWER]: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_DRAWER_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
