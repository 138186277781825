import { DEALS_QUESTIONS } from '@zorba-shared/core/Constants/organization';
import { buildHomePageUrl } from '@zorba-shared/core/Services/urlBuilderService';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  createOrganization,
  updateOrganizationDetails,
  updateUserDetails,
} from '../../../reducers/Auth/actions';
import { setModalState } from '../../../reducers/Modal/actions';

import {
  reportEvent,
  setUserProperties,
  setUserProperty,
} from '../../../services/analyticsService';
import { showErrorToast } from '../../../services/notificationsService';
import {
  buildOnboardingPageUrl,
  getRedirectUrl,
} from '../../../services/urlBuilderService';
import OnboardingStepView from './Components/OnboardingStepView';
import * as constants from './constants';
import './index.scss';
import {
  getAreasLongString,
  getStepParams,
  validateOnboarding,
} from './service';
import { USER_TYPE_AGENT } from '../../../constants/user';

const OnboardingWizard = (props) => {
  const { match, userDetails } = props;
  const dispatch = useDispatch();
  const [wizardSteps, setWizardSteps] = useState(
    constants.WIZARD_STEPS_BY_INTEREST[USER_TYPE_AGENT],
  );
  const [validationObj, setValidationObj] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    ...constants.ONBOARDING_DEFAULT_PARAMS,
  });
  const [stepIndex, setStepIndex] = useState(0);

  const currentStepKey = wizardSteps[stepIndex];

  useEffect(() => {
    reportEvent('onboarding', 'start');
    const stepKey = match?.params?.stepKey;
    if (stepKey !== constants.ONBOARDING_STEP_BUYING_CRITERIA) {
      dispatch(push(buildOnboardingPageUrl()));
    }
  }, []);

  const navigatePage = (newStepIndex) => {
    const newStepKey = wizardSteps[newStepIndex];
    setStepIndex(stepIndex + 1);
    const newUrl = buildOnboardingPageUrl(newStepKey);
    reportEvent('onboarding', newStepKey);
    dispatch(push(newUrl));
  };

  /** Triggered when the user click Continue */
  const onContinue = () => {
    const { isValid, validationObj } = validateOnboarding(
      params,
      currentStepKey,
    );
    setValidationObj(validationObj);
    if (!isValid)
      return showErrorToast(
        validationObj.customError || 'Fill required fields',
      );
    reportEvent('onboarding', `${currentStepKey}_next_click`);
    // IN CASE THIS IS THE LAST STEP (PHONE NUMBER STEP)
    if (stepIndex === wizardSteps.length - 1) {
      reportEvent('onboarding', 'success', { ...params });
      // this is the end
      const stageParams = getStepParams(currentStepKey, params);

      setUserProperties(userDetails);

      dispatch(
        updateUserDetails(
          {
            kind: stageParams.kind,
            phoneNumber: stageParams.phoneNumber,
            isCompletedOnboarding: true,
            onboarding_mode: true,
          },
          (updatedUser) => {
            setUserProperty('completed_onboarding', true, updatedUser._id);
            const numOfDealsValue = params.numOfDealsQuestion
              ? DEALS_QUESTIONS[params.numOfDealsQuestion]
                  .replace('+', '_more')
                  .replace('-', '_')
              : undefined;
            setUserProperty(
              'company',
              updatedUser.defaultOrganization.name,
              updatedUser?._id,
            );
            setUserProperty('num_of_deals', numOfDealsValue, updatedUser?._id);
            setUserProperty(
              'areas',
              getAreasLongString(params.buyBoxList),
              updatedUser?._id,
            );
            reportEvent('onboarding', 'completed', {
              num_of_deals: numOfDealsValue,
              areas: getAreasLongString(params.buyBoxList),
            });

            let redirectUrl = getRedirectUrl();

            if (!redirectUrl) {
              redirectUrl = buildHomePageUrl(false);
            }
            dispatch(push(redirectUrl));
          },
        ),
      );
      return;
    }

    navigatePage(stepIndex + 1);

    const currentStepParams = getStepParams(currentStepKey, params);

    if (
      Object.values(currentStepParams).filter((x) => x && x.length > 0).length >
      0
    ) {
      dispatch(
        updateUserDetails({
          ...currentStepParams,
          // organizationId: userDetails?.defaultOrganization?._id,
          onboarding_mode: true,
          organizationName: params.organizationName || 'Unnamed',
        }),
      );
      const updatedParams = { ...userDetails, ...currentStepParams };
      if (currentStepParams.kind) {
        updatedParams.kind = [currentStepParams.kind];
      }
      setUserProperties(updatedParams);
    }
  };

  /** Triggered when the user click Back */
  const onBack = () => {
    navigatePage(stepIndex - 1);
  };
  const onChange = (key, value) => {
    setParams({ ...params, [key]: value });
  };

  return (
    <div className={`onboarding-wizard ${currentStepKey}`}>
      <OnboardingStepView
        {...props}
        validationObj={validationObj}
        currentStepKey={currentStepKey}
        onContinue={onContinue}
        onBack={onBack}
        params={params}
        onChange={onChange}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        wizardSteps={wizardSteps}
        stepIndex={stepIndex}
      />
    </div>
  );
};

const mapStateToProps = ({ AuthReducer }) => {
  const { userDetails, organization } = AuthReducer;

  return {
    userDetails,
    organization,
  };
};
const mapDispatchToProps = {
  push,
  setModalState,
  updateUserDetails,
  createOrganization,
  updateOrganizationDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingWizard);
