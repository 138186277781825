const getParamName = (flow) => `is${flow}ModalOpen`;
export const SET_MODAL_STATE = 'SET_MODAL_STATE';
export const MODAL_PARAM_ONBOARDING = getParamName('OnboardingModal');
export const MODAL_PARAM_PHONE_UPDATE = getParamName('PhoneUpdate');
export const MODAL_PARAM_INVITE_ORGANIZATION_MEMBERS = getParamName(
  'InviteOrganizationMembers',
);

export const MODAL_PARAM_EMAIL_VERIFICATION = getParamName('EmailVerification');
export const MODAL_PARAM_PHONE_VERIFICATION = getParamName('PhoneVerification');
export const MODAL_PARAM_UPLOAD_CONTRACT = getParamName('UploadContract');
export const MODAL_PARAM_REQUEST_REEVALUATION = getParamName(
  'RequestReevaluation',
);

export const MODAL_PARAM_INVITE_MEMBERS = getParamName('InviteMembers');

export const ALLOWED_MODALS_TO_OPEN_BY_QUERY = [];
export const MODAL_PARAM_MOBILE_DEAL_DETAILS = getParamName('MobileDeal');

export const MODAL_TRAINING = getParamName('Training');

export const MODAL_PRE_DEAL_ACCEPT_OFFER = getParamName('PreDealAcceptOffer');

export const PRE_DEALS_MODAL_TRAINING = getParamName('PreDealsTraining');
