import classNames from 'classnames';
import { Card } from 'antd';
import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Page } from '../../components/layout';
import { getQueryParam } from '../../services/clientService';
import { getRequest, postRequest } from '../../services/requestService';
import DefaultMasterPage from '../masterPages/DefaultMasterPage';
import './index.scss';
import { ZorbaButton } from '../../components/forms';
import { BUTTON_VARIANT_SECONDARY } from '../../components/forms/ZorbaButton/constants';
import { showSuccessToast } from '../../services/notificationsService';
import { setModalState } from '../../reducers/Modal/actions';
import { MODAL_PARAM_EMAIL_VERIFICATION } from '../../reducers/Modal/types';
import { reportEvent } from '../../services/analyticsService';

const EmailConfirmationPage = (props) => {
  const { match } = props;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    init();
  }, []);
  const init = async () => {
    const emailConfirmationToken = getQueryParam('email_code');
    if (emailConfirmationToken) {
    }
    const res = await postRequest('/auth/confirm-email', {
      emailConfirmationToken,
    });
    if (res?.data?.success) {
      reportEvent('auth', 'email_verified');
      // dispatch(
      //   push(userDetails.isCompletedOnboarding ? "/" : buildOnboardingPageUrl())
      // );
      dispatch(push('/'));
      showSuccessToast('Your email was verified successfully');
    } else {
      dispatch(setModalState(MODAL_PARAM_EMAIL_VERIFICATION, true));
    }
  };
  const onSubmit = async () => {
    setLoading(true);
    await postRequest('/auth/request-email-confirmation');
    setLoading(false);
    setSuccess(true);
  };

  return (
    <DefaultMasterPage>
      <Page {...props} className={classNames('email-confirmation-page')}>
        <Card>
          <div className="confirm-icon">
            <i
              className={
                success ? 'fas fa-check-circle success' : 'fas fa-times-circle'
              }
            />
          </div>
          {!loading && (
            <div className="confirm-msg">
              {success
                ? 'Check your email inbox, we sent you instructions to confirm your email address.'
                : 'Your email confirmation was already expired, please try again.'}
            </div>
          )}
          {!success && (
            <ZorbaButton
              loading={loading}
              variant={BUTTON_VARIANT_SECONDARY}
              onClick={onSubmit}
            >
              Send confirmation email
            </ZorbaButton>
          )}
        </Card>
      </Page>
    </DefaultMasterPage>
  );
};

export default EmailConfirmationPage;
// export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmationPage);
