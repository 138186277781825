import { useEffect, useState } from 'react';
import { Modal, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { BUYER_REJECTION_FRIENDLY_MESSAGE } from '@zorba-shared/core/Constants/offer';

import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_PARAM_MOBILE_DEAL_DETAILS } from '../../../../../../reducers/Modal/types';
import { getFriendlyTime } from '../../../../../../services/textService';
import { getStatusTag, DEAL_TABS } from '../constants';
import { OfferDetails } from '../OfferDetails';
import { UnderwritingResult } from './Components/UnderwritingResult';
import { UserData } from './Components/UserData';
import { setSelectedDeal } from '../../../../../../reducers/Property/actions';
import { ZorbaButton } from '../../../../../../components/forms';
import { BUTTON_VARIANT_SECONDARY } from '../../../../../../components/forms/ZorbaButton/constants';

import './index.scss';

const MobileDealModal = ({ onRequestContract, handleOpenModal, isContractLoading }) => {
  const { selectedDeal } = useSelector(
    ({ PropertyReducer }) => PropertyReducer,
  );

  const [selectedTab, setSelectedTab] = useState();
  const dispatch = useDispatch();
  const { isMobileDealModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const history = useHistory();
  const location = useLocation();
  const initialTab =
    selectedDeal?.step === 'revised_offer'
      ? DEAL_TABS.OUR_OFFER
      : DEAL_TABS.YOUR_INFO;

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [initialTab]);

  if (!selectedDeal) return null;

  const handleDrawerClose = () => {
    const queryParams = new URLSearchParams(location.search);
    const dealSubmissionId = queryParams.get('deal_submission_id');

    if (dealSubmissionId) {
      history.replace('/');
    }
    dispatch(setModalState(MODAL_PARAM_MOBILE_DEAL_DETAILS, false));
    dispatch(setSelectedDeal(null));
  };

  const shouldDisableUnderwriting = !(
    selectedDeal.underwriting?.arv ||
    selectedDeal.underwriting?.rent ||
    selectedDeal.underwriting?.rehabCost
  );

  const getRejectionReason = () => {
    if (selectedDeal.step === 'rejected_by_uw') {
      return selectedDeal.underwriting?.rejectionMessageToSeller
        ? selectedDeal.underwriting?.rejectionMessageToSeller
        : selectedDeal?.reasons.join(', ');
    }
    if (selectedDeal.step === 'rejected_by_buyer') {
      const lastEvent = _.last(
        selectedDeal.events.filter((event) => event.kind === 'buyer_reject'),
      );

      return `${BUYER_REJECTION_FRIENDLY_MESSAGE[lastEvent?.message]}`;
    }

    return '';
  };

  return (
    <Modal
      className="deal-drawer"
      onCancel={handleDrawerClose}
      open={isMobileDealModalOpen}
      footer={null}
      destroyOnClose
    >
      <div className="deal-details-container">
        <span className="last-updated-title">
          Last updated {getFriendlyTime(selectedDeal.updatedAt)}
        </span>
        <div>{getStatusTag(selectedDeal.step)}</div>
        <span className="address-title">
          {selectedDeal.address.formattedAddress}
        </span>
        {(selectedDeal.step === 'rejected_by_uw' ||
          selectedDeal.step === 'rejected_by_buyer') && (
          <div>
            <span className="rejection-reason-title">{`Reason: `}</span>
            <span className="rejection-reason">
              {getRejectionReason(selectedDeal)}
            </span>
          </div>
        )}
        {selectedDeal.step === 'rejected_by_buyer' && (
          <div>
            <ZorbaButton
              variant={BUTTON_VARIANT_SECONDARY}
              onClick={() => handleOpenModal(selectedDeal)}
            >
              Request reevaluation
            </ZorbaButton>
          </div>
        )}
      </div>
      {selectedDeal.step !== 'rejected_by_buyer' &&
        selectedDeal.step !== 'rejected_by_uw' && (
          <>
            <div className="radio-container">
              <Radio.Group
                size="middle"
                value={selectedTab}
                onChange={(e) => setSelectedTab(e.target.value)}
              >
                <Radio.Button value={DEAL_TABS.YOUR_INFO}>
                  Your info
                </Radio.Button>
                <Radio.Button
                  value={DEAL_TABS.UNDERWRITING_RESULT}
                  disabled={shouldDisableUnderwriting}
                >
                  Underwriting result
                </Radio.Button>
                <Radio.Button
                  value={DEAL_TABS.OUR_OFFER}
                  disabled={selectedDeal.step !== 'revised_offer'}
                >
                  Our offer
                </Radio.Button>
              </Radio.Group>
            </div>
            {selectedTab === DEAL_TABS.OUR_OFFER && (
              <OfferDetails
                record={selectedDeal}
                onRequestContract={onRequestContract}
                isContractLoading={isContractLoading}
              />
            )}
            {selectedTab === DEAL_TABS.UNDERWRITING_RESULT && (
              <UnderwritingResult selectedDeal={selectedDeal} />
            )}
            {selectedTab === DEAL_TABS.YOUR_INFO && (
              <UserData selectedDeal={selectedDeal} />
            )}
          </>
        )}
    </Modal>
  );
};

export default MobileDealModal;
