import Cookies from 'js-cookie';
import { getCookie } from '@zorba-shared/client/Services/clientService';
import { removeCookie, getQueryParam } from './clientService';
import { clearLatestViewedEntities } from './crm/latestActivityService';
import { postRequest } from './requestService';
import { buildLoginUrlWithRedirect } from './urlBuilderService';

export const QUERY_PARAM_FORCE_AUTH = 'force_auth';
export const QUERY_PARAM_USER_ID = 'uid';
export const QUERY_PARAM_MODAL = 'modal';
export const QUERY_PARAM_ORG_INVITE = 'org_invite';
export const QUERY_PARAM_COUPON = 'coupon';
export const QUERY_PARAM_REDIRECT_URL = 'redirect_url';
export const QUERY_PARAM_MAGIC_TOKEN = 'token';

export const AUTH_STRATEGY_FACEBOOK = 'facebook';
export const AUTH_STRATEGY_GOOGLE = 'google';

export const QUERY_PARAM_DEAL_SUBMISSION_ID = 'deal_submission_id';

export const COOKIE_NAME_AUTH_TOKEN = 'x-access-token';

export const setAuthToken = (token) => {
  Cookies.set(COOKIE_NAME_AUTH_TOKEN, token, { expires: 365 });
};

export const getAuthToken = () => Cookies.get(COOKIE_NAME_AUTH_TOKEN);

export const logoutUser = async (shouldRedirectToLogin = false) => {
  const res = await postRequest('/auth/logout');
  try {
    clearLatestViewedEntities();
    const auth2 = window.gapi.auth2.getAuthInstance();
    if (auth2 != null) {
      auth2.signOut().then(auth2.disconnect().then((e) => {}));
    }
  } catch (e) {}
  removeCookie(COOKIE_NAME_AUTH_TOKEN, '.getzorba.com');
  Cookies.remove(COOKIE_NAME_AUTH_TOKEN);

  if (shouldRedirectToLogin) {
    window.location.href = buildLoginUrlWithRedirect(false, true);
  } else {
    window.location.reload();
  }
};

export const setDealSubmissionIdCookie = () => {
  const dealSubmissionId = getQueryParam(QUERY_PARAM_DEAL_SUBMISSION_ID);

  if (dealSubmissionId) {
    Cookies.set(QUERY_PARAM_DEAL_SUBMISSION_ID, dealSubmissionId, {
      expires: 1,
    });
  }
};

export const postRefreshTasks = () => {
  const orgInvite = getQueryParam(QUERY_PARAM_ORG_INVITE);

  if (orgInvite) {
    Cookies.set(QUERY_PARAM_ORG_INVITE, orgInvite, { expires: 1 });
  }

  const coupon = getQueryParam(QUERY_PARAM_COUPON);
  if (coupon) {
    Cookies.set(QUERY_PARAM_COUPON, coupon, { expires: 1 });
  }
};

export const isOnboardingPage = () =>
  window.location.pathname.startsWith('/o/onboarding');

export const isEmailExists = async (email) => {
  let res = {
    emailExists: false,
  };
  try {
    const apiResponse = await postRequest('/auth/get-local-auth', {
      email: email.toLowerCase(),
      kind: getCookie('user_kind'),
    });

    res = {
      accountExists: apiResponse?.data?.accountExists,
      valid: !!apiResponse?.data,
    };
  } catch (e) {}

  return res;
};

export const isPhoneNumberVerified = (userDetails) =>
  !!userDetails?.phoneNumber?.verified;

export const isAuthenticationPage = () => {
  const { pathname } = window.location;
  return pathname.startsWith('/auth');
};
