import { FcInfo } from '@react-icons/all-files/fc/FcInfo';

import { ZorbaButton } from '../../../../../../components/forms';
import { AcceptOfferModal } from '../AcceptOfferModal';

export const AcceptOffer = ({
  record,
  onRequestContract,
  setIsContractLoading,
  isDoubleClose,
  isPreInspection,
}) => (
  <>
    <div className="counter-accept-offer-container">
      <ZorbaButton
        className="counter-accept-button"
        onClick={onRequestContract}
      >
        {isPreInspection ? 'Schedule pre-inspection' : 'Request contract'}
      </ZorbaButton>
    </div>
    {isDoubleClose && (
      <div className="counter-offer-info">
        <div className="icon-container">
          <FcInfo size={25} />
        </div>
        {`Please be aware that a double closing is required for this property.
            However, clicking ${
              isPreInspection ? 'Schedule pre-inspection' : 'Request contract'
            } does not commit you to this process.
            After your selection, a representative will contact you to discuss
            the available options in detail.`}
      </div>
    )}
    <AcceptOfferModal
      setIsContractLoading={setIsContractLoading}
      preDeal={record}
    />
  </>
);
