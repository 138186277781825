export const SUBMIT_PROPERTY_STEP_DRAFT = 'draft';
export const SUBMIT_PROPERTY_STEP_MISSING_INFO = 'missing_info';
export const SUBMIT_PROPERTY_STEP_REJECTED_BY_UW = 'rejected_by_uw';
export const SUBMIT_PROPERTY_STEP_REJECTED_BY_SELLER = 'rejected_by_seller';

export const SUBMIT_PROPERTY_STEP_REJECTED_BY_BUYER = 'rejected_by_buyer';
export const SUBMIT_PROPERTY_STEP_SUBMITTED_TO_UW = 'review';
export const SUBMIT_PROPERTY_STEP_REVISED_OFFER = 'revised_offer';
export const SUBMIT_PROPERTY_STEP_PENDING_AGREEMENT = 'pending_agreement';
export const SUBMIT_PROPERTY_STEP_CLOSED_WON = 'closed_won';

export const SUBMIT_PROPERTY_STEP_CONFIRM_DETAILS = 'confirm_details';

export const SUBMIT_PROPERTY_STEP_PRE_CONFIRM_DETAILS = 'pre_confirm_details';

export const SUBMISSION_WIZARD_STEPS_TITLES = {
  [SUBMIT_PROPERTY_STEP_DRAFT]: 'Draft',
  [SUBMIT_PROPERTY_STEP_MISSING_INFO]: 'Missing PSA',
  [SUBMIT_PROPERTY_STEP_SUBMITTED_TO_UW]: 'Under review',
  [SUBMIT_PROPERTY_STEP_REJECTED_BY_UW]: 'Rejected by Zorba',
  [SUBMIT_PROPERTY_STEP_REJECTED_BY_SELLER]: 'Rejected by you',
  [SUBMIT_PROPERTY_STEP_REVISED_OFFER]: 'Ready for contract',
  [SUBMIT_PROPERTY_STEP_PENDING_AGREEMENT]: 'Pending agreement',
  [SUBMIT_PROPERTY_STEP_CLOSED_WON]: 'Closed won',
};

export const SUBMISSION_WIZARD_STEPS = [
  SUBMIT_PROPERTY_STEP_PRE_CONFIRM_DETAILS,
  SUBMIT_PROPERTY_STEP_CONFIRM_DETAILS,
  SUBMIT_PROPERTY_STEP_DRAFT,
  SUBMIT_PROPERTY_STEP_MISSING_INFO,
  SUBMIT_PROPERTY_STEP_REJECTED_BY_UW,
  SUBMIT_PROPERTY_STEP_SUBMITTED_TO_UW,
  SUBMIT_PROPERTY_STEP_REJECTED_BY_SELLER,
];

export const isValidURL = (url) => {
  const urlRegex = /^(http[s]?:\/\/)?(www\.)?[\w.-]+\.\w+/;
  return urlRegex.test(url);
};