import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { getStepComponent, loadCurrentStep } from './service';

import './index.scss';

export default (props) => {
  const { preDeal } = props;
  const [wizardStep, setWizardStep] = useState();
  useEffect(() => {
    const currentWizardStep = loadCurrentStep({ preDeal });
    setWizardStep(currentWizardStep);
  }, [preDeal]);

  const stepComponent = getStepComponent(wizardStep, {
    preDeal,
    onReset: props.onReset,
    onContinue: props.onContinue,
  });
  return (
    <Modal
      className="submit-property-modal"
      open={!!preDeal && wizardStep != null}
      footer={null}
      onCancel={() => props.onReset()}
      destroyOnClose
    >
      {stepComponent}
    </Modal>
  );
};
