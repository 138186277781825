import * as types from './types';

const initialState = {
  deals: [],
  currentPage: 0,
  pageSize: 20,
  totalDeals: 0,
  totalDealsFiltered: 0,
  appliedFilters: {},
  totalOffers: 0,
  rejectedByUW: 0,
  loading: false,
  selectedDeal: null,
  isFetchingDeal: false,
  hasMoreResults: false,
  totalWholesalerAssignmentFee: 0,
  isFetchingStats: false,
  previousDealSubmissionsMap: {},
  pendingDealSubmissionsCounter: 0,
  pendingPreDealsCounter: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_PROPERTIES:
    case types.SET_CURRENT_PAGE:
    case types.SET_PAGE_SIZE:
    case types.SET_APPLIED_FILTERS:
    case types.SET_SELECTED_DEAL:
    case types.SET_STATS:
    case types.SET_PENDING_ACTIONS:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
