import _ from 'lodash';
import * as constants from './constants';
import {
  ONBOARDING_STEP_CREATE,
  ONBOARDING_STEP_BUYING_CRITERIA,
} from './constants';

export const validateOnboarding = (params, step) => {
  const {
    organizationName,
    numOfDealsQuestion,
    buyBoxList,
    phoneNumber,
  } = params;
  const validationObj = {
    areas: '',
  };
  switch (step) {
    case ONBOARDING_STEP_CREATE:
      if (organizationName?.length < 3) {
        validationObj.organizationName = 'Required. At least 3 letters';
      }
      if (!numOfDealsQuestion) {
        validationObj.numOfDealsQuestion = 'Required.';
      }
      break;
    case ONBOARDING_STEP_BUYING_CRITERIA:
      const selectedBuybox = buyBoxList[0];
      if (selectedBuybox.areas.length === 0) {
        validationObj.buyBoxAreas = 'Required';
        validationObj.customError = 'Add at least one area of interest';
      }
      break;
    case constants.ONBOARDING_STEP_PHONE:
      if (phoneNumber?.number?.length !== 10) {
        validationObj.phoneNumber = 'Fill the best number to reach you at';
      }
      break;
    default:
      break;
  }

  return {
    isValid:
      Object.values(validationObj).filter((x) => x.length > 0).length === 0,
    validationObj,
  };
};

export const getStepParams = (currentStepKey, params) => {
  switch (currentStepKey) {
    case constants.ONBOARDING_STEP_CREATE:
      return _.pick(params, [
        'organizationName',
        'numOfDealsQuestion',
        'mainInterestQuestion',
      ]);
    case constants.ONBOARDING_STEP_BUYING_CRITERIA:
      return _.pick(params, ['buyBoxList']);
    case constants.ONBOARDING_STEP_PHONE:
      return _.pick(params, ['phoneNumber', 'kind']);
    default:
      return {};
  }
};

export const getAreasLongString = (buyBoxList) => {
  if (
    !buyBoxList ||
    buyBoxList.length === 0 ||
    !buyBoxList[0].areas ||
    buyBoxList[0].areas.length === 0
  )
    return '';
  const { areas } = buyBoxList[0];

  let areasString = areas.map((area) => area?.state).filter((area) => !!area);

  areasString = _.uniq(areasString);
  areasString.map((area) => area.toLowerCase()).join(',');

  return areasString;
};
