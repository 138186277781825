import { Layout as AntLayout } from 'antd';
import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUserData } from '../../../reducers/Auth/actions';
import {
  initAnalytics,
} from '../../../services/analyticsService';
import { setInitialClientUrl } from '../../../services/userTrackingService';
import EmailVerificationModal from '../../auth/EmailVerificationModal';

import Header from '../Header';
import ScrollToTop from './Components/ScrollToTop';
import './index.scss';

const LayoutComponent = (props) => {
  useEffect(() => {
    initAnalytics();
    setInitialClientUrl();
  }, []);
  const content = (
    <>
      <Header {...props} pageName={props.pageName} />
      <div className="layout-content">{props.children}</div>

      <EmailVerificationModal {...props} />
      <ScrollToTop {...props} />
    </>
  );
  return (
    <AntLayout>
        {content}
    </AntLayout>
  );
};

const mapStateToProps = ({ AuthReducer }) => {
  const { isAppLoading } = AuthReducer;
  return { isAppLoading };
};

const mapDispatchToProps = {
  push,
  getUserData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutComponent),
);
