import RejectionStep from './Components/RejectionStep';
import SubmittedToUnderwritingStep from './Components/SubmittedToUnderwritingStep';
import ConfirmDetails from './Components/ConfirmDetails';
import PropertyInfoStep from './Components/PropertyInfoStep';
import {
  SUBMIT_PROPERTY_STEP_CONFIRM_DETAILS,
  SUBMIT_PROPERTY_STEP_MISSING_INFO,
  SUBMIT_PROPERTY_STEP_REJECTED_BY_UW,
  SUBMIT_PROPERTY_STEP_SUBMITTED_TO_UW,
} from './constants';

export const loadCurrentStep = ({ preDeal }) => {
  if (!preDeal) return null;
  if (preDeal.step === 'rejected_by_uw') {
    return SUBMIT_PROPERTY_STEP_REJECTED_BY_UW;
  }

  if (preDeal.step === 'missing_info') {
    return SUBMIT_PROPERTY_STEP_MISSING_INFO;
  }

  if (preDeal.step === 'review') {
    return SUBMIT_PROPERTY_STEP_SUBMITTED_TO_UW;
  }
  if (preDeal.step === 'confirm_details') {
    return SUBMIT_PROPERTY_STEP_CONFIRM_DETAILS;
  }

  return null;
};

export const getStepComponent = (wizardStep, props) => {
  const TYPES = {
    [SUBMIT_PROPERTY_STEP_REJECTED_BY_UW]: <RejectionStep {...props} />,
    [SUBMIT_PROPERTY_STEP_MISSING_INFO]: <PropertyInfoStep {...props} />,
    [SUBMIT_PROPERTY_STEP_SUBMITTED_TO_UW]: (
      <SubmittedToUnderwritingStep {...props} />
    ),
    [SUBMIT_PROPERTY_STEP_CONFIRM_DETAILS]: <ConfirmDetails {...props} />,
  };

  return TYPES[wizardStep];
};
