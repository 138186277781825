import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModalState } from '../../reducers/Modal/actions';
import { MODAL_TRAINING } from '../../reducers/Modal/types';

const HowItWorksPage = () => {
  const { userDetails, isAppLoading, isAuthLoading } = useSelector(
    ({ AuthReducer }) => AuthReducer,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !isAppLoading &&
      !isAuthLoading &&
      userDetails &&
      userDetails?.watchedTrainingAt
    ) {
      dispatch(setModalState(MODAL_TRAINING, true));
    }
  }, [userDetails]);

  return null;
};

export default HowItWorksPage;
