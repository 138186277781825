import PropertyInfoStep from './Components/PropertyInfoStep';
import RejectionStep from './Components/RejectionStep';
import SubmitToUnderwritingStep from './Components/SubmitToUnderwritingStep';
import SubmittedToUnderwritingStep from './Components/SubmittedToUnderwritingStep';
import ConfirmDetails from './Components/ConfirmDetails';
import PreConfirmDetails from './Components/PreConfirmDetails';
import {
  SUBMIT_PROPERTY_STEP_CONFIRM_DETAILS,
  SUBMIT_PROPERTY_STEP_MISSING_INFO,
  SUBMIT_PROPERTY_STEP_DRAFT,
  SUBMIT_PROPERTY_STEP_REJECTED_BY_UW,
  SUBMIT_PROPERTY_STEP_SUBMITTED_TO_UW,
  SUBMIT_PROPERTY_STEP_PRE_CONFIRM_DETAILS,
} from './constants';

export const loadCurrentStep = ({
  dealSubmission,
  previouslySubmittedDealSubmissions,
}) => {
  if (!dealSubmission) return null;
  if (dealSubmission.step === 'rejected_by_uw')
    return SUBMIT_PROPERTY_STEP_REJECTED_BY_UW;

  if (dealSubmission.step === 'draft') return SUBMIT_PROPERTY_STEP_DRAFT;

  if (dealSubmission.step === 'missing_info')
    return SUBMIT_PROPERTY_STEP_MISSING_INFO;

  if (dealSubmission.step === 'review') {
    return SUBMIT_PROPERTY_STEP_SUBMITTED_TO_UW;
  }
  if (
    dealSubmission.step === 'confirm_details' &&
    previouslySubmittedDealSubmissions?.length
  ) {
    return SUBMIT_PROPERTY_STEP_PRE_CONFIRM_DETAILS;
  }
  if (dealSubmission.step === 'confirm_details') {
    return SUBMIT_PROPERTY_STEP_CONFIRM_DETAILS;
  }

  return null;
};

export const getStepComponent = (wizardStep, props) => {
  const TYPES = {
    [SUBMIT_PROPERTY_STEP_REJECTED_BY_UW]: <RejectionStep {...props} />,
    [SUBMIT_PROPERTY_STEP_DRAFT]: <PropertyInfoStep {...props} />,
    [SUBMIT_PROPERTY_STEP_MISSING_INFO]: (
      <SubmitToUnderwritingStep {...props} />
    ),
    [SUBMIT_PROPERTY_STEP_SUBMITTED_TO_UW]: (
      <SubmittedToUnderwritingStep {...props} />
    ),
    [SUBMIT_PROPERTY_STEP_CONFIRM_DETAILS]: <ConfirmDetails {...props} />,
    [SUBMIT_PROPERTY_STEP_PRE_CONFIRM_DETAILS]: (
      <PreConfirmDetails {...props} />
    ),
  };

  return TYPES[wizardStep];
};
