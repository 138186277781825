import * as types from './types';

const initialState = {
  modalParams: {},

  [types.MODAL_PARAM_ONBOARDING]: false,
  [types.MODAL_PARAM_INVITE_ORGANIZATION_MEMBERS]: false,
  [types.MODAL_PARAM_EMAIL_VERIFICATION]: false,
  [types.MODAL_PARAM_PHONE_VERIFICATION]: false,
  [types.MODAL_PARAM_UPLOAD_CONTRACT]: false,
  [types.MODAL_PARAM_PHONE_UPDATE]: false,
  [types.MODAL_PARAM_REQUEST_REEVALUATION]: false,
  [types.MODAL_PARAM_INVITE_MEMBERS]: false,
  [types.MODAL_PARAM_MOBILE_DEAL_DETAILS]: false,
  [types.MODAL_TRAINING]: false,
  [types.MODAL_PRE_DEAL_ACCEPT_OFFER]: false,
  [types.PRE_DEALS_MODAL_TRAINING]: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_MODAL_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
