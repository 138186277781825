export const DEFAULT_USER_IMAGE = '/img/auth/default-user-img.jpg';

export const getUserArea = (user, areaIndex = 0) => {
  if (!user) return '';
  if (!user.areaList || user.areaList.length === 0) return '';

  const selectedArea = user.areaList[areaIndex];
  if (!selectedArea.locality || !selectedArea.stateCode) {
    return selectedArea.formattedAddress;
  }
  return `${selectedArea.locality}, ${selectedArea.stateCode}`;
};

export const getUserImage = (user) => {
  if (!user) return DEFAULT_USER_IMAGE;
  return user.picture || DEFAULT_USER_IMAGE;
};

export const isCrmMode = () => window.location.pathname.includes('crm');

export const isEmployee = (userDetails) => !!userDetails?.isAdmin;

export const getUserType = (userDetails) => {
  if (!userDetails) return null;

  return userDetails.kind?.length > 0 ? userDetails.kind[0] : 'investor';
};
