import * as Sentry from '@sentry/react';
import { isDev } from './clientService';
import { postRequest } from './requestService';

export const initLoggers = () => {
  if (isDev()) return;

  Sentry.init({
    dsn:
      'https://85fb16e880ce400a86e15f1d47a3d365@o497229.ingest.sentry.io/4505171845906432',
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https:api.getzorba.com'],
      }),
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        networkDetailAllowUrls: ['https://api.getzorba.com/dealchecker'],
        networkRequestHeaders: ['X-Custom-Header'],
        networkResponseHeaders: ['X-Custom-Header'],
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    whitelistUrls: [
      'getzorba.com',
      'www.getzorba.com',
      'partners.getzorba.com',
      'buy.getzorba.com',
    ],
    ignoreErrors: [
      /ResizeObserver/,
      /Cannot read property 'querySelectorAll' of null/,
      /Cannot read property 'focus' of null/,
      /InvalidStateError: The object is in an invalid state./,
      /InvalidStateError: The object/,
      /evaluating 'Ye.current.focus'/,
      /Error: Could not load "util"/,
      /e[n].toUpperCase is not a function/,
      /Object.sendBeacon(frame-modern)/,
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
    ],
  });
};

export const logError = async (e) => {
  try {
    if (isDev()) return;
    Sentry.captureException(e)
    // await postRequest('/health/log-client-error', { message: e?.stack || e });
  } catch (e) {}
};
