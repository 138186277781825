import { Card } from 'antd';
import { RiDashboardLine } from '@react-icons/all-files/ri/RiDashboardLine';
import { PreDealsFilters } from '../PreDealsFilters';
import FiltersDrawer from '../../../../../DealCheckerPage/Components/FiltersDrawer';

export const EmptyState = ({
  appliedFilters,
  openFiltersDrawer,
  setPredefinedView,
  predefinedView,
  totalDealsFiltered,
  form,
}) => (
  <Card className="my-properties empty-state">
    {!Object.keys(appliedFilters).length ? (
      <>
        <RiDashboardLine style={{ fontSize: 40, margin: '20px 0' }} />
        <h4>No properties submitted yet</h4>
        <p>Submit properties above to uncover the offers</p>
      </>
    ) : (
      <>
        <PreDealsFilters
          openFiltersDrawer={openFiltersDrawer}
          appliedFilters={appliedFilters}
          setPredefinedView={setPredefinedView}
          predefinedView={predefinedView}
          totalDealsFiltered={totalDealsFiltered}
          form={form}
        />
        <RiDashboardLine style={{ fontSize: 40, margin: '20px 0' }} />
        <h4>
          There are currently no properties that match the selected filters.
        </h4>
        <p>Adjust the filters to view available properties.</p>
      </>
    )}
    <FiltersDrawer setPredefinedView={setPredefinedView} />
  </Card>
);
