import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import AuthReducer from './Auth';
import DrawerReducer from './Drawer';
import ModalReducer from './Modal';
import PropertyReducer from './Property';
import ReferralReducer from './Referral';
import PreDealReducer from './PreDeal';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    AuthReducer,
    ModalReducer,
    DrawerReducer,
    PropertyReducer,
    ReferralReducer,
    PreDealReducer,
  });
