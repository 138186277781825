import * as constants from '../../constants';
import OnboardingStepCreate from '../OnboardingStepCreate';
import OnboardingStepBuyingCriteria from '../OnboardingStepBuyingCriteria';
import OnboardingStepViewButtons from '../OnboardingStepViewButtons';
import './index.scss';
import OnboardingStepPhone from '../OnboardingStepPhone';

export default (props) => {
  const { currentStepKey, wizardSteps, stepIndex } = props;
  const STEPS = {
    [constants.ONBOARDING_STEP_CREATE]: <OnboardingStepCreate {...props} />,
    [constants.ONBOARDING_STEP_BUYING_CRITERIA]: (
      <OnboardingStepBuyingCriteria {...props} />
    ),
    [constants.ONBOARDING_STEP_PHONE]: <OnboardingStepPhone {...props} />,
  };

  if (!STEPS[currentStepKey]) return null;

  const shouldHideSteps = constants.HIDDEN_BUTTON_STEPS.includes(
    currentStepKey,
  );
  return (
    <div className="onboarding-step-view">
      <div className="step-indicator">{`Onboarding - Step ${stepIndex + 1} of ${
        wizardSteps.length
      }`}</div>
      {STEPS[currentStepKey]}
      {!shouldHideSteps && <OnboardingStepViewButtons {...props} />}
    </div>
  );
};
