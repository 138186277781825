import { Layout } from 'antd';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { setDrawerState } from '../../../reducers/Drawer/actions';

import { setModalState } from '../../../reducers/Modal/actions';

import { isOnboardingPage } from '../../../services/authService';
import { isMobile } from '../../../services/clientService';

import OrganizationIdentity from './Components/OrganizationIdentity';
import SidebarLinks from './Components/SidebarLinks';
import './index.scss';

const { Sider } = Layout;

const CRMSidebar = (props) => {
  const { userDetails = {} } = props;
  const isMobileLayout = isMobile();

  if (!userDetails || Object.values(userDetails).length === 0) return null;
  return (
    <Sider
      className="crm-sidebar"
      // collapsible={!isMobileLayout}
      collapsible={false}
      width={300}
      theme="light"
      // defaultCollapsed
    >
      {userDetails?.defaultOrganization?._id && (
        <OrganizationIdentity {...props} />
      )}
      {!isOnboardingPage() && <SidebarLinks {...props} />}
    </Sider>
  );
};

const mapStateToProps = ({ AuthReducer }) => {
  const { userDetails } = AuthReducer;
  return {
    userDetails,
  };
};

const mapDispatchToProps = {
  setModalState,
  push,
  setDrawerState,
};

export default connect(mapStateToProps, mapDispatchToProps)(CRMSidebar);
