import { USER_TYPE_AGENT } from '../../../constants/user';

export const ONBOARDING_STEP_CREATE = 'create';
export const ONBOARDING_STEP_INVITE = 'invite';
export const ONBOARDING_STEP_PHONE = 'phone';
export const ONBOARDING_STEP_BUYING_CRITERIA = 'buying-criteria';

export const WIZARD_STEPS_BY_INTEREST = {
  [USER_TYPE_AGENT]: [ONBOARDING_STEP_PHONE],
};

export const SELECT_OPTIONS_COMPLETE_NEXT_DEAL = [
  { text: 'Less than 1 month', value: 0 },
  { text: '1-3 months', value: 1 },
  { text: '4-6 months', value: 4 },
  { text: '7-10 months', value: 7 },
  { text: 'I haven’t defined a timeframe yet', value: -1 },
];

export const SELECT_OPTIONS_PROPERTY_CONDITION = [
  { text: 'Turn key properties only', value: 'turn_key' },
  { text: 'Minor cosmetic updates', value: 'minor_cosmetics' },
  { text: 'Medium size rehab', value: 'medium_size_rehab' },
  { text: 'Full-scale rehab', value: 'minimum_size' },
];

export const ONBOARDING_DEFAULT_PARAMS = {
  // step 1
  buyBoxList: [],
  // step 1
  organizationName: '',
  numOfDealsQuestion: undefined,
  mainInterestQuestion: undefined,
  // step 3
  phoneNumber: {},
  kind: 'investor',
};

export const HIDDEN_BUTTON_STEPS = [ONBOARDING_STEP_INVITE];
