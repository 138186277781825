import { getPriceText } from '@zorba-shared/core/Services/textService';
import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { Divider } from 'antd';
import './index.scss';

export default (props) => {
  const { dealSubmission } = props;
  // if (!dealSubmission?.maxOffer) return null;

  return (
    <div className="your-offer">
      <div className="offer-value">
        <AiFillCheckCircle />
        <div className="title">
          {dealSubmission.maxOffer
            ? 'OUR INITIAL CASH OFFER'
            : 'WE ARE INTERESTED IN YOUR PROPERTY!'}
        </div>
        <div className="offer-amount">
          {getPriceText(dealSubmission.maxOffer)}
        </div>
      </div>
      <Divider />
      <p>
        You're almost there. Help us get a better look on the property by
        sharing photos/videos and your most updated contract. <br />
        {dealSubmission.maxOffer && (
          <>
            <b>What's next?</b>
            <ol>
              <li>
                Please note that this initial offer will be at our lowest range,
                and it should increase once we see pictures of the property and
                learn more about its condition.
              </li>
              <li>
                As soon as you get under contract update us in your dashboard
                and submit your contract and the photos/videos of the property.
              </li>
              <li>
                Our underwriters would send you our final offer within 24 hours
                or less.
              </li>
            </ol>
          </>
        )}
      </p>
    </div>
  );
};
