import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModalState } from '../../reducers/Modal/actions';
import { PRE_DEALS_MODAL_TRAINING } from '../../reducers/Modal/types';
import { PreDealsTrainingModal } from '../../containers/onboarding/PreDealsTrainingModal';

const HowPreLockDealWorksPage = () => {
  const { userDetails, isAppLoading, isAuthLoading } = useSelector(
    ({ AuthReducer }) => AuthReducer,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAppLoading && !isAuthLoading && userDetails) {
      dispatch(setModalState(PRE_DEALS_MODAL_TRAINING, true));
    }
  }, [userDetails]);

  return <PreDealsTrainingModal />;
};

export default HowPreLockDealWorksPage;
