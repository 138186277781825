import { AiOutlinePlus } from '@react-icons/all-files/ai/AiOutlinePlus';
import { v4 as uuid } from 'uuid';
import { SUBMISSION_WIZARD_STEPS_TITLES } from '../../../SubmitPropertyModal/constants';
import { ZorbaButton } from '../../../../../../components/forms';
import { optionTitles } from '../FiltersBuilder/constants';
import { BUTTON_VARIANT_LINK } from '../../../../../../components/forms/ZorbaButton/constants';

const convertSelectedFilterToString = (obj) => {
  if (!obj || typeof obj !== 'object') {
    return '';
  }

  const key = Object.keys(obj)[0];
  const values = obj[key];

  if (!Array.isArray(values)) {
    return '';
  }

  if (values.length === 0) {
    return '';
  }

  const result = values
    .map((value) => SUBMISSION_WIZARD_STEPS_TITLES[value] || value)
    .join(', ');

  return `${result}`;
};
export const InitialFiltersState = ({
  appliedFilters,
  handleOpenFilterList,
  handleOpenFilterDetail,
  filterConfig,
  handleOnDelete,
}) => {
  const getTextFromAppliedFilter = (filterKey) => {
    const filterObject = appliedFilters[filterKey];
    return convertSelectedFilterToString(filterObject);
  };

  const getSelectedRadioValue = (filterKey) => {
    const filterObject = appliedFilters[filterKey];
    const key = Object.keys(filterObject)[0];

    return `${optionTitles[key]} `;
  };

  return (
    <>
      <span className="applied-filters-summary-title">
        Applied filters ({Object.keys(appliedFilters).length})
      </span>
      <div className="applied-filters-summary-container">
        {Object.keys(appliedFilters).length ? (
          Object.keys(appliedFilters).map((filterKey) => (
            <div key={uuid()} style={{ boxSizing: 'border-box' }}>
              <div className="delete-filter-container">
                <ZorbaButton
                  fullWidth={false}
                  variant={BUTTON_VARIANT_LINK}
                  onClick={() => handleOnDelete(filterKey)}
                >
                  Delete
                </ZorbaButton>
              </div>
              <div
                className="applied-filter-description"
                onClick={() => handleOpenFilterDetail(filterKey)}
              >
                <span className="applied-filter-title">{`Deal ${
                  filterKey === 'step' ? 'status' : filterKey
                } `}</span>
                <span>{getSelectedRadioValue(filterKey)}</span>
                <span className="applied-filter-title">
                  {getTextFromAppliedFilter(filterKey)}
                </span>
              </div>
            </div>
          ))
        ) : (
          <span>This view has no filters applied</span>
        )}
      </div>
      {filterConfig.length > 0 && (
        <div className="add-filter-container">
          <ZorbaButton
            onClick={handleOpenFilterList}
            icon={<AiOutlinePlus />}
            fullWidth={false}
            disabled={!filterConfig.length}
          >
            Add filter
          </ZorbaButton>
        </div>
      )}
    </>
  );
};
