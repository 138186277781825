import _ from 'lodash';
import { isMobile } from '@zorba-shared/client/Services/clientService';
import { postRequest, getRequest } from '../../services/requestService';
import * as types from './types';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';
import { setModalState } from '../Modal/actions';
import {
  MODAL_PARAM_REQUEST_REEVALUATION,
  MODAL_PARAM_MOBILE_DEAL_DETAILS,
} from '../Modal/types';
import { PREDEFINED_VIEWS } from '../../containers/DealCheckerPage/Components/MyProperties/Components/constants';
import { setDrawerState } from '../Drawer/actions';

export const refreshProperties = (params) => async (dispatch, getState) => {
  dispatch({ type: types.SET_PROPERTIES, payload: { loading: true } });
  const payload = { loading: false, deals: [] };
  const alreadyFetchedDeals = getState().PropertyReducer.deals;

  try {
    const res = await postRequest('/dealchecker', params);
    payload.totalDealsFiltered = res.data.totalDealsFiltered;
    payload.deals = isMobile()
      ? [...alreadyFetchedDeals, ...res.data.deals]
      : res.data.deals;
    payload.hasMoreResults = res.data.hasMoreResults;
    payload.previousDealSubmissionsMap =
      res.data.previouslySubmittedDeals || {};
  } catch (e) {
    console.log(e);
  }

  dispatch({ type: types.SET_PROPERTIES, payload });
};

export const setCurrentPage = ({ page }) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_PAGE,
    payload: { currentPage: page },
  });
};

export const setPageSize = ({ pageSize }) => (dispatch) => {
  dispatch({
    type: types.SET_PAGE_SIZE,
    payload: { pageSize },
  });
};

export const setAppliedFilters = (key, filter) => (dispatch, getState) => {
  const previousAppliedFilters = getState().PropertyReducer.appliedFilters;

  dispatch({
    type: types.SET_APPLIED_FILTERS,
    payload: {
      currentPage: 0,
      appliedFilters: { ...previousAppliedFilters, [key]: filter },
      deals: [],
    },
  });
};

export const deleteAppliedFilter = (key, setPredefinedView) => (
  dispatch,
  getState,
) => {
  const previousAppliedFilters = getState().PropertyReducer.appliedFilters;
  const newFilters = _.omit(previousAppliedFilters, key);
  if (!Object.keys(newFilters).length && setPredefinedView) {
    setPredefinedView(PREDEFINED_VIEWS.ALL_DEALS);
  }

  dispatch({
    type: types.SET_APPLIED_FILTERS,
    payload: { appliedFilters: newFilters },
  });
};

export const resetAppliedFilters = (shouldResetQuery = true) => (
  dispatch,
  getState,
) => {
  const previousAppliedFilters = getState().PropertyReducer.appliedFilters;

  if (shouldResetQuery) {
    dispatch({
      type: types.SET_APPLIED_FILTERS,
      payload: { appliedFilters: {}, deals: [] },
    });
  } else {
    dispatch({
      type: types.SET_APPLIED_FILTERS,
      payload: { appliedFilters: { query: previousAppliedFilters.query } },
    });
  }
};

export const rejectOffer = (params) => async (dispatch, getState) => {
  dispatch({ type: types.SET_PROPERTIES, payload: { loading: true } });
  const {
    deals,
    currentPage,
    pageSize,
    appliedFilters,
  } = getState().PropertyReducer;
  const payload = { loading: false, deals };

  try {
    const res = await postRequest('/dealchecker/reject-by-seller', {
      ...params,
      page: currentPage,
      pageSize,
      appliedFilters,
    });
    payload.totalDealsFiltered = res.data.totalDealsFiltered;
    payload.deals = res.data.deals;
    payload.hasMoreResults = res.data.hasMoreResults;
  } catch (e) {
    showErrorToast('Rejecting an offer failed!');
  }
  dispatch({ type: types.SET_PROPERTIES, payload });
};

export const sendCounterOffer = (dealSubmissionId, params) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: types.SET_PROPERTIES, payload: { loading: true } });
  const { currentPage, pageSize, appliedFilters } = getState().PropertyReducer;
  try {
    await postRequest(`/dealchecker/${dealSubmissionId}`, params);
    await dispatch(
      refreshProperties({ page: currentPage, pageSize, appliedFilters }),
    );
    showSuccessToast(
      `Success. We've received your request and will update you shortly.`,
    );
  } catch (e) {
    showErrorToast('Sending an counter offer failed!');
  }
};

export const requestReevaluation = (params) => async (dispatch, getState) => {
  dispatch({ type: types.SET_PROPERTIES, payload: { loading: true } });
  const { currentPage, pageSize, appliedFilters } = getState().PropertyReducer;

  try {
    await postRequest(`/dealchecker/request-reevaluation`, params);
    await dispatch(
      refreshProperties({ currentPage, pageSize, appliedFilters }),
    );
    dispatch(setModalState(MODAL_PARAM_REQUEST_REEVALUATION, false));
    showSuccessToast('Requesting reevaluation succeeded!');
  } catch (e) {
    showErrorToast('Requesting reevaluation failed!');
  }
  dispatch({ type: types.SET_PROPERTIES, payload: { loading: false } });
};

export const requestOfferRenewal = (params) => async (dispatch, getState) => {
  dispatch({ type: types.SET_PROPERTIES, payload: { loading: true } });
  const { currentPage, pageSize, appliedFilters } = getState().PropertyReducer;

  try {
    await postRequest(`/dealchecker/request-offer-renewal`, params);
    await dispatch(
      refreshProperties({ currentPage, pageSize, appliedFilters }),
    );
    showSuccessToast('Offer renewal request succeeded!');
  } catch (e) {
    showErrorToast('Offer renewal request failed!');
  }
  dispatch({ type: types.SET_PROPERTIES, payload: { loading: false } });
};

export const setSelectedDeal = (deal) => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_DEAL,
    payload: { selectedDeal: deal },
  });
};

export const fetchDealDetails = (dealSubmissionId) => async (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_DEAL,
    payload: { isFetchingDeal: true },
  });
  try {
    const res = await getRequest(`/dealchecker/get-by-id/${dealSubmissionId}`);
    if (Object.keys(res.data.deal)?.length) {
      dispatch(setSelectedDeal(res.data.deal));
      dispatch(setDrawerState(MODAL_PARAM_MOBILE_DEAL_DETAILS, true));
    }
  } catch (e) {
    console.log(e);
  }
  dispatch({
    type: types.SET_SELECTED_DEAL,
    payload: { isFetchingDeal: false },
  });
};

export const fetchStats = () => async (dispatch) => {
  dispatch({
    type: types.SET_STATS,
    payload: { isFetchingStats: true },
  });
  const payload = { isFetchingStats: false };
  try {
    const res = await getRequest('/dealchecker/stats');
    payload.totalOffers = res.data.totalOffers;
    payload.rejectedByUW = res.data.rejectedByUW;
    payload.totalWholesalerAssignmentFee =
      res.data.totalWholesalerAssignmentFee;
    payload.totalDeals = res.data.totalDeals;
  } catch (e) {
    console.log(e);
  }

  dispatch({ type: types.SET_STATS, payload });
};

export const fetchPendingActions = () => async (dispatch) => {
  const payload = {};
  try {
    const res = await getRequest('/dealchecker/count-pending-actions');
    payload.pendingDealSubmissionsCounter =
      res.data.pendingDealSubmissionsCounter;
    payload.pendingPreDealsCounter = res.data.pendingPreDealsCounter;
  } catch (e) {
    console.log(e);
  }

  dispatch({ type: types.SET_PENDING_ACTIONS, payload });
};
