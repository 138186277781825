import { isEmailValid } from '@zorba-shared/core/Services/textService';
import classNames from 'classnames';
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ZorbaButton, ZorbaInput } from '../../../components/forms';
import CodeVerification from '../../../components/forms/CodeVerification';
import {
  BUTTON_VARIANT_DARK,
  BUTTON_VARIANT_LINK,
} from '../../../components/forms/ZorbaButton/constants';
import Modal from '../../../components/layout/Modal';
import {
  setEmailVerified,
  setUserDetails,
} from '../../../reducers/Auth/actions';
import { setModalState } from '../../../reducers/Modal/actions';
import { MODAL_PARAM_EMAIL_VERIFICATION } from '../../../reducers/Modal/types';
import { reportEvent } from '../../../services/analyticsService';
import { isMobile } from '../../../services/clientService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../../services/notificationsService';
import { postRequest } from '../../../services/requestService';

import './index.scss';

export default (props) => {
  const dispatch = useDispatch();
  const { isEmailVerificationModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [suggestedMail, setSuggestedMail] = useState(userDetails?.email);

  // useEffect(() => {
  //   if (!window.location.pathname.startsWith("/confirm-email") && userDetails) {
  //     if (!isEmailVerificationModalOpen && !userDetails?.isEmailVerified) {
  //       reportScreen("email_verification_modal");
  //       dispatch(setModalState(MODAL_PARAM_EMAIL_VERIFICATION, true));
  //     } else if (isEmailVerificationModalOpen && userDetails?.isEmailVerified) {
  //       reportEvent("email_verification", "close_modal");
  //       dispatch(setModalState(MODAL_PARAM_EMAIL_VERIFICATION, false));
  //     }
  //   }
  // }, [userDetails]);

  const onSendEmail = async () => {
    reportEvent('email_verification', 'send_click');
    setLoading(true);
    const res = await postRequest('/auth/send-verification-mail', {
      suggestedMail,
    });
    setLoading(false);
    if (res.data.success) {
      reportEvent('email_verification', 'send_success');
      showSuccessToast(res.data.reason);
      setEditMode(false);
      dispatch(
        setUserDetails(
          'emailConfirmationExpiration',
          res.data.emailConfirmationExpiration,
        ),
      );
    } else {
      reportEvent('email_verification', 'send_failure');
      showErrorToast(res.data.reason);
      setSuggestedMail('');
    }
  };

  const verifyEmailCode = async (emailConfirmationCode) => {
    if (!emailConfirmationCode) return;
    setErrorMessage('');

    setLoading(true);
    const res = await postRequest('/auth/verify-email-code', {
      code: emailConfirmationCode,
    });
    if (!res.data.success) {
      setErrorMessage(res.data.reason);
    } else {
      dispatch(setModalState(MODAL_PARAM_EMAIL_VERIFICATION, false));
      dispatch(setEmailVerified());
      showSuccessToast('Your email was verified successfully 🎉');
    }
    setLoading(false);
  };
  const handleClose = () => {
    dispatch(setModalState(MODAL_PARAM_EMAIL_VERIFICATION, false));
  };
  const confirmationExpirationDate = new Date(
    userDetails?.emailConfirmationExpiration,
  );
  const currentDate = new Date();
  const isRequestConfirmationButtonDisabled =
    currentDate.getTime() < confirmationExpirationDate.getTime();

  if (!userDetails || window.location.pathname.includes('onboarding'))
    return null;
  return (
    <Modal
      className={classNames('email-verification-modal')}
      footer={null}
      width={isMobile() ? '100%' : 690}
      visible={isEmailVerificationModalOpen}
      onCancel={handleClose}
    >
      <img src="/img/brand/zorba-logo-black.png" />
      <h1 className="title">Verify your email</h1>
      <p className="desc">
        {userDetails?.emailConfirmationExpiration
          ? 'Thank you for registering, a Verification email has been sent to you.'
          : 'Thank you for registering, please request verification email to complete your account verification. '}
        <br />
      </p>

      {!editMode && (
        <div className="selected-email">
          <div className="value">{userDetails.email}</div>
          <ZorbaButton
            variant={BUTTON_VARIANT_LINK}
            onClick={() => {
              setEditMode(true);
              setSuggestedMail('');
            }}
          >
            Change Email
          </ZorbaButton>
        </div>
      )}
      {editMode && (
        <div className="selected-email">
          <ZorbaInput
            onChange={(e) => setSuggestedMail(e.target.value)}
            value={suggestedMail}
            className="email-field"
            placeholder="Email address"
          />
        </div>
      )}
      <div className="buttons-wrapper">
        {!editMode && (
          <CodeVerification
            title="Enter the code you received over email"
            onSubmit={verifyEmailCode}
            loading={loading}
            errorMessage={errorMessage}
          />
        )}
        {!editMode && (
          <ZorbaButton
            onClick={onSendEmail}
            disabled={loading || isRequestConfirmationButtonDisabled}
            variant={BUTTON_VARIANT_DARK}
          >
            {userDetails?.emailConfirmationExpiration
              ? 'ReSend Verification Email'
              : 'Send Verification Email'}
          </ZorbaButton>
        )}
        {editMode && (
          <ZorbaButton
            loading={loading}
            disabled={loading}
            onClick={() => onSendEmail(true)}
            disabled={!isEmailValid(suggestedMail)}
          >
            Save & Send Verification Mail
          </ZorbaButton>
        )}
      </div>
    </Modal>
  );
};
