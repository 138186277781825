import classNames from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { buildUserPageUrl } from '../../../services/urlBuilderService';
import { DEFAULT_USER_IMAGE, getUserType } from '../../../services/userService';
import './index.scss';

export default (props) => {
  const {
    userDetails,
    aboveNameTitle,
    variant,
    belowNameTitle,
    belowNameComponent,
    showtitle = true,
    rounded,
    nameSuffix,
    nameSuffixComponent,
    linkUserPage,
  } = props;
  const [userImageUrl, setUserImageUrl] = useState(
    userDetails?.picture || DEFAULT_USER_IMAGE,
  );
  if (!userDetails) return null;

  return (
    <div
      className={classNames('user-info', {
        [variant]: variant,
      })}
    >
      {getUserType(userDetails) === 'agent' && userDetails?.licenseNumber && (
        <div className="license-number">{userDetails?.licenseNumber}</div>
      )}
      {linkUserPage ? (
        <Link
          className={classNames('left', { clickable: props.onClick })}
          onClick={() => props.onClick && props.onClick()}
          to={buildUserPageUrl(userDetails)}
        >
          <img
            src={userImageUrl}
            onError={() => setUserImageUrl(DEFAULT_USER_IMAGE)}
            className={classNames('align-top user-info-img', {
              'img-rounded': rounded,
            })}
            alt={userDetails._id}
          />
        </Link>
      ) : (
        <div
          className={classNames('left', { clickable: props.onClick })}
          onClick={() => props.onClick && props.onClick()}
        >
          <img
            src={userImageUrl}
            onError={(e) => setUserImageUrl(DEFAULT_USER_IMAGE)}
            className={classNames('align-top user-info-img', {
              'img-rounded': rounded,
            })}
            alt={userDetails._id}
          />
        </div>
      )}
      <div className="right">
        {showtitle && (
          <div className="org-name-wrapper">
            {aboveNameTitle && (
              <div className="org-name-above-name-title">{aboveNameTitle}</div>
            )}
            <div className="user-name-wrapper">
              <div className="user-name">{userDetails?.name}</div>
              {nameSuffix && (
                <div className="user-name-suffix">{nameSuffix}</div>
              )}
              {nameSuffixComponent}
            </div>
            {belowNameTitle && (
              <div className="org-name-above-name-title">{belowNameTitle}</div>
            )}
            {belowNameComponent || null}
            {/* {showArea && (
              <div className="org-name-above-name-title">
                <span className="area-prefix">Active in:</span>{" "}
                {getUserArea(userDetails)}
              </div>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
};
