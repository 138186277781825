import { getPriceText } from '@zorba-shared/core/Services/textService';
import { Dropdown, Tooltip } from 'antd';
import { BsThreeDots } from '@react-icons/all-files/bs/BsThreeDots';
import moment from 'moment';

import _ from 'lodash';
import { getLatestAskingPriceBySeller } from '@zorba-shared/core/Constants/offer';
import { getUserType } from '../../../../services/userService';
import { getStatusTag } from './Components/constants';

export const buildTableColumns = ({
  userDetails,
  updateDealSubmission,
  setPreviouslySubmittedDealSubmissions,
  previousDealSubmissionsMap,
}) => {
  if (!userDetails) return null;
  let result = [
    {
      key: 'address',
      title: 'Property address',
      width: 400,
      render: ({ address }) => address?.formattedAddress,
    },
    {
      key: 'createdAt',
      title: 'Submitted at',
      render: (createdAt) => moment(createdAt).format('MM/DD/YY'),
      dataIndex: 'createdAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      key: 'arv',
      title: 'ARV',
      render: ({ userData }) => getPriceText(userData?.arv),
    },
    {
      key: 'rent',
      title: 'RENT EST',
      render: ({ userData }) => getPriceText(userData?.rent),
    },
    {
      key: 'rehabCost',
      title: 'REHAB EST',
      render: ({ userData }) => getPriceText(userData?.rehabCost),
    },
    {
      key: 'askingPrice',
      title: 'ASKING PRICE',
      width: 130,
      render: (dealSubmission) => {
        const latestAskingPrice = getLatestAskingPriceBySeller(dealSubmission);

        return getPriceText(latestAskingPrice);
      },
    },
    {
      key: 'offer',
      title: 'OUR OFFER',
      width: 130,
      render: ({ events }) => {
        const lastOfferEvent = _.last(
          events.filter((event) => event.kind === 'offer'),
        );
        const finalOfferEvent = _.last(
          events.filter((event) => event.kind === 'buyer_final_offer'),
        );
        if (finalOfferEvent) {
          return getPriceText(finalOfferEvent.amount);
        }
        return lastOfferEvent?.amount
          ? getPriceText(lastOfferEvent?.amount)
          : '';
      },
    },
    {
      key: 'sellerCommission',
      title: () => (
        <Tooltip title="Your seller commission + Zorba’s 2%">
          EST COMMISSION
        </Tooltip>
      ),
      render: ({ sellerCommission, maxOffer, step }) =>
        step === 'rejected' ? null : (
          <Tooltip title="Your seller commission + Zorba’s 2%">
            {getPriceText(parseInt((sellerCommission + 0.02) * maxOffer))}
          </Tooltip>
        ),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'step',
      render: (step) => getStatusTag(step),
    },
    {
      key: 'actions',
      title: '',
      render: (dealSubmission) => {
        const { step } = dealSubmission;
        let items = [];
        switch (step) {
          case 'confirm_details':
            items = [
              {
                key: 0,
                label: (
                  <div
                    onClick={() => {
                      updateDealSubmission(dealSubmission);
                      if (
                        previousDealSubmissionsMap[
                          dealSubmission.address.formattedAddress
                        ]
                      ) {
                        setPreviouslySubmittedDealSubmissions(
                          previousDealSubmissionsMap[
                            dealSubmission.address.formattedAddress
                          ],
                        );
                      }
                    }}
                  >
                    Confirm details about the property
                  </div>
                ),
              },
            ];
            break;
          case 'draft':
            items = [
              {
                key: 0,
                label: (
                  <div onClick={() => updateDealSubmission(dealSubmission)}>
                    Submit for underwriting
                  </div>
                ),
              },
            ];
            break;
          case 'missing_info':
            items = [
              {
                key: 0,
                label: (
                  <div onClick={() => updateDealSubmission(dealSubmission)}>
                    Update request
                  </div>
                ),
              },
            ];
            break;
          case 'rejected_by_uw':
            break;

          case 'revised_offer':
            break;
          default:
            return null;
        }
        if (items.length === 0) return null;

        return (
          <Dropdown menu={{ items }} placement="bottom">
            <BsThreeDots style={{ cursor: 'pointer', fontSize: 20 }} />
          </Dropdown>
        );
      },
    },
  ];

  if (getUserType(userDetails) !== 'agent') {
    result = result.filter((a) => a.key !== 'sellerCommission');
  }

  return result;
};
