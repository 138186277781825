import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { getStepComponent, loadCurrentStep } from './service';

import './index.scss';

export default (props) => {
  const { dealSubmission, previouslySubmittedDealSubmissions = [] } = props;
  const [wizardStep, setWizardStep] = useState();
  useEffect(() => {
    const currentWizardStep = loadCurrentStep({
      dealSubmission,
      previouslySubmittedDealSubmissions,
    });
    console.log(`currentStep ${currentWizardStep}`);
    setWizardStep(currentWizardStep);
  }, [dealSubmission, previouslySubmittedDealSubmissions]);

  const stepComponent = getStepComponent(wizardStep, {
    dealSubmission,
    onReset: props.onReset,
    onContinue: props.onContinue,
    previouslySubmittedDealSubmissions,
  });
  return (
    <Modal
      className="submit-property-modal"
      open={!!dealSubmission && wizardStep != null}
      footer={null}
      onCancel={() => props.onReset()}
      destroyOnClose
    >
      {stepComponent}
    </Modal>
  );
};
