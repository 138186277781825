import axios from 'axios';
import * as Sentry from '@sentry/react';
import {
  getAuthToken,
  setAuthToken,
  COOKIE_NAME_AUTH_TOKEN,
} from './authService';
import { BASE_URL, isDev, getQueryParam } from './clientService';

const Axios = axios.create({
  withCredentials: true,
  crossdomain: true,
});
axios.defaults.preflightContinue = true;

Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Axios.interceptors.request.use(async (req) => {
  //  req.headers['Referer'] = window.location.origin
  try {
    const accessToken = getAuthToken();

    req.headers[COOKIE_NAME_AUTH_TOKEN] = accessToken;
    req.headers['Access-Control-Allow-Origin'] = window.location.origin;
  } catch (e) {}
  return req;
});

Axios.interceptors.response.use(
  async (res) => {
    try {
      const authToken = res.headers[COOKIE_NAME_AUTH_TOKEN];
      if (authToken) {
        setAuthToken(authToken);
      }
    } catch (e) {}
    return res;
  },
  (error) => {
    if (!isDev()) {
      Sentry.captureException(error);
    }
    return Promise.reject(error);
  },
);

export const deleteRequest = (relativeUrl, params = {}) => {
  const requestUrl = `${BASE_URL}${relativeUrl}`;
  return Axios.delete(requestUrl, params, {
    headers: {
      Accept: 'application/json',
    },
  });
};

export const postRequest = (relativeUrl, params = {}) => {
  const requestUrl = `${BASE_URL}${relativeUrl}`;
  return Axios.post(requestUrl, params, {
    headers: {
      Accept: 'application/json',
    },
  });
};

export const getRequest = (relativeUrl, params = {}, config = {}) => {
  const requestUrl = `${BASE_URL}${relativeUrl}`;
  return Axios.get(requestUrl, {
    params,
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    ...config,
  });
};
