/* eslint-disable no-unused-expressions */
import { Divider } from 'antd';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Tooltip from '../../../../../components/forms/Tooltip';
import { logoutUser } from '../../../../../services/authService';
import { isMobile } from '../../../../../services/clientService';
import { CRM_SIDEBAR_LINKS } from './constants';
import './index.scss';

export default (props) => {
  const { userDetails } = props;
  const isPreDealsTrainingWatched = userDetails?.watchedPreDealsTrainingAt;

  const isMobileLayout = isMobile();
  const isActive = (sidebarLink) => {
    const currentPathname = window.location.pathname;
    if (sidebarLink.url === currentPathname) return true;
  };
  const hasAssociatedOrganization = !!userDetails?.defaultOrganization?._id;
  return (
    <div className="sidebar-links">
      {CRM_SIDEBAR_LINKS(isPreDealsTrainingWatched)
        .filter(
          (sidebarLink) =>
            !sidebarLink.requiresAssociatedOrganization ||
            (sidebarLink.requiresAssociatedOrganization &&
              hasAssociatedOrganization),
        )
        .map((sidebarLink, i) => {
          const active = isActive(sidebarLink);
          return (
            <Tooltip
              key={i}
              title={!props.onCloseSidebar ? sidebarLink.title : ''}
              placement="right"
            >
              <Link
                key={i}
                onClick={() => {
                  if (sidebarLink.onClick) sidebarLink.onClick(props);
                  if (props.onCloseSidebar) props.onCloseSidebar();
                }}
                to={sidebarLink.url}
                className={classNames({
                  active,
                  [sidebarLink?.class]: !!sidebarLink.class,
                })}
              >
                {sidebarLink.icon} <span>{sidebarLink.title}</span>
              </Link>
            </Tooltip>
          );
        })}
      {isMobileLayout && (
        <>
          <Divider />
          <Link
            to="#"
            onClick={() => {
              logoutUser(true);
              props.onCloseSidebar && props.onCloseSidebar();
            }}
          >
            Logout
          </Link>
        </>
      )}
    </div>
  );
};
