import { BiFilterAlt } from '@react-icons/all-files/bi/BiFilterAlt';
import { Radio } from 'antd';
import { isMobile } from '@zorba-shared/client/Services/clientService';
import { ZorbaButton } from '../../../../../../components/forms';
import { BUTTON_VARIANT_LIGHT } from '../../../../../../components/forms/ZorbaButton/constants';
import { PREDEFINED_VIEWS } from '../constants';
import { AddressFilter } from '../AddressFilter';

export const MyPropertiesFilters = ({
  openFiltersDrawer,
  appliedFilters,
  predefinedView,
  setPredefinedView,
  totalDealsFiltered,
  form,
}) => {
  const { query, ...filtersToApply } = appliedFilters;
  return (
    <>
      <div className="offer-view-container">
        {!isMobile() && (
          <div className="address-filter-container">
            <AddressFilter setPredefinedView={setPredefinedView} form={form} />
            <ZorbaButton
              variant={BUTTON_VARIANT_LIGHT}
              onClick={openFiltersDrawer}
              fullWidth={false}
              icon={<BiFilterAlt />}
            >
              {Object.keys(filtersToApply).length
                ? `Applied filters (${Object.keys(filtersToApply).length})`
                : 'Filters'}
            </ZorbaButton>
          </div>
        )}
        {isMobile() && (
          <h5 className="total-properties-title">{`Showing ${totalDealsFiltered} properties`}</h5>
        )}
        <Radio.Group
          size={isMobile() ? 'middle' : 'large'}
          value={predefinedView}
          onChange={(e) => setPredefinedView(e.target.value)}
        >
          <Radio.Button value={PREDEFINED_VIEWS.PENDING_ACTION}>
            Pending action
          </Radio.Button>
          <Radio.Button value={PREDEFINED_VIEWS.MISSING_INFO}>
            Missing info
          </Radio.Button>
          <Radio.Button value={PREDEFINED_VIEWS.ALL_DEALS}>
            All deals
          </Radio.Button>
        </Radio.Group>
      </div>
      {!isMobile() && (
        <h3 className="total-properties-title-desktop">{`Showing ${totalDealsFiltered} properties `}</h3>
      )}
    </>
  );
}
