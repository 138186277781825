import { useState } from 'react';
import { FcInfo } from '@react-icons/all-files/fc/FcInfo';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Input } from 'antd';
import {
  DAYS_TO_CLOSE_OPTIONS,
  INSPECTION_DAYS_OPTIONS,
} from '@zorba-shared/core/Constants/offer';
import {
  ZorbaButton,
  ZorbaInput,
  ZorbaSelect,
} from '../../../../../../components/forms';
import { sendCounterOffer } from '../../../../../../reducers/PreDeal/actions';
import { diffObject } from '../../../../../../services/validationService';

export const CounterOffer = ({ preDealId, isOfferExpired, preDeal }) => {
  const lastOffer = _.last(
    preDeal.events.filter((event) => event.kind === 'offer'),
  );
  const initialState = {
    inspectionMode: lastOffer?.inspectionMode,
    inspectionDays: lastOffer?.inspectionDays,
    updatedAskingPrice: lastOffer?.amount,
    daysToClose: lastOffer?.daysToClose,
    emd: lastOffer?.emd,
  };
  const [params, setParams] = useState(initialState);
  const [changedParams, setChangedParams] = useState({});
  const [textAreaValue, setTextAreaValue] = useState('');
  const dispatch = useDispatch();

  const onChange = (key, value) => {
    const updatedParams = { ...params, [key]: value };
    setParams(updatedParams);

    const paramsToChange = diffObject(updatedParams, initialState);
    setChangedParams(paramsToChange);
  };
  const handleSubmit = () => {
    dispatch(
      sendCounterOffer({
        ...params,
        preDealId,
        preDealMatchId: preDeal.associatedMatch._id,
        changedParams,
        additionalRequests: textAreaValue,
        inspectionDays:
          params.inspectionMode === 'pre_inspection'
            ? null
            : params.inspectionDays,
      }),
    );
  };

  const isSubmitDisabled =
    !Object.keys(changedParams).length ||
    (params.inspectionMode === 'contingency' && !params.inspectionDays) ||
    !params.updatedAskingPrice ||
    !params.emd;

  return (
    <>
      <div className="counter-accept-offer-container">
        <ZorbaInput
          placeholder={
            isOfferExpired
              ? ' Enter your updated asking price'
              : ' Enter your counter offer'
          }
          label={
            isOfferExpired
              ? 'Your updated asking price*'
              : 'Your counter offer*'
          }
          onChange={(value) => onChange('updatedAskingPrice', value)}
          type="number"
          value={
            !Number.isNaN(params.updatedAskingPrice)
              ? params.updatedAskingPrice
              : undefined
          }
          symbol=""
          addonBefore="$"
          fullWidth
        />
      </div>
      <div>
        <div className="two-columns-container">
          <div className="column">
            <ZorbaSelect
              label="Inspection Mode*"
              options={[
                { text: 'Pre inspection', value: 'pre_inspection' },
                { text: 'Contingency', value: 'contingency' },
              ]}
              value={params.inspectionMode}
              onChange={(e) => onChange('inspectionMode', e)}
            />
          </div>
          {params?.inspectionMode !== 'pre_inspection' && (
            <div className="column">
              <ZorbaSelect
                label="Inspection period*"
                onChange={(e) => onChange('inspectionDays', e)}
                options={INSPECTION_DAYS_OPTIONS}
                value={params.inspectionDays}
              />
            </div>
          )}
        </div>
        <div className="two-columns-container">
          <div className="column">
            <ZorbaSelect
              label="Days to close*"
              onChange={(e) => onChange('daysToClose', e)}
              options={DAYS_TO_CLOSE_OPTIONS}
              value={params.daysToClose}
            />
          </div>
        </div>
        <div className="two-columns-container">
          <div className="column">
            <ZorbaInput
              type="number"
              addonBefore="$"
              fullWidth
              symbol=""
              label="EMD*"
              onChange={(value) => onChange('emd', value)}
              value={!Number.isNaN(params.emd) ? params.emd : undefined}
            />
          </div>
        </div>
      </div>
      <div className="text-area-container">
        <div className="zorba-input-item">
          <div className="ant-form-item ant-form-item-label">
            <label>Additional requests</label>
          </div>
          <Input.TextArea
            placeholder="Type any questions or requests you have to our team"
            onChange={(e) => setTextAreaValue(e.target.value)}
            className="text-area"
          />
        </div>
      </div>
      <div>
        <ZorbaButton
          className="counter-accept-button"
          disabled={isSubmitDisabled}
          onClick={handleSubmit}
          fullWidth
        >
          {isOfferExpired ? 'Update asking price' : 'Counter offer'}
        </ZorbaButton>
      </div>
      <div className="counter-offer-info">
        <FcInfo size={25} />
        {`Please be aware, a counter-offer will not extend the offer's expiration
        time`}
      </div>
    </>
  );
};
