import { useEffect, useState } from 'react';
import { Alert, Form, Input, Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import { ZorbaInput, ZorbaButton } from '../../../../../../components/forms';
import { BUTTON_VARIANT_LINK } from '../../../../../../components/forms/ZorbaButton/constants';
import './index.scss';
import { getUserType } from '../../../../../../services/userService';
import { isValidURL } from '../../constants';
import YourOffer from '../YourOffer';
import { getMatchedBuyers } from '../../../../service';

/**
 * Show the initial offer (if exists)
 * Collect the PSA & photos to submit in order to submit to UW
 */
export default (props) => {
  const { dealSubmission } = props;
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const [loading, setLoading] = useState(false);
  const userType = getUserType(userDetails);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValues,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    reset(dealSubmission.userData);
  }, [dealSubmission]);
  const onSubmit = async (formValues) => {
    setLoading(true);
    const { updatedMatch, previouslySubmittedDeals } = await getMatchedBuyers({
      ...dealSubmission,
      userData: {
        ...formValues,
        sellerCommission: formValues.sellerCommission
          ? formValues.sellerCommission / 100
          : null,
      },
      slackThreadId: dealSubmission.slackThreadId,
      step: 'pending_uw',
    });

    props.onContinue(updatedMatch, previouslySubmittedDeals);
    setLoading(false);
  };

  const isUnderContract = watch('isUnderContract');
  const didAcceptTerms = watch('didAcceptTerms');
  const linkToPhotos = watch('linkToPhotos');

  const isValid = isUnderContract && didAcceptTerms && linkToPhotos;
  return (
    <form className="submit-to-uw-step" onSubmit={handleSubmit(onSubmit)}>
      <YourOffer dealSubmission={dealSubmission} />

      <div className="items">
        {/* <div className="form-item">
            <Controller
              name="isUnderContract"
              control={control}
              render={({ field }) => (
                <ZorbaSelect
                  label="Are you ready to sell today?*"
                  options={[
                    {
                      text: 'Yes',
                      value: true,
                    },
                    { text: `Not yet `, value: false },
                  ]}
                  field={field}
                />
              )}
            />
          </div> */}
        {/* PHOTOS QUESTION - AGENTS & INVESTORS */}

        <div className="form-item full">
          <Controller
            name="linkToPhotos"
            control={control}
            rules={{
              validate: (newVal) =>
                isValidURL(newVal) ? true : 'Please provide a valid URL',
            }}
            disabled={!isUnderContract}
            render={({ field }) => (
              <ZorbaInput
                label="Link to photos/videos*"
                disabled={!isUnderContract}
                field={field}
                placeholder="Add link to photos"
                help={errors.linkToPhotos?.message}
                hint="Please use online storage services like Dropbox, Google Drive, OneDrive, iCloud, etc..."
              />
            )}
          />
          <div style={{fontSize: 14, color: '#333'}}>You can also add a link to Zillow</div>
        </div>
        <div className="form-item full">
          <Controller
            name="description"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Form.Item label="About this deal">
                <Input.TextArea
                  placeholder={`Comps:
123 main St. Tampa, FL
123 main St. Tampa, FL

Condition:
Roof - 2 years
HVAC - 1 year
`}
                  className="text-area"
                  value={field.value}
                  onChange={field.onChange}
                />
              </Form.Item>
            )}
          />
        </div>

        {/* AGENT MODE */}
        {userType === 'agent' && (
          <div className="right">
            {/* SELLER COMMISSION */}
            <div className="form-item">
              <Controller
                name="sellerCommission"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <ZorbaInput
                    label="Your seller commission*"
                    type="number"
                    placeholder="3"
                    field={field}
                    addonBefore="%"
                    symbol={null}
                    help="We use it in our net sheet simulators"
                    validateStatus="success"
                    formatter={(val) => `${val}%`}
                    parser={(val) => val.replace('%', '')}
                  />
                )}
              />
            </div>
          </div>
        )}
        <div className="file-item">
          {isUnderContract && (
            <Controller
              name="didAcceptTerms"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Checkbox
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                  checked={field.value}
                >
                  By selecting “V”, I acknowledge that I will be required to
                  present the contract I have with the seller, including the
                  disclosed purchase price, once Zorba sends the assignment
                  agreement with the agreed-upon terms.
                </Checkbox>
              )}
            />
          )}
        </div>
        {/* PHONE NUMBER QUESTION - APPLIES TO EVERYONE */}
        {!userDetails?.phoneNumber?.number && (
          <div className="form-item">
            <Controller
              name="phoneNumber"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <ZorbaInput
                  label="Your phone number*"
                  placeholder="813-123-1234"
                  field={field}
                  symbol={null}
                  validateStatus="success"
                />
              )}
            />
          </div>
        )}
      </div>
      <Alert
        className="alert"
        type="info"
        showIcon
        description={
          <div>
            We are OK with assignments and double close transactions
            <br />
            Our inspection period is 3 business days, although we typically
            complete it earlier
            <br />
            We can provide an earnest money deposit of up to $5,000
          </div>
        }
      />
      <br />
      <div className="buttons">
        <ZorbaButton
          fullWidth={false}
          htmlType="submit"
          variant={BUTTON_VARIANT_LINK}
          className="generateOfferButton"
          disabled={loading}
          onClick={() => props.onReset()}
        >
          Continue later
        </ZorbaButton>
        <ZorbaButton
          loading={loading}
          fullWidth={false}
          htmlType="submit"
          className="generateOfferButton"
          disabled={loading || !isValid}
        >
          Get finalized offer
        </ZorbaButton>
      </div>
    </form>
  );
};
