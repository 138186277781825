import { Input } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ZorbaButton } from '../../../../../../components/forms';
import { requestOfferRenewal } from '../../../../../../reducers/PreDeal/actions';

export const RenewOffer = ({ preDealId }) => {
  const [textAreaValue, setTextAreaValue] = useState();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(
      requestOfferRenewal({
        additionalRequests: textAreaValue,
        preDealId,
      }),
    );
  };

  return (
    <>
      <div className="counter-accept-offer-container">
        <div className="zorba-input-item">
          <div className="ant-form-item ant-form-item-label">
            <label>Additional requests</label>
          </div>
          <Input.TextArea
            placeholder="Type any questions or requests you have to our team"
            onChange={(e) => setTextAreaValue(e.target.value)}
            className="text-area"
          />
        </div>
      </div>
      <div className="counter-accept-offer-container">
        <ZorbaButton className="counter-accept-button" onClick={handleSubmit}>
          Submit
        </ZorbaButton>
      </div>
    </>
  );
};
