import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import PropTypes from 'prop-types';
import { getAuthToken } from '../../../services/authService';
import { BASE_URL } from '../../../services/clientService';
import { UPLOAD_PROPS } from './constants';
import './index.scss';

const { Dragger } = Upload;

function beforeUpload(file) {
  const isLt2M = file.size / 1024 / 1024 < 40;
  if (!isLt2M) {
    message.error('Image must smaller than 40MB!');
  }
  return isLt2M;
}

const DragUpload = (props) => {
  const {
    post,
    uploadUrl,
    title,
    subtitle = 'We support PDF, docx, JPEG & PNG files',
    disabled,
  } = props;
  const accessToken = getAuthToken();

  return (
    <Dragger
      // {...UPLOAD_PROPS}
      // disabled={disabled}
      action={uploadUrl}
      multiple={false}
      maxCount={1}
      name="file"
      beforeUpload={beforeUpload}
      headers={{ 'x-access-token': accessToken, post: post?._id }}
      className={`drag-upload ${props.value ? 'has-value' : ''}`}
      onChange={(info) => {
        const { status } = info.file;
        props.onLoadingChange && props.onLoadingChange(status === 'uploading');
        if (status !== 'uploading') {
        }
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
        props.onChange && props.onChange(info.fileList);
      }}
      customRequest={props.customRequest}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{title}</p>
      <p className="ant-upload-hint">{subtitle}</p>
    </Dragger>
  );
};

DragUpload.propTypes = {
  title: PropTypes.string,
  uploadUrl: PropTypes.string,
};

DragUpload.defaultProps = {
  title: 'Click or drag files to this area to upload',
  uploadUrl: `${BASE_URL}/referral/upload-attachment`,
};

export default DragUpload;
