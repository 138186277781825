import { v4 as uuid } from 'uuid';
import { Tag } from 'antd';
import { ZorbaButton } from '../../../../../../components/forms';
import { BUTTON_VARIANT_SECONDARY } from '../../../../../../components/forms/ZorbaButton/constants';
import UnderwritingStatus from '../UnderwritingStatus';

import './index.scss';

export default (props) => {
  const { preDeal } = props;

  const isRejectedWithOtherSource =
    preDeal?.reasons?.length && preDeal.reasons[0] === 'Other source';

  return (
    <div className="rejection-step">
      {isRejectedWithOtherSource ? (
        <div className="other-source-container">
          <span className="other-source-title">Get a Pre-lock Offer™</span>
          <span className="other-source-address">
            {preDeal.address.formattedAddress}
          </span>
          <span className="other-source-description">
            We have already received this deal from another partner. If you are
            direct to the seller for this deal, please email us at{' '}
            <a href="mailto:acquisitions@zorbapartners.com">
              acquisitions@zorbapartners.com
            </a>{' '}
            or text us at <a href="tel:+18137618386">(813) 761-8386</a>.
          </span>

          <div>
            <ZorbaButton onClick={() => props.onReset()}>Close</ZorbaButton>
          </div>
        </div>
      ) : (
        <>
          <UnderwritingStatus preDeal={preDeal} />
          {preDeal?.reasons?.length > 0 && (
            <div>
              <h4>Underwriting response:</h4>
              <div className="rejection-reasons-container">
                {preDeal.reasons.map((reason) => (
                  <Tag key={uuid()}>{reason}</Tag>
                ))}
              </div>
            </div>
          )}

          <p className="paragraph">
            If you believe that we have made a mistake, please email us at
            acquisitions@getzorba.com or use the chat button on the bottom right
            side of this page.
          </p>
          <div className="buttons">
            <div className="button-wrapper">
              <ZorbaButton onClick={() => props.onReset()}>
                Submit another property
              </ZorbaButton>
            </div>
            <div className="button-wrapper">
              <ZorbaButton
                variant={BUTTON_VARIANT_SECONDARY}
                onClick={() => props.onReset()}
              >
                Back to dashboard
              </ZorbaButton>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
