import { Modal } from 'antd';
import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { FaTimesCircle } from '@react-icons/all-files/fa/FaTimesCircle';
import './index.scss';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DragUpload from '../../../../components/forms/DragUpload';
import { setModalState } from '../../../../reducers/Modal/actions';
import { MODAL_PARAM_UPLOAD_CONTRACT } from '../../../../reducers/Modal/types';

export default (props) => {
  const { isUploadContractModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  return (
    <Modal
      className="submit-property-modal"
      open={isUploadContractModalOpen}
      footer={null}
      onCancel={() =>
        dispatch(setModalState(MODAL_PARAM_UPLOAD_CONTRACT, false))
      }
    >
      {/*  */}
    </Modal>
  );
};
