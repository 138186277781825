import { v4 as uuid } from 'uuid';
import { Tag } from 'antd';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { ZorbaButton, ZorbaSelect } from '../../../../../../components/forms';
import { BUTTON_VARIANT_SECONDARY } from '../../../../../../components/forms/ZorbaButton/constants';
import UnderwritingStatus from '../UnderwritingStatus';
import { postRequest } from '../../../../../../services/requestService';

import './index.scss';

export default (props) => {
  const { dealSubmission } = props;
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const [sourceValue, setSourceValue] = useState();

  const handleSourceValue = async (value) => {
    setSourceValue(value);
    await postRequest('/dealchecker/update-source', {
      dealSubmissionId: dealSubmission._id,
      source: value,
    });
  };

  return (
    <div className="rejection-step">
      <UnderwritingStatus dealSubmission={dealSubmission} />
      {dealSubmission?.reasons?.length > 0 && (
        <div>
          <h4>Underwriting response:</h4>
          <div className="rejection-reasons-container">
            {dealSubmission.reasons.map((reason) => (
              <Tag key={uuid()}>{reason}</Tag>
            ))}
          </div>
        </div>
      )}

      <p className="paragraph">
        If you believe that we have made a mistake, please email us at
        acquisitions@getzorba.com or use the chat button on the bottom right
        side of this page.
      </p>
      <div className="buttons">
        {userDetails?.email === 'acquisitions@getzorba.com' && (
          <div className="form-item">
            <ZorbaSelect
              label="Source*"
              onChange={handleSourceValue}
              value={sourceValue}
              options={[
                { text: 'Investor Lift', value: 'Investor Lift' },
                {
                  text: 'Wholesaler Website',
                  value: 'Wholesaler Website',
                },
                { text: 'Email', value: 'Email' },
                { text: 'Dialpad', value: 'Dialpad' },
                { text: 'Equity Pro', value: 'Equity Pro' },
                { text: 'Real Estate Bees', value: 'Real Estate Bees' },
                { text: 'Other', value: 'Other' },
              ]}
              help={!sourceValue ? 'Required' : ''}
            />
          </div>
        )}
        <div className="button-wrapper">
          <ZorbaButton onClick={() => props.onReset()}>
            Submit another property
          </ZorbaButton>
        </div>
        <div className="button-wrapper">
          <ZorbaButton
            variant={BUTTON_VARIANT_SECONDARY}
            onClick={() => props.onReset()}
          >
            Back to dashboard
          </ZorbaButton>
        </div>
      </div>
    </div>
  );
};
