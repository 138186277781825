import CollaboratorsView from '../../../../../components/crm/CollaboratorsView';
import { DEFAULT_ORGANIZATION_LOGO } from '../../../../../constants/organization';
import './index.scss';

export default (props) => {
  const { userDetails } = props;
  const { defaultOrganization } = userDetails;

  if (!defaultOrganization) return null;

  const { logo = DEFAULT_ORGANIZATION_LOGO, name } = defaultOrganization;
  if (!name) return null;
  return (
    <div className="organization-identity">
      <div className="identity">
        <div className="left">
          <img src={logo} className="org-logo" alt={`${name} logo`} />
          <div className="text">
            <div className="org-name">{userDetails.name}</div>
            {/* <div className="user-mail">{userDetails.name}</div> */}
          </div>
        </div>
      </div>
      {/* <CollaboratorsView {...props} users={defaultOrganization.members} /> */}
    </div>
  );
};
