import classNames from 'classnames';
import { Progress, Rate } from 'antd';
import { useEffect, useState } from 'react';
import './index.scss';

const FEATURES = [
  { title: 'Neighborhood', rating: 3 },
  { title: 'Property condition', rating: 4 },
  { title: 'Property comps', rating: 3 },
  { title: 'Demand by cash buyers', rating: 2 },
];

const SUBTITLES = [
  'Collecting data from several databases',
  'Analyzing property details',
  'Searching matching cash buyers',
  'Searching matching cash buyers',
  'Qualifying cash buyers',
];

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export default (props) => {
  const { params } = props;
  const subtitles = props.subtitles || SUBTITLES;
  const [percent, setPercent] = useState(0);
  const [selectedSubititle, setSelectedSubtitle] = useState(subtitles[0]);
  const initInterval = async () => {
    for (let i = 1; i <= 5; i++) {
      await sleep(3000);
      setPercent(i * 20);
      setSelectedSubtitle(subtitles[i - 1] || subtitles[i - 2]);
    }
    // TODO: Navigate to the next step
    props.onContinue(params, []);
  };
  useEffect(() => {
    initInterval();
  }, []);

  const features = props.features || FEATURES;

  return (
    <div className={classNames('loading-step')}>
      <div className="inner">
        {/* TOP */}
        <div className="spinner-wrapper">
          <img src={props.spinner || '/img/layout/forms/spinner.svg'} />
          <div className="spinner-text">{selectedSubititle}</div>
        </div>
        {/* PROGRESS */}
        <Progress percent={percent} showInfo={false} strokeColor="#7388d9" />
        <div className="features">
          {features
            .filter((feature, idx) => idx <= percent / 20)
            .map((feature, idx) => (
              <div className="feature">
                <div className="title">{feature.title}</div>
                <div className="rate">
                  <Rate
                    value={feature.rating}
                    disabled
                    style={{ color: '#7388d9' }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
