import { postRequest } from '../../services/requestService';
import {
  showErrorToast,
  showSuccessToast,
} from '../../services/notificationsService';
import * as types from './types';

export const fetchPreDeals = (params) => async (dispatch) => {
  dispatch({ type: types.SET_PRE_DEALS, payload: { loading: true } });
  const payload = { loading: false, preDeals: [] };

  try {
    const res = await postRequest('/pre-deal/list', params);
    payload.totalDealsFiltered = res.data.totalDealsFiltered;
    payload.preDeals = res.data.preDeals;
    payload.hasMoreResults = res.data.hasMoreResults;
  } catch (e) {
    console.log(e);
  }

  dispatch({ type: types.SET_PRE_DEALS, payload });
};

export const setCurrentPage = ({ page }) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_PAGE,
    payload: { currentPage: page },
  });
};

export const setPageSize = ({ pageSize }) => (dispatch) => {
  dispatch({
    type: types.SET_PAGE_SIZE,
    payload: { pageSize },
  });
};

export const rejectOffer = (params) => async (dispatch, getState) => {
  dispatch({ type: types.SET_PRE_DEALS, payload: { loading: true } });
  const { currentPage, pageSize, appliedFilters } = getState().PreDealReducer;
  const payload = { loading: false };

  try {
    await postRequest('/pre-deal/reject-by-seller', {
      ...params,
    });
    await dispatch(fetchPreDeals({ currentPage, pageSize, appliedFilters }));
  } catch (e) {
    showErrorToast('Rejecting an offer failed!');
  }
  dispatch({ type: types.SET_PRE_DEALS, payload });
};

export const requestOfferRenewal = (params) => async (dispatch, getState) => {
  dispatch({ type: types.SET_PRE_DEALS, payload: { loading: true } });
  const { currentPage, pageSize, appliedFilters } = getState().PreDealReducer;

  try {
    await postRequest(`/pre-deal/request-offer-renewal`, params);
    await dispatch(fetchPreDeals({ currentPage, pageSize, appliedFilters }));
    showSuccessToast('Offer renewal request succeeded!');
  } catch (e) {
    showErrorToast('Offer renewal request failed!');
  }
  dispatch({ type: types.SET_PRE_DEALS, payload: { loading: false } });
};

export const sendCounterOffer = (params) => async (dispatch, getState) => {
  dispatch({ type: types.SET_PRE_DEALS, payload: { loading: true } });
  const { currentPage, pageSize, appliedFilters } = getState().PreDealReducer;

  try {
    await postRequest(`/pre-deal/counter-offer-by-seller`, params);
    await dispatch(fetchPreDeals({ currentPage, pageSize, appliedFilters }));
    showSuccessToast('Counter offer sucessfully submitted!');
  } catch (e) {
    showErrorToast('Countering an offer failed!');
  }
  dispatch({ type: types.SET_PRE_DEALS, payload: { loading: false } });
};

export const acceptPreOffer = (params, callback) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: types.SET_PRE_DEALS, payload: { loading: true } });
  const { currentPage, pageSize, appliedFilters } = getState().PreDealReducer;

  try {
    await postRequest(`/pre-deal/accept-pre-offer-by-seller`, params);
    await dispatch(fetchPreDeals({ currentPage, pageSize, appliedFilters }));
    showSuccessToast('Offer sucessfully accepted!');
  } catch (e) {
    showErrorToast('Accepting an offer failed!');
  }
  if (callback) {
    callback();
  }
  dispatch({ type: types.SET_PRE_DEALS, payload: { loading: false } });
};

export const acceptFinalOffer = (params, callback) => async (
  dispatch,
  getState,
) => {
  dispatch({ type: types.SET_PRE_DEALS, payload: { loading: true } });
  const { currentPage, pageSize, appliedFilters } = getState().PreDealReducer;

  try {
    await postRequest(`/pre-deal/accept-offer-by-seller`, params);
    await dispatch(fetchPreDeals({ currentPage, pageSize, appliedFilters }));
    showSuccessToast('Offer sucessfully accepted!');
  } catch (e) {
    showErrorToast('Accepting an offer failed!');
  }
  if (callback) {
    callback();
  }
  dispatch({ type: types.SET_PRE_DEALS, payload: { loading: false } });
};
