import { Divider, Statistic, Radio, Tag } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import _ from 'lodash';

import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_PRE_DEAL_ACCEPT_OFFER } from '../../../../../../reducers/Modal/types';
import { RejectOffer } from '../RejectOffer';
import { AcceptOffer } from '../AcceptOffer';
import { CounterOffer } from '../CounterOffer';
import { RenewOffer } from '../RenewOffer';
import { acceptFinalOffer } from '../../../../../../reducers/PreDeal/actions';

import './index.scss';

const RADIO_OPTIONS = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  COUNTER: 'counter',
  RENEW_OFFER: 'renew_offer',
};

const { Countdown } = Statistic;

export const OfferDetails = ({
  record,
  isContractLoading,
  setIsContractLoading,
}) => {
  const dispatch = useDispatch();
  const isOfferExpired =
    new Date().getTime() >
    new Date(record.associatedMatch?.expiredAt).getTime();
  const [radioValue, setRadioValue] = useState(
    isOfferExpired ? RADIO_OPTIONS.RENEW_OFFER : RADIO_OPTIONS.ACCEPT,
  );

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const finalOffer = _.last(
    record.events.filter((e) => e.kind === 'buyer_final_offer'),
  );

  const onRequestContract = () => {
    if (finalOffer) {
      dispatch(
        acceptFinalOffer({
          preDealId: record._id,
        }),
      );
    } else {
      dispatch(setModalState(MODAL_PRE_DEAL_ACCEPT_OFFER, true));
    }
  };

  const lastOfferEvent =
    finalOffer ||
    _.last(record.events.filter((event) => event.kind === 'offer'));

  const getAcceptCTAText = () => {
    if (isOfferExpired) {
      return 'Renew my offer';
    }
    if (lastOfferEvent?.inspectionMode === 'pre_inspection') {
      return 'Schedule pre-inspection';
    }
    return 'Request contract';
  };

  return (
    <div className="offer-expandable-container">
      <div className="offer-details-section">
        <div className="offer-amount-container">
          <div className="title">
            {finalOffer && (
              <div className="final-offer-strip">Our final offer</div>
            )}
            <span>{finalOffer ? 'Your offer' : 'Your Pre-Offer'}</span>
          </div>
          <div className="offer-amount">
            {finalOffer
              ? getPriceText(finalOffer?.amount)
              : getPriceText(lastOfferEvent?.amount)}
            <br />
          </div>
        </div>
        <Divider />
        <div className="offer-details-container">
          {record.associatedMatch?.expiredAt && (
            <div className="row-detail">
              <span className="title">Offer expiration due:</span>
              {!isOfferExpired ? (
                <div className="countdown-container">
                  <div className="countdown">
                    <span>Days</span>
                    <span>Hours</span>
                    <span>Minutes</span>
                    <span>Seconds</span>
                  </div>
                  <Countdown
                    className="offer-detail"
                    format="DD:HH:mm:ss"
                    value={new Date(
                      record.associatedMatch?.expiredAt,
                    ).toISOString()}
                  />
                </div>
              ) : (
                <span className="offer-detail expired">EXPIRED OFFER</span>
              )}
            </div>
          )}
          <div className="row-detail">
            <span className="title">Transaction type:</span>
            <span className="offer-detail">
              {lastOfferEvent?.transactionType === 'double_close'
                ? 'Double Close'
                : 'Double Close/Assignment'}
            </span>
          </div>
          {lastOfferEvent?.emd && (
            <div className="row-detail">
              <span className="title">EMD:</span>
              <span className="offer-detail">
                {getPriceText(lastOfferEvent?.emd)}
              </span>
            </div>
          )}
          {lastOfferEvent?.inspectionMode && (
            <div className="row-detail">
              <span className="title">Inspection:</span>
              <span className="offer-detail">
                {lastOfferEvent?.inspectionMode === 'pre_inspection'
                  ? 'Pre-Inspection'
                  : `${lastOfferEvent?.inspectionDays} business days`}
              </span>
            </div>
          )}
        </div>
        <div className="radio-buttons-container">
          <Radio.Group defaultValue={radioValue} onChange={handleRadioChange}>
            <Radio
              value={
                isOfferExpired
                  ? RADIO_OPTIONS.RENEW_OFFER
                  : RADIO_OPTIONS.ACCEPT
              }
              className="accept-offer"
            >
              {getAcceptCTAText()}
            </Radio>
            <div className="counter-reject-offer-container">
              {!isOfferExpired && !finalOffer && (
                <Radio
                  value={RADIO_OPTIONS.COUNTER}
                  className="counter-reject-offer"
                >
                  {isOfferExpired ? 'Request old offer' : 'Counter'}
                </Radio>
              )}
              <Radio
                value={RADIO_OPTIONS.REJECT}
                className="counter-reject-offer"
              >
                Reject offer
              </Radio>
            </div>
          </Radio.Group>
        </div>
        {radioValue === RADIO_OPTIONS.COUNTER && (
          <CounterOffer
            preDeal={record}
            preDealId={record._id}
            isOfferExpired={isOfferExpired}
          />
        )}
        {radioValue === RADIO_OPTIONS.REJECT && (
          <RejectOffer preDealId={record._id} />
        )}
        {radioValue === RADIO_OPTIONS.ACCEPT && (
          <AcceptOffer
            record={record}
            onRequestContract={onRequestContract}
            isContractLoading={isContractLoading}
            isDoubleClose={lastOfferEvent?.transactionType === 'double_close'}
            isPreInspection={
              lastOfferEvent?.inspectionMode === 'pre_inspection'
            }
            setIsContractLoading={setIsContractLoading}
          />
        )}
        {radioValue === RADIO_OPTIONS.RENEW_OFFER && (
          <RenewOffer preDealId={record._id} />
        )}
      </div>
      <div className="missing-info-section">
        <span className="title">Missing info for finalized offer</span>
        <Divider />
        <div className="tags-section">
          {!record.userData?.linkToPhotos && <Tag className="tag">Photos</Tag>}
          {!record.userData?.hoaFee && <Tag className="tag">HOA Info</Tag>}
        </div>
        <div className="description">
          <div>
            Besides agreeing on the price, we’ll need you to provide the missing
            information to ensure our finalized offer is accurate.
          </div>
        </div>
      </div>
    </div>
  );
};
