import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { setModalState } from '../../../reducers/Modal/actions';
import { PRE_DEALS_MODAL_TRAINING } from '../../../reducers/Modal/types';
import { ZorbaButton } from '../../../components/forms';
import { postRequest } from '../../../services/requestService';
import { getUserData } from '../../../reducers/Auth/actions';
import './index.scss';
import { isMobile } from '../../../services/clientService';

export const PreDealsTrainingModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { userDetails, isAppLoading, isAuthLoading } = useSelector(
    ({ AuthReducer }) => AuthReducer,
  );
  const { isPreDealsTrainingModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !isAppLoading &&
      !isAuthLoading &&
      userDetails &&
      userDetails?.defaultOrganization?.buyersAppFeatureFlags?.preDeals
        ?.isActive &&
      !userDetails?.watchedPreDealsTrainingAt
    ) {
      dispatch(setModalState(PRE_DEALS_MODAL_TRAINING, true));
    }
  }, [userDetails, isAuthLoading, isAppLoading]);

  const handleCloseModal = async () => {
    setIsLoading(true);
    if (!userDetails?.watchedPreDealsTrainingAt) {
      await postRequest('/user/update-pre-deals-watched-training');
    } else {
      history.push('/?tab=pre-lock-properties');
    }
    setIsLoading(false);
    dispatch(getUserData());
    dispatch(setModalState(PRE_DEALS_MODAL_TRAINING, false));
  };

  const videoSrc =
    'https://www.youtube.com/embed/r1Rd6hCUxf4?si=ZUbwypfxU1T9H5__&cc_load_policy=1&cc_lang_pref=en';

  return (
    <Modal
      open={isPreDealsTrainingModalOpen}
      footer={null}
      className="training-modal"
      destroyOnClose
      centered={!isMobile()}
      width={!isMobile() ? 740 : ''}
      style={isMobile() ? { top: 200 } : {}}
      closable={Boolean(userDetails?.watchedPreDealsTrainingAt)}
      onCancel={
        userDetails?.watchedPreDealsTrainingAt ? handleCloseModal : null
      }
    >
      <div className="video-responsive">
        <div className="title-container">
          <span className="title">Introducing: Pre-lock Offer™</span>
          <span className="description">
            Get offers on properties you still don’t have under contract and
            close back-to-back contracts with our offer terms.{' '}
          </span>
        </div>
        <iframe
          width={!isMobile() ? '640' : ''}
          height={!isMobile() ? '360' : ''}
          src={videoSrc}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </div>
      <div className="buttons-container">
        <ZorbaButton
          fullWidth={false}
          onClick={handleCloseModal}
          disabled={isLoading}
          loading={isLoading}
        >
          {userDetails?.watchedPreDealsTrainingAt
            ? 'Close'
            : 'Start free trial'}
        </ZorbaButton>
      </div>
    </Modal>
  );
};
