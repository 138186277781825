import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BiGridAlt } from '@react-icons/all-files/bi/BiGridAlt';
import { MdLocalOffer } from '@react-icons/all-files/md/MdLocalOffer';
import { GiTakeMyMoney } from '@react-icons/all-files/gi/GiTakeMyMoney';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import { fetchStats } from '../../../../reducers/Property/actions';

import './index.scss';

export default () => {
  const {
    isFetchingStats,
    deals,
    totalDeals,
    totalOffers,
    rejectedByUW,
    totalWholesalerAssignmentFee,
  } = useSelector(({ PropertyReducer }) => PropertyReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStats());
  }, []);

  if (isFetchingStats || !deals) return null;

  return (
    <div className="properties-stats">
      <div className="item">
        <div className="title">
          <BiGridAlt />
          <div>Total deals</div>
        </div>
        <div className="value">{totalDeals}</div>
      </div>
      <div className="item">
        <div className="title">
          <MdLocalOffer />
          <div>Total offers</div>
        </div>
        <div className="value">{totalOffers}</div>
      </div>
      <div className="item">
        <div className="title">
          <MdLocalOffer />
          <div>Total rejected</div>
        </div>
        <div className="value">{rejectedByUW}</div>
      </div>
      <div className="item">
        <div className="title">
          <GiTakeMyMoney />
          <div>Total revenue</div>
        </div>
        <div className="value">
          {getPriceText(totalWholesalerAssignmentFee)}
        </div>
      </div>
    </div>
  );
};
