import { Card, Table } from 'antd';
import _ from 'lodash';
import {
  BUYER_REJECTION_FRIENDLY_MESSAGE,
  BUYER_REJECTION_FRIENDLY_MESSAGE_CANT_BE_COMPETITIVE,
  BUYER_REJECTION_REASON_TO_FRIENDLY_MESSAGE_MAP,
} from '@zorba-shared/core/Constants/offer';

import { RiDashboardLine } from '@react-icons/all-files/ri/RiDashboardLine';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { isMobile } from '@zorba-shared/client/Services/clientService';
import {
  refreshProperties,
  setCurrentPage,
  setPageSize,
  setAppliedFilters,
  resetAppliedFilters,
} from '../../../../reducers/Property/actions';
import { buildTableColumns } from './service';
import { updateDealSubmissionObj } from '../../service';
import { OfferDetails } from './Components/OfferDetails';
import { ZorbaButton } from '../../../../components/forms';
import { BUTTON_VARIANT_SECONDARY } from '../../../../components/forms/ZorbaButton/constants';
import { setModalState } from '../../../../reducers/Modal/actions';
import { MODAL_PARAM_REQUEST_REEVALUATION } from '../../../../reducers/Modal/types';
import { RequestReevaluationModal } from './Components/RequestReevaluationModal';
import { setDrawerState } from '../../../../reducers/Drawer/actions';
import { DEAL_FILTERS_DRAWER } from '../../../../reducers/Drawer/types';
import { PREDEFINED_VIEWS } from './Components/constants';
import FiltersDrawer from '../FiltersDrawer';
import { MyPropertiesFilters } from './Components/MyPropertiesFilters';
import { MobilePropertyList } from './Components/MobilePropertyList';

import './index.scss';

export default (props) => {
  const {
    updateDealSubmission,
    form,
    avoidRefetch,
    predefinedView,
    setPredefinedView,
    setPreviouslySubmittedDealSubmissions,
    previousDealSubmissionsMap,
  } = props;
  const dispatch = useDispatch();
  const {
    loading,
    isFetchingDeal,
    deals,
    currentPage,
    pageSize,
    totalDealsFiltered,
    appliedFilters,
    totalDeals,
    hasMoreResults,
  } = useSelector(({ PropertyReducer }) => PropertyReducer);
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const [isContractLoading, setIsContractLoading] = useState(false);
  const [selectedDealSubmission, setSelectedDealSubmission] = useState();
  const [mounted, setMounted] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const onShowSizeChange = (changedPage, changedCurrentPageSize) => {
    if (changedPage - 1 !== currentPage) {
      dispatch(setCurrentPage({ page: changedPage - 1 }));
    }
    if (pageSize !== changedCurrentPageSize) {
      dispatch(setPageSize({ pageSize: changedCurrentPageSize }));
    }
  };

  const pagination = {
    position: ['bottomRight'],
    onChange: onShowSizeChange,
    total: totalDealsFiltered,
    pageSize,
    current: currentPage + 1,
    showTotal: (totalItems, range) =>
      `${range[0]}-${range[1]} of ${totalItems} items`,
  };
  const onRequestContract = async (
    step,
    dealSubmission,
    additionalRequests,
    formValues,
  ) => {
    setIsContractLoading(true);
    await updateDealSubmissionObj(dealSubmission, {
      step,
      additionalRequests,
      ...formValues,
    });
    setPredefinedView(PREDEFINED_VIEWS.ALL_DEALS);
    dispatch(
      refreshProperties({ page: currentPage, pageSize, appliedFilters }),
    );
    setIsContractLoading(false);
  };

  const openFiltersDrawer = () => {
    dispatch(setDrawerState(DEAL_FILTERS_DRAWER, true));
  };

  useEffect(() => {
    if (predefinedView === PREDEFINED_VIEWS.PENDING_ACTION && !avoidRefetch) {
      form.resetFields();
      dispatch(resetAppliedFilters());
      dispatch(
        setAppliedFilters('step', {
          isAnyOf: ['revised_offer'],
        }),
      );
    }
    if (predefinedView === PREDEFINED_VIEWS.MISSING_INFO && !avoidRefetch) {
      form.resetFields();
      dispatch(resetAppliedFilters());
      dispatch(
        setAppliedFilters('step', {
          isAnyOf: ['draft', 'missing_info', 'confirm_details'],
        }),
      );
    }
    if (predefinedView === PREDEFINED_VIEWS.ALL_DEALS) {
      dispatch(resetAppliedFilters(false));
    }
  }, [predefinedView]);

  useEffect(() => {
    if (mounted) {
      dispatch(
        refreshProperties({ page: currentPage, pageSize, appliedFilters }),
      );
    } else {
      setMounted(true);
    }
  }, [currentPage, pageSize, appliedFilters]);

  useEffect(() => {
    if (deals?.length) {
      const rowsToExpand = deals
        .filter((deal) => deal.step === 'revised_offer')
        .map((deal) => deal._id);
      setExpandedRowKeys(rowsToExpand);
    }
  }, [deals]);

  const EmptyState = (
    <Card className="my-properties empty-state">
      {!Object.keys(appliedFilters).length ? (
        <>
          <RiDashboardLine style={{ fontSize: 40, margin: '20px 0' }} />
          <h4>No properties submitted yet</h4>
          <p>Submit properties above to uncover the offers</p>
        </>
      ) : (
        <>
          <MyPropertiesFilters
            openFiltersDrawer={openFiltersDrawer}
            appliedFilters={appliedFilters}
            setPredefinedView={setPredefinedView}
            predefinedView={predefinedView}
            totalDealsFiltered={totalDealsFiltered}
            form={form}
          />
          <RiDashboardLine style={{ fontSize: 40, margin: '20px 0' }} />
          <h4>
            There are currently no properties that match the selected filters.
          </h4>
          <p>Adjust the filters to view available properties.</p>
        </>
      )}
      <FiltersDrawer setPredefinedView={setPredefinedView} />
    </Card>
  );

  const handleOpenModal = (record) => {
    setSelectedDealSubmission(record);
    dispatch(setModalState(MODAL_PARAM_REQUEST_REEVALUATION, true));
  };

  const ResultsTable = (
    <>
      <Card className="my-properties" bordered={!isMobile()}>
        <MyPropertiesFilters
          openFiltersDrawer={openFiltersDrawer}
          appliedFilters={appliedFilters}
          setPredefinedView={setPredefinedView}
          predefinedView={predefinedView}
          totalDealsFiltered={totalDealsFiltered}
          form={form}
        />
        {!isMobile() && (
          <Table
            rowKey="_id"
            loading={loading || isContractLoading}
            className="my-deals-table"
            columns={buildTableColumns({
              userDetails,
              updateDealSubmission,
              dispatch,
              setPreviouslySubmittedDealSubmissions,
              previousDealSubmissionsMap,
            })}
            dataSource={deals}
            pagination={pagination}
            scroll={{ x: true }}
            expandable={{
              expandedRowKeys,
              onExpand: (expandable, record) => {
                if (expandable) {
                  setExpandedRowKeys([...expandedRowKeys, record._id]);
                } else {
                  setExpandedRowKeys(
                    expandedRowKeys.filter((id) => record._id !== id),
                  );
                }
              },
              expandedRowRender: (record) => {
                if (record?.step === 'revised_offer') {
                  return (
                    <OfferDetails
                      record={record}
                      onRequestContract={onRequestContract}
                      isContractLoading={isContractLoading}
                    />
                  );
                }
                if (record?.step === 'rejected_by_uw') {
                  const message = record.underwriting?.rejectionMessageToSeller
                    ? record.underwriting?.rejectionMessageToSeller
                    : record?.reasons.join(', ');
                  return (
                    <div>
                      <h4 className="underwriting-response">
                        Underwriting response
                      </h4>
                      <div className="underwriting-rejection">{message}</div>
                    </div>
                  );
                }
                if (record?.step === 'rejected_by_buyer') {
                  const lastEvent = _.last(
                    record.events.filter(
                      (event) => event.kind === 'buyer_reject',
                    ),
                  );

                  if (
                    lastEvent?.message ===
                    BUYER_REJECTION_FRIENDLY_MESSAGE_CANT_BE_COMPETITIVE
                  ) {
                    return (
                      <div className="underwriting-request-reevaluation">
                        <div>
                          <span className="underwriting-title">
                            Rejection reason:
                          </span>
                          <span className="underwriting-rejection">{`${
                            BUYER_REJECTION_FRIENDLY_MESSAGE[lastEvent?.message]
                          }`}</span>
                        </div>
                        <div>
                          <ZorbaButton
                            variant={BUTTON_VARIANT_SECONDARY}
                            onClick={handleOpenModal}
                          >
                            Request reevaluation
                          </ZorbaButton>
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div>
                      <h4 className="underwriting-response">
                        Underwriting response
                      </h4>
                      <div className="underwriting-rejection">
                        {
                          BUYER_REJECTION_FRIENDLY_MESSAGE[
                            BUYER_REJECTION_REASON_TO_FRIENDLY_MESSAGE_MAP[
                              lastEvent?.message
                            ]
                          ]
                        }
                      </div>
                    </div>
                  );
                }
              },
              rowExpandable: (record) =>
                record?.step === 'revised_offer' ||
                (record?.step === 'rejected_by_uw' &&
                  record.underwriting?.rejectionMessageToSeller) ||
                (record?.step === 'rejected_by_uw' && record.reasons?.length) ||
                record?.step === 'rejected_by_buyer',
            }}
          />
        )}
      </Card>
      {isMobile() && (
        <MobilePropertyList
          deals={deals}
          loading={loading}
          onRequestContract={onRequestContract}
          updateDealSubmission={updateDealSubmission}
          handleOpenModal={handleOpenModal}
          currentPage={currentPage}
          totalDeals={totalDeals}
          hasMoreResults={hasMoreResults}
          predefinedView={predefinedView}
          isContractLoading={isContractLoading}
          setPreviouslySubmittedDealSubmissions={
            setPreviouslySubmittedDealSubmissions
          }
          previousDealSubmissionsMap={previousDealSubmissionsMap}
        />
      )}
      <RequestReevaluationModal
        dealSubmissionId={selectedDealSubmission?._id}
      />
      <FiltersDrawer setPredefinedView={setPredefinedView} />
    </>
  );

  return deals.length > 0 || loading || isFetchingDeal
    ? ResultsTable
    : EmptyState;
};
