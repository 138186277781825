import * as types from './types';

const initialState = {
  isAppLoading: false,
  userDetails: undefined,
  organisationList: [],
  notificationList: [],
  isInit: false,
  isAuthLoading: false,
  organization: undefined,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_USER_DATA:
    case types.SET_GUEST:
    case types.SET_AUTH_LOADING_STATE:
    case types.LOCAL_AUTH:
    case types.SET_ORGANIZATION:
    case types.UPDATE_USER_DETAILS:
      return {
        ...state,
        ...payload,
      };
    case types.SET_EMAIL_VERIFIED:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isEmailVerified: false,
        },
      };
    default:
      return state;
  }
}
