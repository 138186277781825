import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Divider, Form, Input } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import {
  PROPERTY_TYPE_LOT,
  PROPERTY_TYPE_LAND,
} from '@zorba-shared/core/Constants/deal';
import {
  ZorbaButton,
  ZorbaInput,
  ZorbaSelect,
} from '../../../../../../components/forms';
import { MODAL_PRE_DEAL_ACCEPT_OFFER } from '../../../../../../reducers/Modal/types';
import { BUTTON_VARIANT_LINK } from '../../../../../../components/forms/ZorbaButton/constants';
import ZorbaDatePicker from '../../../../../../components/forms/ZorbaDatePicker';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { isValidURL } from '../../../SubmitPreDealModal/constants';
import { acceptPreOffer } from '../../../../../../reducers/PreDeal/actions';
import './index.scss';

export const AcceptOfferModal = ({ preDeal }) => {
  const dispatch = useDispatch();
  const { isPreDealAcceptOfferModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    dispatch(setModalState(MODAL_PRE_DEAL_ACCEPT_OFFER, false));
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      linkToPhotos: preDeal?.userData?.linkToPhotos,
    },
  });

  const validateRequired = (value) =>
    ![null, undefined].includes(value) || 'This field is required';

  const onSubmit = async (formValues) => {
    setLoading(true);
    dispatch(
      acceptPreOffer(
        {
          ...formValues,
          preDealId: preDeal._id,
        },
        () => {
          setLoading(false);
          handleCloseModal();
        },
      ),
    );
  };

  const watchHoaFees = watch('hoaFees');
  const watchInspectionAvailability1 = watch('inspectionAvailability1');

  useEffect(() => {
    if (!watchHoaFees) {
      setValue('hoaPeriod', null);
      setValue('hoaFee', null);
    }
    if (watchHoaFees) {
      setValue('hoaPeriod', 'month');
    }
  }, [watchHoaFees]);

  return (
    <Modal
      className="accept-offer-modal"
      open={isPreDealAcceptOfferModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      destroyOnClose
    >
      <form className="submission-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-container">
          <div className="underwriting-container">
            <h3 className="pre-deal-title">Accept a Pre-lock Offer™</h3>
            <span className="underwriting-title">
              {preDeal?.address?.formattedAddress}
            </span>
          </div>
          <Divider />
          <div className="items">
            <Controller
              name="linkToPhotos"
              control={control}
              rules={{
                validate: (newVal) =>
                  isValidURL(newVal) ? true : 'Please provide a valid URL',
              }}
              render={({ field }) => (
                <ZorbaInput
                  label="Link to photos/videos*"
                  field={field}
                  placeholder="Add link to photos"
                  help={errors.linkToPhotos?.message}
                  hint="Please use online storage services like Dropbox, Google Drive, OneDrive, iCloud, etc..."
                  hintPosition="bottom"
                />
              )}
            />
          </div>
          <Divider />
          {/* INITIAL FORM (PRICES, RELATIONSHIP & OCCUPANCY) */}
          <div className="items">
            {/* ARV */}
            <div className="form-item">
              <Controller
                name="arv"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <ZorbaInput
                    label="ARV"
                    type="number"
                    placeholder="1000"
                    field={field}
                    symbol={null}
                    addonBefore="$"
                    help={errors.arv ? 'Required' : ''}
                  />
                )}
              />
            </div>
            {/* ESTIMATED RENT */}
            {preDeal?.lastCalculationParams?.propertyType !==
              PROPERTY_TYPE_LOT &&
              preDeal?.lastCalculationParams?.propertyType !==
                PROPERTY_TYPE_LAND && (
                <div className="form-item">
                  <Controller
                    name="rent"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <ZorbaInput
                        label="Estimated rent"
                        type="number"
                        placeholder="1200"
                        field={field}
                        addonBefore="$"
                        symbol={null}
                        help={errors.rent ? 'Required' : ''}
                      />
                    )}
                  />
                </div>
              )}
            {preDeal?.lastCalculationParams?.propertyType !==
              PROPERTY_TYPE_LOT &&
              preDeal?.lastCalculationParams?.propertyType !==
                PROPERTY_TYPE_LAND && (
                <div className="form-item">
                  <Controller
                    name="rehabCost"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <ZorbaInput
                        label="Estimated rehab cost"
                        type="number"
                        placeholder="1000"
                        field={field}
                        addonBefore="$"
                        symbol={null}
                        help={errors.rehabCost ? 'Required' : ''}
                      />
                    )}
                  />
                </div>
              )}
            <div className="form-item">
              <Controller
                name="relationship"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <ZorbaSelect
                    label="Relationship to the property*"
                    onChange={field.onChange}
                    value={field.value}
                    options={[
                      { text: 'Owner', value: 'owner' },
                      { text: 'Realtor / Agent', value: 'agent' },
                      {
                        text: 'Wholesaler (direct to seller)',
                        value: 'wholesaler_direct',
                      },
                      {
                        text: 'Wholesaler (3rd party)',
                        value: 'wholesaler_daisy_chain',
                      },
                    ]}
                    help={errors.relationship ? 'Required' : ''}
                  />
                )}
              />
            </div>
            {preDeal?.lastCalculationParams?.propertyType !==
              PROPERTY_TYPE_LOT &&
              preDeal?.lastCalculationParams?.propertyType !==
                PROPERTY_TYPE_LAND && (
                <div className="form-item">
                  <Controller
                    name="occupancy"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <ZorbaSelect
                        label="Occupancy at closing*"
                        onChange={field.onChange}
                        value={field.value}
                        options={[
                          { text: 'Owner occupied', value: 'owner_occupied' },
                          { text: 'Tenant occupied', value: 'tenant_occupied' },
                          { text: 'Vacant', value: 'vacant' },
                        ]}
                        help={errors.occupancy ? 'Required' : ''}
                      />
                    )}
                  />
                </div>
              )}
          </div>
          {preDeal?.lastCalculationParams?.propertyType !== PROPERTY_TYPE_LOT &&
            preDeal?.lastCalculationParams?.propertyType !==
              PROPERTY_TYPE_LAND && (
              <>
                <Divider />
                <div className="items">
                  <div className="form-item">
                    <Controller
                      name="hoaFees"
                      control={control}
                      rules={{ validate: validateRequired }}
                      render={({ field }) => (
                        <ZorbaSelect
                          label="Are there any HOA fees?*"
                          field={field}
                          options={[
                            {
                              text: 'Yes',
                              value: true,
                            },
                            { text: `No`, value: false },
                          ]}
                          help={errors.hoaFees ? 'Required' : ''}
                        />
                      )}
                    />
                  </div>
                  {watchHoaFees && (
                    <>
                      <div className="form-item">
                        <Controller
                          name="hoaPeriod"
                          control={control}
                          rules={{ required: watchHoaFees }}
                          render={({ field }) => (
                            <ZorbaSelect
                              label="HOA Frequency*"
                              fullWidth
                              onChange={field.onChange}
                              value={field.value}
                              options={[
                                { text: 'Monthly', value: 'month' },
                                { text: 'Quarterly', value: 'quarter' },
                                { text: 'Annually', value: 'annual' },
                              ]}
                              help={errors.hoaPeriod ? 'Required' : ''}
                            />
                          )}
                        />
                      </div>
                      {watchHoaFees && (
                        <div className="form-item">
                          <Controller
                            name="hoaFee"
                            control={control}
                            rules={{ required: watchHoaFees, min: 1 }}
                            render={({ field }) => (
                              <ZorbaInput
                                label="HOA Amount*"
                                type="number"
                                placeholder="1000"
                                field={field}
                                addonBefore="$"
                                symbol={null}
                                help={errors.hoaFee ? 'Required' : ''}
                              />
                            )}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          <Divider />
          {/* UNDER CONTRACT QUESTION */}
          <div className="items">
            <div className="form-item full">
              <Controller
                name="isUnderContract"
                control={control}
                render={({ field }) => (
                  <ZorbaSelect
                    label="Did you sign a contract with the seller?*"
                    options={[
                      {
                        text: 'Yes',
                        value: true,
                      },
                      { text: `Not yet `, value: false },
                    ]}
                    field={field}
                    help={errors.isUnderContract ? 'Required' : ''}
                  />
                )}
              />
            </div>
          </div>
          {preDeal?.lastCalculationParams?.propertyType !== PROPERTY_TYPE_LOT &&
            preDeal?.lastCalculationParams?.propertyType !==
              PROPERTY_TYPE_LAND && (
              <>
                <Divider />
                {/* UNDER CONTRACT QUESTION */}
                <div className="items">
                  <div className="form-item full calendar-container">
                    <Controller
                      name="inspectionAvailability1"
                      control={control}
                      render={({ field }) => (
                        <ZorbaDatePicker
                          field={field}
                          formClassName="full-width"
                          label="When can we walk the property?"
                          showTime
                        />
                      )}
                    />
                  </div>
                  {watchInspectionAvailability1 && (
                    <div className="form-item full calendar-container">
                      <Controller
                        name="inspectionAvailability2"
                        control={control}
                        render={({ field }) => (
                          <ZorbaDatePicker
                            field={field}
                            formClassName="full-width"
                            label=""
                            showTime
                          />
                        )}
                      />
                    </div>
                  )}
                  <span>
                    Please provide at least one available time slot for us to
                    inspect the property. If you have a preferred time, feel
                    free to fill it in, otherwise, you can leave it blank. Our
                    inspection typically takes less than an hour.
                  </span>
                </div>
              </>
            )}
          <Divider />
          <div className="access-details-info-container">
            <Controller
              name="accessDetails"
              control={control}
              render={({ field }) => (
                <Form.Item label="Access Details">
                  <Input.TextArea
                    placeholder="We will need the access details to schedule the inspection. Is there a lockbox code, and if so what is the code?"
                    className="text-area"
                    value={field.value}
                    onChange={field.onChange}
                  />
                </Form.Item>
              )}
            />
            <Controller
              name="additionalRequests"
              control={control}
              render={({ field }) => (
                <Form.Item label="Additional requests">
                  <Input.TextArea
                    placeholder="Type any questions or requests you have to our team"
                    className="text-area"
                    value={field.value}
                    onChange={field.onChange}
                  />
                </Form.Item>
              )}
            />
          </div>
          <Divider />
        </div>
        <div className="buttons">
          <ZorbaButton
            fullWidth={false}
            htmlType="submit"
            variant={BUTTON_VARIANT_LINK}
            className="generateOfferButton"
            disabled={loading}
            onClick={handleCloseModal}
          >
            Cancel
          </ZorbaButton>
          <ZorbaButton
            loading={loading}
            fullWidth={false}
            htmlType="submit"
            className="generateOfferButton"
            disabled={loading}
          >
            Get Final Offer
          </ZorbaButton>
        </div>
      </form>
    </Modal>
  );
};
