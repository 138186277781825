export const buildQueryString = (url, queryParams) => {
  if (!url || !queryParams) return url || '';

  let baseUrl = url;
  const queryString = Object.keys(queryParams)
    .filter((x) => !!queryParams[x])
    .map((key) => `${key}=${queryParams[key]}`)
    .join('&');
  if (queryString.length > 0) {
    baseUrl += `?${queryString}`;
  }
  return baseUrl;
};
