import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import {
  buildLoginUrlWithRedirect,
  buildOnboardingPageUrl,
} from '../services/urlBuilderService';
import { FullPageSpinner } from '../components/layout';
import CRMSidebar from '../containers/crm/CRMSidebar';
import { getUserData } from '../reducers/Auth/actions';
import {
  getAuthToken,
  postRefreshTasks,
  QUERY_PARAM_USER_ID,
  QUERY_PARAM_MAGIC_TOKEN,
} from '../services/authService';
import { getQueryParam, isMobile } from '../services/clientService';

const PrivateRoute = (props) => {
  const {
    isAppLoading,
    userDetails,
    component: Component,
    path,
    sidebar,
    ...rest
  } = props;
  useEffect(() => {
    postRefreshTasks();
  }, []);
  useEffect(() => {
    const magicToken = getQueryParam(QUERY_PARAM_MAGIC_TOKEN);
    const queryUserId = getQueryParam(QUERY_PARAM_USER_ID);
    const authToken = getAuthToken();

    if (!magicToken && !authToken) props.push(buildLoginUrlWithRedirect());
    else if (!userDetails && !isAppLoading) {
      let placeFriendlyUrl;

      if (window.location.pathname.startsWith('/p/')) {
        placeFriendlyUrl = window.location.pathname
          .substring(1)
          .replace('p', '');
      }
      props.getUserData(queryUserId, placeFriendlyUrl);
    }
  }, [isAppLoading, userDetails]);

  useEffect(() => {
    if (userDetails && !userDetails.isCompletedOnboarding) {
      props.push(buildOnboardingPageUrl());
    }
  }, [userDetails]);
  const render = (props) =>
    userDetails ? (
      <>
        {!isMobile() && sidebar && <CRMSidebar {...props} />}
        <Component {...props} sidebar={sidebar} userDetails={userDetails} />
      </>
    ) : (
      <FullPageSpinner />
    );

  return <Route path={path} render={render} {...rest} />;
};
const mapStateToProps = ({ AuthReducer }) => {
  const { userDetails, isAppLoading } = AuthReducer;
  return {
    userDetails,
    isAppLoading,
  };
};
const mapDispatchToProps = {
  getUserData,
  push,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
