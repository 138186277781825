import { useState } from 'react';
import { Form, Input } from 'antd';
import { FcInfo } from '@react-icons/all-files/fc/FcInfo';

import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ZorbaButton, ZorbaSelect } from '../../../../../../components/forms';
import ZorbaDatePicker from '../../../../../../components/forms/ZorbaDatePicker';

dayjs.extend(utc);
dayjs.extend(timezone);
export const AcceptOffer = ({
  record,
  onRequestContract,
  isContractLoading,
  isDoubleClose,
  isPreInspection,
}) => {
  const [textAreaValue, setTextAreaValue] = useState();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      occupancy: record?.userData?.occupancy,
      inspectionAvailability1: record?.userData?.inspectionAvailability?.[0]
        ? dayjs(
            record?.userData?.inspectionAvailability[0],
            'YYYY-MM-DD h:mm a',
          ).tz('America/New_York')
        : '',
      inspectionAvailability2: record?.userData?.inspectionAvailability?.[1]
        ? dayjs(
            record?.userData?.inspectionAvailability[1],
            'YYYY-MM-DD h:mm a',
          ).tz('America/New_York')
        : '',
    },
  });
  const onSubmit = async (formValues) => {
    const step = isPreInspection ? 'pending_inspection' : 'pending_agreement';
    onRequestContract(step, record, textAreaValue, formValues);
  };

  const watchInspectionAvailability1 = watch('inspectionAvailability1');
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isPreInspection && (
          <div className="pre-inspection-form">
            <div className="form-item">
              <Controller
                name="occupancy"
                control={control}
                rules={{ required: !!isPreInspection }}
                render={({ field }) => (
                  <ZorbaSelect
                    label="Is the property occupied or vacant?*"
                    onChange={field.onChange}
                    value={field.value}
                    options={[
                      { text: 'Owner occupied', value: 'owner_occupied' },
                      { text: 'Tenant occupied', value: 'tenant_occupied' },
                      { text: 'Vacant', value: 'vacant' },
                    ]}
                    help={errors.occupancy ? 'Required' : ''}
                  />
                )}
              />
            </div>
            <div className="items">
              <div className="form-item">
                <Controller
                  name="inspectionAvailability1"
                  control={control}
                  render={({ field }) => (
                    <ZorbaDatePicker
                      field={field}
                      formClassName="full-width"
                      label="Do you have a preferred time for us to walk the property?"
                      showTime
                    />
                  )}
                />
              </div>
              {watchInspectionAvailability1 && (
                <div className="form-item">
                  <Controller
                    name="inspectionAvailability2"
                    control={control}
                    render={({ field }) => (
                      <ZorbaDatePicker
                        field={field}
                        formClassName="full-width"
                        label=""
                        showTime
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="form-item access-details">
              <Controller
                name="accessDetails"
                control={control}
                render={({ field }) => (
                  <Form.Item label="Access Details">
                    <Input.TextArea
                      placeholder="We will need the access details to schedule the inspection. Is there a lockbox code, and if so what is the code?"
                      className="text-area"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </Form.Item>
                )}
              />
            </div>
          </div>
        )}
        <div className="counter-accept-offer-container">
          <div className="zorba-input-item">
            <div className="ant-form-item ant-form-item-label">
              <label className="additional-requests-label">
                Additional requests
              </label>
            </div>
            <Input.TextArea
              placeholder="Type any questions or requests you have to our team"
              onChange={(e) => setTextAreaValue(e.target.value)}
              className="text-area"
            />
          </div>
        </div>
        <div className="counter-accept-offer-container">
          <ZorbaButton
            className="counter-accept-button"
            disabled={isContractLoading}
            htmlType="submit"
          >
            {isPreInspection ? 'Schedule pre-inspection' : 'Request contract'}
          </ZorbaButton>
        </div>
        {isDoubleClose && (
          <div className="counter-offer-info">
            <div className="icon-container">
              <FcInfo size={25} />
            </div>
            {`Please be aware that a double closing is required for this property.
            However, clicking ${
              isPreInspection ? 'Schedule pre-inspection' : 'Request contract'
            } does not commit you to this process.
            After your selection, a representative will contact you to discuss
            the available options in detail.`}
          </div>
        )}
      </form>
    </>
  );
};
