import React from 'react';
import { ZorbaButton } from '../../../../../components/forms';
import { BUTTON_VARIANT_LINK } from '../../../../../components/forms/ZorbaButton/constants';
import { reportEvent } from '../../../../../services/analyticsService';
import { buildLoginUrlWithRedirect } from '../../../../../services/urlBuilderService';
import { HEADER_LOGIN_EXCLUDED_URLS } from './constants';
import './index.scss';

export default (props) => {
  const { userDetails, isAuthLoading } = props;
  if (userDetails) return null;
  if (HEADER_LOGIN_EXCLUDED_URLS.includes(window.location.pathname))
    return null;
  const onClick = () => {
    props.push(buildLoginUrlWithRedirect());
    reportEvent('auth', 'open', { source: 'header' });
  };

  if (isAuthLoading) return null;
  return (
    <div className="login-buttons">
      <ZorbaButton onClick={onClick} variant={BUTTON_VARIANT_LINK}>
        Log in
      </ZorbaButton>
      <div className="header-login-button" onClick={onClick}>
        <ZorbaButton>Get Started</ZorbaButton>
      </div>
    </div>
  );
};
