import { Avatar, Form, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import './index.scss';

export default React.forwardRef((props, ref) => {
  const { options = [], variant = '', label, itemClassName, className } = props;
  const itemProps = _.pick(props, [
    'label',
    'help',
    'validateStatus',
    'tooltip',
  ]);
  const selectProps = _.pick(props, [
    'onSelect',
    'placeholder',
    'onDeselect',
    'onChange',
    'value',
    'notFoundContent',
    'dropdownRender',
    'getInputElement',
    'disabled',
  ]);

  const selectComponent = (
    <Select
      {...selectProps}
      ref={ref}
      className={`zorba-select ${variant || ''} ${className || ''}`}
      optionLabelProp="label"
      {...(props.field || {})}
    >
      {props.children ||
        options.map((option) => (
          <Select.Option
            key={option.value}
            value={option.value}
            label={option.text}
          >
            {option.image && (
              <Avatar role="img" src={option.image} alt={option.text} />
            )}
            {option.text}
          </Select.Option>
        ))}
    </Select>
  );

  return label ? (
    <Form.Item
      {...itemProps}
      validateStatus={
        itemProps.validateStatus || (itemProps.help ? 'error' : 'success')
      }
      className={`zorba-select-item ${variant || ''} ${itemClassName || ''}`}
    >
      {selectComponent}
    </Form.Item>
  ) : (
    selectComponent
  );
});
