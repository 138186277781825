import { BsCheckCircle } from '@react-icons/all-files/bs/BsCheckCircle';
import { BsXCircle } from '@react-icons/all-files/bs/BsXCircle';
import { v4 as uuid } from 'uuid';
import { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import { fetchSharedBuyBoxFromS3 } from '@zorba-shared/client/Services/s3Service';
import { ZorbaButton } from '../../../../components/forms';
import { BUTTON_VARIANT_DARK } from '../../../../components/forms/ZorbaButton/constants';
import { reportEvent } from '../../../../services/analyticsService';

import './index.scss';

const { Panel } = Collapse;

export default (props) => {
  const [jsonData, setJsonData] = useState({});

  useEffect(() => {
    const fetchJsonFromS3 = async () => {
      try {
        const data = await fetchSharedBuyBoxFromS3();
        setJsonData(data);
      } catch (error) {
        console.log('Error fetching JSON from S3:', error);
      }
    };

    fetchJsonFromS3();
  }, []);

  return (
    <div className="buybox">
      {!props.hideTitle && <h3>Our buy box</h3>}
      <div className="promotion">
        <div className="left">
          Gain access to our preferred ZIP code list across all our markets.
        </div>
        <div
          className="right"
          onClick={() => {
            reportEvent('dashboard', 'download_zipcodes', {
              user: props.userDetails?.email,
            });
            window.open(
              'https://zorba.s3.amazonaws.com/partners/zorba-preferred-zipcode-list.csv',
            );
          }}
        >
          <ZorbaButton variant={BUTTON_VARIANT_DARK}>
            Download Zipcodes
          </ZorbaButton>
        </div>
      </div>
      <div className="items">
        <div className="left">
          {(jsonData?.requirements || []).map((requirement) => (
            <div key={uuid()}>
              <BsCheckCircle />
              {`${requirement.title}: ${requirement.value}`}
            </div>
          ))}
        </div>
        <div className="right">
          {(jsonData?.exclusionCriteria || []).map((element) => (
            <div key={uuid()}>
              <BsXCircle />
              {element}
            </div>
          ))}
        </div>
      </div>
      <Collapse className="states">
        {Object.keys(jsonData.stateCountyAreaMap || {})
          .sort()
          .map((key) => {
            const counties = Object.keys(
              jsonData.stateCountyAreaMap[key],
            ).sort();
            const areas = [
              ...new Set(
                Object.values(jsonData.stateCountyAreaMap[key]).flat(),
              ),
            ].sort();

            const header = (
              <span>
                <strong style={{ fontSize: '16px' }}>{key}</strong>
                <br />
                <strong>{counties.length} counties, </strong>
                <strong>{areas.length} areas</strong>
              </span>
            );

            return (
              <Panel header={header} key={key}>
                <div className="counties-container">
                  <div className="title-county-area">Counties</div>
                  {`${Object.keys(jsonData.stateCountyAreaMap[key]).join(
                    ', ',
                  )}`}
                </div>
                <div>
                  <div className="title-county-area">Areas</div>
                  {`${areas.join(', ')}`}
                </div>
              </Panel>
            );
          })}
      </Collapse>
    </div>
  );
};
