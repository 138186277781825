import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isMobile } from '../../../services/clientService';
import { buildUserPageUrl } from '../../../services/urlBuilderService';
import { getUserImage } from '../../../services/userService';
import Tooltip from '../../forms/Tooltip';
import './index.scss';

const CollaboratorsView = (props) => {
  const { users, count, shouldDisableClick } = props;
  if (!(users?.length > 0)) return null;
  const isMobileLayout = isMobile();

  if (window.location.pathname.includes('onboarding')) return null;

  const renderAvatar = (user) => (
    <Avatar src={getUserImage(user)} alt={user.firstName} />
  );

  const maxCount = count || (isMobileLayout ? 4 : 5);

  return (
    <div className="collaborators-view">
      <Avatar.Group size="large" maxCount={maxCount}>
        {users.map((user) => (
          <Tooltip
            key={user._id}
            title={isMobileLayout ? '' : `${user.name || ''}`}
          >
            {shouldDisableClick ? (
              renderAvatar(user)
            ) : (
              <Link target="_blank" to={buildUserPageUrl(user)}>
                {renderAvatar(user)}
              </Link>
            )}
          </Tooltip>
        ))}
      </Avatar.Group>
    </div>
  );
};

CollaboratorsView.defaultProps = {
  users: [],
  shouldDisableClick: false,
  count: 0,
};
CollaboratorsView.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      _id: PropTypes.string,
      friendlyUrl: PropTypes.string,
    }),
  ),
  shouldDisableClick: PropTypes.bool,
  count: PropTypes.number,
};

export default CollaboratorsView;
