import { useState } from 'react';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import { SELLER_REJECTION_REASON_OPTIONS } from '@zorba-shared/core/Constants/offer';
import { ZorbaButton, ZorbaSelect } from '../../../../../../components/forms';
import { rejectOffer } from '../../../../../../reducers/PreDeal/actions';

export const RejectOffer = ({ preDealId }) => {
  const [textAreaValue, setTextAreaValue] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(
      rejectOffer({
        reason: rejectionReason,
        additionalDescription: textAreaValue,
        preDealId,
      }),
    );
  };

  return (
    <div className="rejection-container">
      <ZorbaSelect
        label="Rejection reason*"
        onChange={(e) => setRejectionReason(e)}
        options={SELLER_REJECTION_REASON_OPTIONS}
        fullWidth
      />
      <Input.TextArea
        placeholder="Please enter additional message ..."
        onChange={(e) => setTextAreaValue(e.target.value)}
        className="text-area"
      />
      <div className="button-container">
        <ZorbaButton
          className="button"
          fullWidth={false}
          disabled={!rejectionReason}
          onClick={handleSubmit}
        >
          Reject
        </ZorbaButton>
      </div>
    </div>
  );
};
