import classNames from 'classnames';
import ZorbaButton from '../../../../../components/forms/ZorbaButton';
import './index.scss';

export default (props) => {
  const { isLoading, floating = false, stepIndex } = props;
  return (
    <div className={classNames('onboarding-step-view-buttons', { floating })}>
      <ZorbaButton
        loading={isLoading}
        onClick={() => props.onContinue()}
        className="onboarding-submit-btn"
      >
        Continue
      </ZorbaButton>
      {[3].includes(stepIndex) && (
        <div className="skip-btn" onClick={() => props.onContinue()}>
          Skip this step
        </div>
      )}
    </div>
  );
};
