import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Divider } from 'antd';
import {
  PROPERTY_TYPE_LAND,
  PROPERTY_TYPE_LOT,
  PROPERTY_TYPE_OPTIONS,
} from '@zorba-shared/core/Constants/deal';
import {
  ZorbaButton,
  ZorbaInput,
  ZorbaSelect,
} from '../../../../../../components/forms';
import { BUTTON_VARIANT_LINK } from '../../../../../../components/forms/ZorbaButton/constants';
import { getMatchedBuyers } from '../../../../service';
import { PlusMinusInput } from '../../../../../../components/forms/PlusMinusInput';
import { generateYearOptions } from './constants';

import './index.scss';

const ConfirmDetails = (props) => {
  const { preDeal } = props;
  const [loading, setLoading] = useState(false);
  const onSubmit = async (formValues) => {
    setLoading(true);
    const { carportSpaces, garageSpaces, drivewaySpaces } = formValues;
    const parkingSpaces = carportSpaces + garageSpaces + drivewaySpaces;

    const getParkingTypes = () => {
      const parkingTypeMap = {
        carport: carportSpaces,
        garage: garageSpaces,
        driveway: drivewaySpaces,
      };

      return Object.entries(parkingTypeMap)
        .filter(([, spaces]) => spaces > 0)
        .map(([type]) => type);
    };
    const payload = {
      ...preDeal,
      lastCalculationParams: {
        ...preDeal.lastCalculationParams,
        ...formValues,
        parkingSpaces,
        parkingTypes: getParkingTypes(),
      },
      step: 'confirm_details',
    };
    const { updatedMatch, previouslySubmittedDeals } = await getMatchedBuyers(
      payload,
    );
    props.onContinue(updatedMatch, previouslySubmittedDeals);
    setLoading(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      bedroom:
        preDeal.lastCalculationParams?.bedroom &&
        preDeal.lastCalculationParams?.bedroom > 0
          ? preDeal.lastCalculationParams?.bedroom
          : undefined,
      bathroom:
        preDeal.lastCalculationParams?.bathroom &&
        preDeal.lastCalculationParams?.bathroom > 0
          ? preDeal.lastCalculationParams?.bathroom
          : undefined,
      yearBuilt: preDeal.lastCalculationParams?.yearBuilt,
      squareFootage: preDeal.lastCalculationParams?.squareFootage,
      lotSize: preDeal.lastCalculationParams?.lotSize,
      hasPool: preDeal.lastCalculationParams?.hasPool,
      parkingTypes: preDeal.lastCalculationParams?.parkingTypes || [],
      carportSpaces: (
        preDeal.lastCalculationParams?.parkingTypes || []
      ).includes('carport')
        ? preDeal.lastCalculationParams?.parkingSpaces
        : 0,
      garageSpaces: (
        preDeal.lastCalculationParams?.parkingTypes || []
      ).includes('garage')
        ? preDeal.lastCalculationParams?.parkingSpaces
        : 0,
      drivewaySpaces: preDeal.lastCalculationParams?.drivewaySpaces || 0,
      wastewaterType: preDeal.lastCalculationParams?.wastewaterType,
      propertyType: preDeal.lastCalculationParams?.propertyType,
    },
  });

  const watchedPropertyType = watch('propertyType');

  return (
    <form className="confirm-details-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="underwriting-container">
        <h3 className="pre-deal-title">Get a Pre-lock Offer™</h3>
        <span className="underwriting-title">
          {preDeal?.address?.formattedAddress}
        </span>
        <span className="underwriting-question">
          Do we have your property details right?
        </span>
      </div>
      <Divider />
      <div className="items">
        <div className="form-item">
          <Controller
            name="askingPrice"
            control={control}
            rules={{
              validate: {
                required: (newVal) => {
                  if (!newVal && !getValues('askingPrice')) return 'Required';

                  return true;
                },
              },
            }}
            render={({ field }) => (
              <ZorbaInput
                label="Your Asking price*"
                type="number"
                placeholder="1000"
                field={field}
                addonBefore="$"
                symbol={null}
                help={errors.askingPrice?.message}
                hintPosition="bottom"
              />
            )}
          />
        </div>
        <div className="form-item">
          <Controller
            name="propertyType"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <ZorbaSelect
                label="Property Type*"
                onChange={field.onChange}
                value={field.value}
                options={PROPERTY_TYPE_OPTIONS.map((option) => ({
                  text: option.label,
                  value: option.value,
                }))}
                help={errors.propertyType ? 'Required' : ''}
              />
            )}
          />
        </div>
        {watchedPropertyType !== PROPERTY_TYPE_LOT &&
          watchedPropertyType !== PROPERTY_TYPE_LAND && (
            <>
              <div className="form-item">
                <Controller
                  name="bedroom"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <ZorbaSelect
                      label="Bedrooms*"
                      onChange={field.onChange}
                      tooltip="Must have a closet, window, and door"
                      value={field.value}
                      options={[
                        { text: '1', value: 1 },
                        { text: '2', value: 2 },
                        { text: '3', value: 3 },
                        { text: '4', value: 4 },
                        { text: '5', value: 5 },
                        { text: '6+', value: 6 },
                      ]}
                      help={errors.bedroom ? 'Required' : ''}
                    />
                  )}
                />
              </div>
              <div className="form-item">
                <Controller
                  name="bathroom"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <ZorbaSelect
                      label="Bathrooms*"
                      tooltip="A full bath has a shower or tub"
                      onChange={field.onChange}
                      value={field.value}
                      options={[
                        { text: '1', value: 1 },
                        { text: '2', value: 2 },
                        { text: '3', value: 3 },
                        { text: '4', value: 4 },
                        { text: '5', value: 5 },
                        { text: '6+', value: 6 },
                      ]}
                      help={errors.bathroom ? 'Required' : ''}
                    />
                  )}
                />
              </div>
              <div className="form-item">
                <Controller
                  name="yearBuilt"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <ZorbaSelect
                      label="Year Built*"
                      onChange={field.onChange}
                      value={field.value}
                      options={generateYearOptions()}
                      help={errors.yearBuilt ? 'Required' : ''}
                    />
                  )}
                />
              </div>
              <div className="form-item">
                <Controller
                  name="squareFootage"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <ZorbaInput
                      label="Square footage (above ground)*"
                      type="number"
                      placeholder="1,276"
                      field={field}
                      addonAfter="sqft"
                      symbol={null}
                      help={errors.squareFootage ? 'Required' : ''}
                      tooltip="Don’t include basement, yard, garage, screened porch, or any non-heated areas of your home"
                    />
                  )}
                />
              </div>
            </>
          )}
        <div className="form-item">
          <Controller
            name="lotSize"
            control={control}
            render={({ field }) => (
              <ZorbaInput
                label="Lot size (Optional)"
                type="number"
                placeholder="1,276"
                field={field}
                addonAfter="sqft"
                symbol={null}
                help={errors.lotSize ? 'Required' : ''}
                tooltip="Don’t include basement, yard, garage, screened porch, or any non-heated areas of your home"
              />
            )}
          />
        </div>
        {watchedPropertyType !== PROPERTY_TYPE_LOT &&
          watchedPropertyType !== PROPERTY_TYPE_LAND && (
            <>
              <div className="form-item">
                <Controller
                  name="hasPool"
                  control={control}
                  render={({ field }) => (
                    <ZorbaSelect
                      label="Pool (Optional)"
                      onChange={field.onChange}
                      value={field.value}
                      options={[
                        { text: 'Has Pool', value: true },
                        { text: 'No Pool', value: false },
                      ]}
                      help={errors.hasPool ? 'Required' : ''}
                    />
                  )}
                />
              </div>
              <div className="form-item">
                <Controller
                  name="wastewaterType"
                  control={control}
                  render={({ field }) => (
                    <ZorbaSelect
                      label="Wastewater type (Optional)"
                      options={[
                        {
                          text: 'Septic',
                          value: 'septic',
                        },
                        {
                          text: 'Sewer',
                          value: 'sewer',
                        },
                        { text: `I'm not sure`, value: 'not_sure' },
                      ]}
                      field={field}
                      help={errors.wastewaterType ? 'Required' : ''}
                    />
                  )}
                />
              </div>
              <div className="form-item">
                <Controller
                  name="foundationIssues"
                  control={control}
                  render={({ field }) => (
                    <ZorbaSelect
                      label="Foundation issues (Optional)"
                      tooltip="Foundation issues may arise from poor construction, water damage, cracks, etc."
                      options={[
                        {
                          text: 'Yes',
                          value: true,
                        },
                        {
                          text: 'No',
                          value: false,
                        },
                      ]}
                      field={field}
                      help={errors.foundationIssues ? 'Required' : ''}
                    />
                  )}
                />
              </div>
              <div className="parking-spaces">
                <span className="parking-spaces-title">
                  How many covered parking spaces does your property have?
                </span>
                <div>
                  <Controller
                    name="garageSpaces"
                    control={control}
                    render={({ field }) => (
                      <PlusMinusInput
                        label="Garage"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="carportSpaces"
                    control={control}
                    render={({ field }) => (
                      <PlusMinusInput
                        label="Carport"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="drivewaySpaces"
                    control={control}
                    render={({ field }) => (
                      <PlusMinusInput
                        label="Driveway"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
              </div>
            </>
          )}
      </div>
      <div className="buttons">
        <ZorbaButton
          fullWidth={false}
          htmlType="submit"
          variant={BUTTON_VARIANT_LINK}
          disabled={loading}
          onClick={() => props.onReset()}
        >
          Cancel
        </ZorbaButton>
        <ZorbaButton
          loading={loading}
          fullWidth={false}
          htmlType="submit"
          disabled={loading}
        >
          Yes, continue
        </ZorbaButton>
      </div>
    </form>
  );
};

export default ConfirmDetails;
