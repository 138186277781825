import { useState } from 'react';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { reportEvent } from '../../services/analyticsService';
import LoadingStep from './Components/LoadingStep';
import InstructionsStep from './Components/InstructionsStep';
import SubmitPreDealModal from './Components/SubmitPreDealModal';
import { MyPreDeals } from './Components/MyPreDeals';
import { PREDEFINED_VIEWS } from './Components/MyPreDeals/constants';
import { setAppliedFilters } from '../../reducers/Property/actions';
import { PreDealsTrainingModal } from '../onboarding/PreDealsTrainingModal';

const PreDealCheckerPage = () => {
  const dispatch = useDispatch();
  const [address, setAddress] = useState();
  const [loading, setLoading] = useState(false);
  const [avoidRefetch, setAvoidRefetch] = useState(false);
  const [step, setStep] = useState('confirm_details');
  const [preDeal, setPreDeal] = useState();
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const [form] = Form.useForm();

  const [predefinedView, setPredefinedView] = useState(
    PREDEFINED_VIEWS.PENDING_ACTION,
  );

  const onContinue = (params) => {
    if (params?.step)
      reportEvent('pre-dealchecker', `step_${params?.step}`, {
        address: address?.formattedAddress,
        user: userDetails?._id,
      });

    const nextStep = params?.step;
    setPreDeal(params);
    switch (nextStep) {
      case 'draft':
      case 'missing_info':
      case 'review':
      case 'revised_offer':
      case 'pending_agreement':
      case 'pending_inspection':
      case 'closing':
      case 'closed_won':
      case 'rejected_by_seller':
      case 'rejected_by_buyer':
        if (params?.address?.formattedAddress) {
          form.setFieldValue('query', params?.address?.formattedAddress);
          dispatch(
            setAppliedFilters('query', {
              regex: params?.address?.formattedAddress,
            }),
          );
          setPredefinedView(PREDEFINED_VIEWS.ALL_DEALS);
        }
        break;
      default:
        break;
    }
    window.scrollTo(0, 0);
  };

  const onReset = () => {
    setAddress();
    setLoading(false);
    setPreDeal();
    setStep('confirm_details');
  };

  const updatePreDeal = (newDeal) => {
    setPreDeal(newDeal);
    setStep(newDeal?.step);
  };

  return (
    <>
      <div className="pre-buybox-checker-page">
        {loading && (
          <LoadingStep
            features={[
              { title: 'Pulling county records', rating: 2 },
              { title: 'Neighborhood', rating: 3 },
              { title: 'Property condition', rating: 4 },
              { title: 'Property comps', rating: 3 },
            ]}
            subtitles={[
              'Collecting data from several databases',
              'Analyzing property details',
              'Analyzing property details',
              'Pulling sales comparables',
            ]}
            className="loading-step"
            spinner="/img/layout/forms/spinner.svg"
            onContinue={() => {}}
          />
        )}
        {!loading && (
          <>
            <InstructionsStep
              address={address}
              onContinue={onContinue}
              setLoading={setLoading}
              loading={loading}
              step={step}
              onReset={onReset}
              setAddress={setAddress}
              setPreDeal={setPreDeal}
              preDeal={preDeal}
            />
            <MyPreDeals
              predefinedView={predefinedView}
              setPredefinedView={setPredefinedView}
              form={form}
              avoidRefetch={avoidRefetch}
              updatePreDeal={updatePreDeal}
            />
          </>
        )}
      </div>
      <PreDealsTrainingModal />
      <SubmitPreDealModal
        preDeal={preDeal}
        onReset={onReset}
        onContinue={onContinue}
        step={step}
        address={address}
        form={form}
      />
    </>
  );
};

export default PreDealCheckerPage;
