import { getPriceText } from '@zorba-shared/core/Services/textService';

export const UserData = ({ selectedDeal }) => (
  <div className="offer-details-container">
    <div className="row-detail">
      <span className="title">Asking price:</span>
      <span className="offer-detail">
        {getPriceText(selectedDeal.userData?.askingPrice)}
      </span>
    </div>
    <div className="row-detail">
      <span className="title">ARV:</span>
      <span className="offer-detail">
        {getPriceText(selectedDeal.userData?.arv)}
      </span>
    </div>
    <div className="row-detail">
      <span className="title">Estimated rehab:</span>
      <span className="offer-detail">
        {getPriceText(selectedDeal.userData?.rehabCost)}
      </span>
    </div>
    <div className="row-detail">
      <span className="title">Estimated rent:</span>
      <span className="offer-detail">
        {getPriceText(selectedDeal.userData?.rent)}
      </span>
    </div>
    {selectedDeal.userData?.hoaFee && (
      <>
        <div className="row-detail">
          <span className="title">Hoa Fee:</span>
          <span className="offer-detail">
            {getPriceText(selectedDeal.userData?.hoaFee)}
          </span>
        </div>
      </>
    )}
    {selectedDeal.userData?.description && (
      <div className="row-detail description">
        <span className="title description-title">Description:</span>
        <span className="offer-detail description">
          {selectedDeal.userData?.description}
        </span>
      </div>
    )}
  </div>
);
