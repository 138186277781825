import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import Spinner from '../../layout/Spinner';
import './index.scss';

export default (props) => {
  const { loading = false, errorMessage } = props;
  //   const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState('');
  const codeInputRef = useRef(null);
  useEffect(() => {
    if (errorMessage) {
      resetFields();
    }
  }, [errorMessage]);
  const onChange = (newVal) => {
    setValue(newVal);
  };
  const resetFields = () => {
    codeInputRef?.current?.__clearvalues__();
  };
  const onSubmit = (newValue) => {
    props.onSubmit && props.onSubmit(newValue);
  };

  return (
    <div className="code-verification">
      <div className="title">
        <div dangerouslySetInnerHTML={{ __html: props.title }} />
        {loading && <Spinner />}
      </div>
      <ReactCodeInput
        ref={codeInputRef}
        autoFocus
        fields={6}
        onComplete={onSubmit}
        className={classNames('code-input', { invalid: !!errorMessage })}
        // loading={loading}
        value={value}
        onChange={onChange}
        disabled={loading}
      />
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};
