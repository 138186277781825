import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { FaTimesCircle } from '@react-icons/all-files/fa/FaTimesCircle';

export default (props) => {
  const { dealSubmission } = props;
  if (!dealSubmission) return null;

  return (
    <div
      className={`property-status ${
        dealSubmission?.valid ? 'valid' : 'invalid'
      }`}
    >
      {dealSubmission?.valid ? <AiFillCheckCircle /> : <FaTimesCircle />}
      <div className="title">
        {dealSubmission?.valid
          ? 'Yes, we would love to learn more about your property!'
          : 'Sorry, this property is not in our buybox'}
      </div>
    </div>
  );
};
