import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { setModalState } from '../../../reducers/Modal/actions';
import { MODAL_TRAINING } from '../../../reducers/Modal/types';
import { ZorbaButton } from '../../../components/forms';
import { BUTTON_VARIANT_LINK } from '../../../components/forms/ZorbaButton/constants';
import { postRequest } from '../../../services/requestService';
import './index.scss';
import { isMobile } from '../../../services/clientService';

export const TrainingModal = () => {
  const { userDetails, isAppLoading, isAuthLoading } = useSelector(
    ({ AuthReducer }) => AuthReducer,
  );
  const { isTrainingModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (
      !isAppLoading &&
      !isAuthLoading &&
      userDetails &&
      !userDetails?.watchedTrainingAt
    ) {
      dispatch(setModalState(MODAL_TRAINING, true));
    }
  }, [userDetails, isAuthLoading, isAppLoading]);

  const handleCloseModal = async () => {
    await postRequest('/user/update-watched-training');
    dispatch(setModalState(MODAL_TRAINING, false));
    if (location.pathname === '/how-it-works') {
      history.push('/');
    }
  };

  const videoSrc =
    'https://www.youtube.com/embed/xLHJLr4ndjU?si=hLks0XMF8jubZIf&cc_load_policy=1&cc_lang_pref=en';

  return (
    <Modal
      open={isTrainingModalOpen}
      footer={null}
      onCancel={handleCloseModal}
      title="Learn how Zorba works in 2 minutes"
      className="training-modal"
      destroyOnClose
      centered={!isMobile()}
      width={!isMobile() ? 740 : ''}
      style={isMobile() ? { top: 200 } : {}}
    >
      <div className="video-responsive">
        <iframe
          width={!isMobile() ? '640' : ''}
          height={!isMobile() ? '360' : ''}
          src={videoSrc}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </div>
      <div className="buttons-container">
        <ZorbaButton
          variant={BUTTON_VARIANT_LINK}
          fullWidth={false}
          onClick={handleCloseModal}
        >
          Skip the video
        </ZorbaButton>
      </div>
    </Modal>
  );
};
