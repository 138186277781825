import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { FullPageSpinner } from '../components/layout';
import CRMSidebar from '../containers/crm/CRMSidebar';
import { getUserData, setGuest } from '../reducers/Auth/actions';
import {
  getAuthToken,
  postRefreshTasks,
  QUERY_PARAM_FORCE_AUTH,
  QUERY_PARAM_USER_ID,
  QUERY_PARAM_REDIRECT_URL,
  QUERY_PARAM_MAGIC_TOKEN,
} from '../services/authService';
import { getQueryParam, isMobile } from '../services/clientService';
import {
  buildHomePageUrl,
  buildLoginUrlWithRedirect,
  buildOnboardingPageUrl,
} from '../services/urlBuilderService';

const PublicRoute = (props) => {
  const {
    isAppLoading,
    userDetails,
    component: Component,
    path,
    sidebar,
    isInit,
    history,
    pageName,
    ...rest
  } = props;
  useEffect(() => {
    postRefreshTasks();
  }, []);
  useEffect(() => {
    const queryUserId = getQueryParam(QUERY_PARAM_USER_ID);
    const queryForceAuth = getQueryParam(QUERY_PARAM_FORCE_AUTH);
    const authToken = getAuthToken();
    const magicToken = getQueryParam(QUERY_PARAM_MAGIC_TOKEN);

    if (!isInit) {
      if (!userDetails && !isAppLoading && (authToken || magicToken)) {
        let placeFriendlyUrl;

        if (window.location.pathname.startsWith('/p/')) {
          placeFriendlyUrl = window.location.pathname
            .substring(1)
            .replace('p', '');
        }
        props.getUserData(queryUserId, placeFriendlyUrl);
      } else if (!isAppLoading && queryForceAuth) {
        props.push(buildLoginUrlWithRedirect());
      } else {
        props.setGuest();
      }
    }
  }, [userDetails, isAppLoading, isInit]);

  // useEffect(() => {
  //   if (
  //     userDetails &&
  //     !userDetails.isCompletedOnboarding &&
  //     !rest?.location?.pathname?.includes("o/onboarding")
  //   ) {
  //     props.push(buildOnboardingPageUrl());
  //   } else if (
  //     window.location.pathname === "/o" &&
  //     userDetails?.defaultOrganization?.friendlyUrl
  //   ) {
  //     const crmHomePage = buildHomePageUrl();
  //     props.push(crmHomePage);
  //   }
  // }, [userDetails, rest]);

  const render = (props) =>
    isInit && !isAppLoading ? (
      <>
        {!isMobile() && sidebar && <CRMSidebar {...props} />}
        <Component
          {...props}
          sidebar={userDetails ? sidebar : false}
          pageName={pageName}
        />
      </>
    ) : (
      <FullPageSpinner />
    );

  return <Route path={path} render={render} {...rest} />;
};
const mapStateToProps = ({ AuthReducer }) => {
  const { userDetails, isAppLoading, isInit } = AuthReducer;
  return {
    userDetails,
    isAppLoading,
    isInit,
  };
};
const mapDispatchToProps = {
  getUserData,
  push,
  setGuest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
