export const SET_PROPERTIES = 'SET_PROPERTIES';

export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'SET_PAGE_SIZE';

export const SET_APPLIED_FILTERS = 'SET_APPLIED_FILTERS';

export const SET_SELECTED_DEAL = 'SET_SELECTED_DEAL';

export const SET_STATS = 'SET_STATS';

export const SET_PENDING_ACTIONS = 'SET_PENDING_ACTIONS';
