import { useState } from 'react';
import { Radio, Space } from 'antd';
import PhoneInput from '../../../../../components/forms/PhoneInput';

import './index.scss';

export default (props) => {
  const [
    shouldDisplayValidationError,
    setShouldDisplayValidationError,
  ] = useState(false);
  const onPhoneNumberChange = (newVal) => {
    props.onChange('phoneNumber', newVal);
    setShouldDisplayValidationError(newVal.number.toString().length !== 10);
  };

  const onRadioChange = (event) => {
    props.onChange('kind', event.target.value);
  };

  return (
    <div className="onboarding-step phone">
      <h3>What's the best number to reach you at?</h3>
      <PhoneInput
        placeholder="Phone number"
        onChange={onPhoneNumberChange}
        errorMessage="Please provide a valid US phone number"
        showErrorMessage={shouldDisplayValidationError}
      />
      <div className="phone-note">
        Our underwriting team will contact you if they have any questions about
        your properties.
      </div>
      <div className="form-item-container">
        <h3>What describes you best?</h3>
        <Radio.Group onChange={onRadioChange} value={props.params.kind}>
          <Space direction="vertical">
            <Radio value="investor" className="form-item-label">
              Investor (Wholesaler)
            </Radio>
            <Radio value="agent" className="form-item-label">
              Agent
            </Radio>
          </Space>
        </Radio.Group>
      </div>
    </div>
  );
};
