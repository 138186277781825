import { Divider, Statistic, Radio } from 'antd';
import { useState } from 'react';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import { isMobile } from '@zorba-shared/client/Services/clientService';
import _ from 'lodash';

import { RejectOffer } from '../RejectOffer';
import { AcceptOffer } from '../AcceptOffer';
import { CounterOffer } from '../CounterOffer';
import { RenewOffer } from '../RenewOffer';

import './index.scss';

const RADIO_OPTIONS = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  COUNTER: 'counter',
  RENEW_OFFER: 'renew_offer',
};

const { Countdown } = Statistic;

export const OfferDetails = ({
  record,
  onRequestContract,
  isContractLoading,
}) => {
  const isOfferExpired =
    new Date().getTime() >
    new Date(record.associatedMatch?.expiredAt).getTime();
  const [radioValue, setRadioValue] = useState(
    isOfferExpired ? RADIO_OPTIONS.RENEW_OFFER : RADIO_OPTIONS.ACCEPT,
  );

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const shouldShowUnderwritingSection =
    !isMobile() &&
    (record.underwriting?.arv ||
      record.underwriting?.rent ||
      record.underwriting?.rehabCost);

  const finalOffer = _.last(
    record.events.filter((e) => e.kind === 'buyer_final_offer'),
  );

  const lastOfferEvent =
    finalOffer ||
    _.last(record.events.filter((event) => event.kind === 'offer'));

  const getAcceptCTAText = () => {
    if (isOfferExpired) {
      return 'Renew my offer';
    }
    if (lastOfferEvent?.inspectionMode === 'pre_inspection') {
      return 'Schedule pre-inspection';
    }
    return 'Request contract';
  };

  return (
    <div className="offer-expandable-container">
      <div className="offer-details-section">
        <div className="offer-amount-container">
          <div className="title">
            {finalOffer && (
              <div className="final-offer-strip">Our final offer</div>
            )}
            <span>Your cash offer</span>
          </div>
          <div className="offer-amount">
            {finalOffer
              ? getPriceText(finalOffer?.amount)
              : getPriceText(lastOfferEvent?.amount)}
            <br />
          </div>
        </div>
        <Divider />
        <div className="offer-details-container">
          {record.associatedMatch?.expiredAt && (
            <div className="row-detail">
              <span className="title">Offer expiration due:</span>
              {!isOfferExpired ? (
                <div className="countdown-container">
                  <div className="countdown">
                    <span>Days</span>
                    <span>Hours</span>
                    <span>Minutes</span>
                    <span>Seconds</span>
                  </div>
                  <Countdown
                    className="offer-detail"
                    format="DD:HH:mm:ss"
                    value={new Date(
                      record.associatedMatch?.expiredAt,
                    ).toISOString()}
                  />
                </div>
              ) : (
                <span className="offer-detail expired">EXPIRED OFFER</span>
              )}
            </div>
          )}
          <div className="row-detail">
            <span className="title">Transaction type:</span>
            <span className="offer-detail">
              {lastOfferEvent?.transactionType === 'double_close'
                ? 'Double Close'
                : 'Double Close/Assignment'}
            </span>
          </div>
          {lastOfferEvent?.emd && (
            <div className="row-detail">
              <span className="title">EMD:</span>
              <span className="offer-detail">
                {getPriceText(lastOfferEvent?.emd)}
              </span>
            </div>
          )}
          {lastOfferEvent?.inspectionMode && (
            <div className="row-detail">
              <span className="title">Inspection:</span>
              <span className="offer-detail">
                {lastOfferEvent?.inspectionMode === 'pre_inspection'
                  ? 'Pre-Inspection'
                  : `${lastOfferEvent?.inspectionDays} business days`}
              </span>
            </div>
          )}
        </div>
        <div className="radio-buttons-container">
          <Radio.Group defaultValue={radioValue} onChange={handleRadioChange}>
            <Radio
              value={
                isOfferExpired
                  ? RADIO_OPTIONS.RENEW_OFFER
                  : RADIO_OPTIONS.ACCEPT
              }
              className="accept-offer"
            >
              {getAcceptCTAText()}
            </Radio>
            <div className="counter-reject-offer-container">
              {!isOfferExpired && !finalOffer && (
                <Radio
                  value={RADIO_OPTIONS.COUNTER}
                  className="counter-reject-offer"
                >
                  {isOfferExpired ? 'Request old offer' : 'Counter'}
                </Radio>
              )}
              <Radio
                value={RADIO_OPTIONS.REJECT}
                className="counter-reject-offer"
              >
                Reject offer
              </Radio>
            </div>
          </Radio.Group>
        </div>
        {radioValue === RADIO_OPTIONS.COUNTER && (
          <CounterOffer
            dealSubmission={record}
            dealSubmissionId={record._id}
            isOfferExpired={isOfferExpired}
          />
        )}
        {radioValue === RADIO_OPTIONS.REJECT && (
          <RejectOffer dealSubmissionId={record._id} />
        )}
        {radioValue === RADIO_OPTIONS.ACCEPT && (
          <AcceptOffer
            record={record}
            onRequestContract={onRequestContract}
            isContractLoading={isContractLoading}
            isDoubleClose={lastOfferEvent?.transactionType === 'double_close'}
            isPreInspection={
              lastOfferEvent?.inspectionMode === 'pre_inspection'
            }
          />
        )}
        {radioValue === RADIO_OPTIONS.RENEW_OFFER && (
          <RenewOffer dealSubmissionId={record._id} />
        )}
      </div>
      {shouldShowUnderwritingSection && (
        <div className="offer-underwriting-section">
          <div>
            <span className="underwriting-title">Underwriting assumptions</span>
          </div>
          <Divider />
          <div className="offer-details-container">
            <div className="row-detail">
              <span className="title">ARV:</span>
              <span className="offer-detail">
                {getPriceText(record.underwriting?.arv)}
              </span>
            </div>
            <div className="row-detail">
              <span className="title">Estimated rehab:</span>
              <span className="offer-detail">
                {getPriceText(record.underwriting?.rehabCost)}
              </span>
            </div>
            <div className="row-detail">
              <span className="title">Estimated rent:</span>
              <span className="offer-detail">
                {getPriceText(record.underwriting?.rent)}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
