import { Collapse } from 'antd';
import { useEffect, useState } from 'react';
// import AddBuyboxForm from "../../../../buyBox/BuyboxContainer/Components/AddBuyboxForm";
// import {
//   BUYBOX_VARIANT_ONBOARDING,
//   DEFAULT_BUYBOX_PARAMS,
// } from '../../../../buyBox/BuyboxContainer/constants';

import './index.scss';

const { Panel } = Collapse;

export default (props) => {
  const {
    validationObj,
    organization,
    params: { buyingCriteria, buyBoxList },
  } = props;

  const [activeKey, setActiveKey] = useState([0]);

  useEffect(() => {
    // if (organization?.buyBoxList?.length > 0) {
    props.onChange('buyBoxList', organization?.buyBoxList);
    // }
  }, []);

  const onChange = (newVal) => {
    const updatedBuyBoxList = buyBoxList.map((item, idx) => {
      if (idx === 0) return newVal;
      return item;
    });
    props.onChange && props.onChange('buyBoxList', updatedBuyBoxList);
  };

  if (buyBoxList.length === 0) return null;
  const selectedBuyBox = buyBoxList[0];
  return (
    <div className="onboarding-step buying-criteria">
      <h2>What is your buying criteria?</h2>
      <h3>
        We value your time. We will only send you deals that match your exact
        buying criteria.
      </h3>
      {/* <AddBuyboxForm
        selectedBuyBox={selectedBuyBox}
        areasError={validationObj.buyBoxAreas}
        titleError={validationObj.buyBoxTitle}
        onChange={onChange}
        variant={BUYBOX_VARIANT_ONBOARDING}
      /> */}
    </div>
  );
};
