import Lottie from 'react-lottie';
import { isMobile } from '../../../../../services/clientService';

import * as animationData from './verification.json';

import '../../index.scss';

const DisableApp = ({ isVerified, isBanned, isCompletedOnboarding }) => {
  const isMobileLayout = isMobile();
  return (
    <>
      {isBanned && (
        <div className="banned-user">
          <div className="inner">
            Your account has been blocked.
            <br />
            Please contact support@getzorba.com if you have any questions.{' '}
          </div>
        </div>
      )}
      {!isVerified && isCompletedOnboarding && !isBanned && (
        <div className="non-verified-user-container">
          <img
            className="logo"
            alt="Zorba"
            src={
              isMobileLayout
                ? '/img/brand/zorba-logo-black.png'
                : '/img/brand/zorba-logo-black.png'
            }
          />
          <div className="non-verified-user-content">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
                animationData,
              }}
              width={400}
              height={400}
            />
            <div className="description-container">
              <span className="title">Your account is under review.</span>
              <span>
                Our legal team will verify your account shortly, we will reach
                out to you shortly via email to complete your account
                activation. For any questions please do not hesitate contact us
                at acquisitions@zorbapartners.com.
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DisableApp;
