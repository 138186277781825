import { Select, Radio, Space } from 'antd';
import { v4 as uuid } from 'uuid';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { BUTTON_VARIANT_DARK } from '../../../../../../../components/forms/ZorbaButton/constants';
import { ZorbaButton } from '../../../../../../../components/forms';
import { setAppliedFilters } from '../../../../../../../reducers/Property/actions';

import { options } from '../constants';

import '../index.scss';
import {
  SUBMISSION_WIZARD_STEPS_TITLES,
  SUBMIT_PROPERTY_STEP_REJECTED_BY_UW,
  SUBMIT_PROPERTY_STEP_REJECTED_BY_BUYER,
} from '../../../../SubmitPropertyModal/constants';

export const StatusBuilder = ({ onApplyFilter }) => {
  const dispatch = useDispatch();
  const { appliedFilters } = useSelector(
    ({ PropertyReducer }) => PropertyReducer,
  );
  const [defaultRadioValue] = Object.keys(appliedFilters.step ?? {});
  const defaultSelectValue = defaultRadioValue
    ? appliedFilters.step[defaultRadioValue]
    : [];

  const [radioValue, setRadioValue] = useState(defaultRadioValue);
  const [selectedStatuses, setSelectedStatuses] = useState(defaultSelectValue);
  const statusOptions = Object.keys(SUBMISSION_WIZARD_STEPS_TITLES).map(
    (key) => ({
      label: SUBMISSION_WIZARD_STEPS_TITLES[key],
      value: key,
    }),
  );
  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleSelectChange = (newValue) => {
    setSelectedStatuses(newValue);
  };

  const isApplyButtonDisabled = !radioValue || !selectedStatuses.length;
  const handleApply = () => {
    dispatch(
      setAppliedFilters('step', {
        [radioValue]: selectedStatuses,
      }),
    );
    onApplyFilter();
  };

  return (
    <div className="builder-container">
      <span className="options-title">Status</span>
      <div className="options-container">
        <Radio.Group onChange={handleRadioChange} value={radioValue}>
          <Space direction="vertical">
            {options.map((option) => (
              <Radio key={uuid()} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
        {radioValue && (
          <Select
            mode="tags"
            className="select"
            placeholder="Select Statuses"
            onChange={(newValue) => handleSelectChange(newValue)}
            value={selectedStatuses}
            options={statusOptions}
          />
        )}
      </div>
      <ZorbaButton
        variant={BUTTON_VARIANT_DARK}
        disabled={isApplyButtonDisabled}
        onClick={handleApply}
        fullWidth={false}
      >
        Apply
      </ZorbaButton>
    </div>
  );
};
