import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { FaTimesCircle } from '@react-icons/all-files/fa/FaTimesCircle';

export default (props) => {
  const { preDeal } = props;
  if (!preDeal) return null;

  const isValid = !preDeal?.reasons?.length;

  return (
    <div className={`property-status ${isValid ? 'valid' : 'invalid'}`}>
      {isValid ? <AiFillCheckCircle /> : <FaTimesCircle />}
      <div className="title">
        {isValid
          ? 'Yes, we would love to learn more about your property!'
          : 'Sorry, this property is not in our buybox'}
      </div>
    </div>
  );
};
