import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { push } from 'connected-react-router';
import { Page } from '../../components/layout';
import OnboardingWizard from '../../containers/onboarding/OnboardingWizard';

import './index.scss';

export default (props) => {
  const dispatch = useDispatch();
  const { userDetails } = props;

  useEffect(() => {
    if (userDetails?.isCompletedOnboarding) {
      dispatch(push('/'));
    }
  }, [userDetails]);

  return (
    <Page className="onboarding-page crm" {...props}>
      <OnboardingWizard {...props} />
    </Page>
  );
};
