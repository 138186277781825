import { getUserType } from '../services/userService';

export const TRANSLATION = {
  agent: {
    submit_deal_desc: `Submit your client's properties and off-market deals and receive approval in just 15 seconds. We make fast decisions with fair terms.`,
    submission_form_desc:
      'Add the following details to unlock the offer amount:',
    referral_instructions_desc:
      'Create a passive income stream with fellow agents! Earn a $100 revenue share when a fellow agent sells a client’s property to Zorba',
    referral_invite_title:
      'Invite your fellow agents and jumpstart your earnings together',
    agreement_instructions: `Please note that once your first referred agent signs up and becomes
      active on the platform, we will send a separate broker-to-broker
      referral agreement to you and your broker via email.`,
    agreement: (
      <>
        By participating in the Zorba Real Estate Agent Referral Program
        (“Referral Program”), you agree to the following terms and conditions:
        <ol>
          <li>
            Eligibility: To be eligible to participate in the Referral Program,
            you must be a licensed real estate agent in good standing and in
            compliance with all applicable laws and regulations.
          </li>
          <li>
            Referral Program: As a participant in the Referral Program, you may
            invite other real estate agents to join the Zorba platform. For
            every completed transaction involving a property submitted by an
            agent you referred, you will receive a $100 referral fee, subject to
            the terms and conditions outlined herein.
          </li>
          <li>
            Broker-to-Broker Referral Agreement: Once your first referred agent
            signs up and becomes active on the platform, Zorba will send a
            separate broker-to-broker referral agreement to you and your broker
            via email. This agreement must be executed by both parties in order
            to receive referral fees for completed transactions.
          </li>
          <li>
            Tracking and Payment: You will have access to a dashboard where you
            can track the number of agents you invited and signed up, the number
            of properties submitted by those agents, and the referral fees
            earned. You may request a payout of your referral fee balance at any
            time, subject to verification and approval by Zorba.
          </li>
          <li>
            Compliance with Laws and Regulations: You agree to comply with all
            applicable laws, rules, and regulations in connection with your
            participation in the Referral Program. You further agree to act in
            accordance with the applicable real estate licensing laws and
            professional standards in your jurisdiction.
          </li>
          <li>
            Termination: Zorba reserves the right to terminate your
            participation in the Referral Program at any time, for any reason,
            without prior notice. In the event of termination, you will be
            entitled to any referral fees earned up to the date of termination,
            subject to the terms and conditions of the broker-to-broker referral
            agreement.
          </li>
          <li>
            Modification: Zorba may modify the terms and conditions of the
            Referral Program at any time, with or without prior notice. Your
            continued participation in the Referral Program following any such
            modification constitutes your acceptance of the modified terms and
            conditions.
          </li>
          <li>
            Governing Law: These terms of use and any disputes arising from or
            related to your participation in the Referral Program shall be
            governed by and construed in accordance with the laws of Delaware,
            USA, without regard to its conflict of law principles.
          </li>
        </ol>
      </>
    ),
  },
  investor: {
    submit_pre_deal_desc:
      'Submit a pre-lock property to access early buyer demand and save time on deals you’re still working on. Only available to Zorba Pro Partners',
    submit_deal_desc:
      'Submit a property that you currently have under contract and is ready to sell. Get a strong cash offer along with our contract terms. We make fast decisions with fair terms.',
    submission_form_desc: `Great news! We’re considering making an offer and would like to run the numbers. Please fill in the information below to proceed.`,
    referral_instructions_desc:
      'Create a passive income stream with fellow investors! Give your referrals $100 credit at their first closing with Zorba, and earn a $100 revenue share as well.',
    referral_invite_title:
      'Invite your fellow investors and jumpstart your earnings together',
    agreement_instructions: `Please note that will receive your payout for properties we acquired from your referrals after we close on them via the payout mechanism in the previous screen.`,
    agreement: (
      <>
        By participating in the Zorba Investor Referral Program (“Referral
        Program”), you agree to the following terms and conditions:
        <ol>
          <li>
            Eligibility: To be eligible to participate in the Referral Program,
            you must own an active LLC in good standing and in compliance with
            all applicable laws and regulations.
          </li>
          <li>
            Referral Program: As a participant in the Referral Program, you may
            invite other real estate investors to join the Zorba platform. For
            every completed transaction involving a property submitted by an
            investor/agent you referred, you will receive a $100 referral fee,
            subject to the terms and conditions outlined herein only, and only
            for direct referrals.
          </li>
          <li>
            This program applies only to new referrals that did not exist in
            Zorba's database.
          </li>
          <li>
            Payout: the payout will be pending Zorba getting an invoice for
            consulting fee/marketing fee by the LLC that you own on the payout
            date.
          </li>
          <li>
            Tracking and Payment: You will have access to a dashboard where you
            can track the number of investors you invited and signed up, the
            number of properties submitted by those investors, and the referral
            fees earned. You may request a payout of your referral fee balance
            at any time, subject to verification and approval by Zorba.
          </li>
          <li>
            Compliance with Laws and Regulations: You agree to comply with all
            applicable laws, rules, and regulations in connection with your
            participation in the Referral Program. You further agree to act in
            accordance with the applicable real estate licensing laws and
            professional standards in your jurisdiction.
          </li>
          <li>
            Termination: Zorba reserves the right to terminate your
            participation in the Referral Program at any time, for any reason,
            without prior notice. In the event of termination, you will be
            entitled to any referral fees earned up to the date of termination,
            subject to the terms and conditions of the referral agreement.
          </li>
          <li>
            Modification: Zorba may modify the terms and conditions of the
            Referral Program at any time, with or without prior notice. Your
            continued participation in the Referral Program following any such
            modification constitutes your acceptance of the modified terms and
            conditions.
          </li>
          <li>
            Governing Law: These terms of use and any disputes arising from or
            related to your participation in the Referral Program shall be
            governed by and construed in accordance with the laws of Delaware,
            USA, without regard to its conflict of law principles.
          </li>
        </ol>
      </>
    ),
  },
};

export const translateKey = (userDetails, key) => {
  const userType = getUserType(userDetails);

  return TRANSLATION[userType] ? TRANSLATION[userType][key] : '';
};
