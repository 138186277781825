import { useMemo } from 'react';
import { Button, Form, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FiX } from '@react-icons/all-files/fi/FiX';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';

import {
  resetAppliedFilters,
  setAppliedFilters,
} from '../../../../../../reducers/Property/actions';
import { PREDEFINED_VIEWS } from '../constants';

const prefix = (
  <SearchOutlined
    style={{
      fontSize: 20,
      color: '#8A9099',
    }}
  />
);

const SEARCH_THROTTLE_DELAY = 1000;

export const AddressFilter = ({ form, setPredefinedView }) => {
  const dispatch = useDispatch();
  const searchQueryHandler = useMemo(
    () =>
      debounce((event) => {
        const query = event.target.value.trim();
        dispatch(setAppliedFilters('query', { regex: query }));
        setPredefinedView(PREDEFINED_VIEWS.ALL_DEALS);
      }, SEARCH_THROTTLE_DELAY),
    [],
  );

  const resetAddressFilter = () => {
    dispatch(resetAppliedFilters());
    form.resetFields();
  };

  return (
    <Form layout="horizontal" name="querySearchForm" form={form}>
      <div className="form-field form-field__search">
        <div className="form-field__item">
          <div className="form-field__item__input">
            <Input.Group compact className="search-field">
              <Form.Item name="query">
                <Input
                  size="large"
                  prefix={prefix}
                  className="search-input"
                  placeholder="Filter properties by address"
                  onChange={searchQueryHandler}
                />
              </Form.Item>
              <Button
                size="large"
                icon={<FiX size={25} />}
                onClick={resetAddressFilter}
              />
            </Input.Group>
          </div>
        </div>
      </div>
    </Form>
  );
};
