import { push, replace } from 'connected-react-router';

import _ from 'lodash';
import { getCookie } from '@zorba-shared/client/Services/clientService';
import {
  reportEvent,
  setUserProperties,
  setUserProperty,
} from '../../services/analyticsService';
import {
  getAuthToken,
  logoutUser,
  QUERY_PARAM_DEAL_SUBMISSION_ID,
  QUERY_PARAM_MAGIC_TOKEN,
  QUERY_PARAM_MODAL,
  QUERY_PARAM_ORG_INVITE,
  QUERY_PARAM_USER_ID,
  setAuthToken,
} from '../../services/authService';
import {
  getAndDeleteCookie,
  getQueryParam,
} from '../../services/clientService';
import { clearLatestViewedEntities } from '../../services/crm/latestActivityService';
import { logError } from '../../services/loggingService';
import { showErrorToast } from '../../services/notificationsService';
import { getRequest, postRequest } from '../../services/requestService';
import {
  buildHomePageUrl,
  buildLoginUrlWithRedirect,
  buildOnboardingPageUrl,
  removeQueryParam,
  getRedirectUrl,
  buildAuthPageUrl,
} from '../../services/urlBuilderService';
import { buildQueryString } from '../../services/urlService';
import { getTrackingParams } from '../../services/userTrackingService';
import { setModalState } from '../Modal/actions';
import {
  ALLOWED_MODALS_TO_OPEN_BY_QUERY,
  MODAL_PARAM_ONBOARDING,
} from '../Modal/types';
import * as types from './types';

export function setAuthLoadingState(isAuthLoading) {
  return {
    type: types.SET_AUTH_LOADING_STATE,
    payload: { isAuthLoading },
  };
}

export const providerLogin = (formData) => async (dispatch) => {
  dispatch({ type: types.PROVIDER_LOGIN, payload: { isAuthLoading: true } });
  formData.emailInviteId = getQueryParam('email_invite');
  const orgInviteId = getAndDeleteCookie(QUERY_PARAM_ORG_INVITE);
  const dealSubmissionId = getAndDeleteCookie(QUERY_PARAM_DEAL_SUBMISSION_ID);
  if (dealSubmissionId) {
    formData[QUERY_PARAM_DEAL_SUBMISSION_ID] = dealSubmissionId;
  }
  formData.kind = getAndDeleteCookie('user_kind');
  formData.redirectUri = buildAuthPageUrl({ isAbsolute: true });
  if (orgInviteId) {
    formData[QUERY_PARAM_ORG_INVITE] = orgInviteId;
  }

  formData.trackingParams = getTrackingParams();
  formData.referringUserId = getCookie('referring_user_id');
  try {
    const res = await postRequest('/auth/provider-login', formData);

    const { isNewUser, token, userDetails } = res.data;
    if (!token) throw new Error('Missing token');
    setAuthToken(token);
    if (isNewUser) reportEvent('auth', 'register_success');
    reportEvent('auth', 'login_success', {
      isNewUser,
      strategy: formData.strategy,
    });
    userDetails && setUserProperties(userDetails);
    dispatch({
      type: types.SET_USER_DATA,
      payload: {
        isAuthLoading: false,
        isAppLoading: false,
        isInit: true,
        ...res.data,
      },
    });

    redirectAfterLoginOrSignup(dispatch, userDetails);
    dispatch(postLoginTasks(res));
    clearLatestViewedEntities();
  } catch (e) {
    reportEvent('auth', 'login_failed', { source: 'zorba_api' });
    showErrorToast('Oops. Something happened');
  }
  dispatch({ type: types.PROVIDER_LOGIN, payload: { isAuthLoading: false } });
};

const redirectAfterLoginOrSignup = (dispatch, userDetails) => {
  // if (!isPhoneNumberVerified(userDetails)) {
  //   reportEvent("auth", "require_phone_verification");
  //   const url = buildAuthPageUrl(false, AUTH_STATE_UPDATE_PHONE_NUMBER);
  //   dispatch(push(url));
  // }
  if (!userDetails.isCompletedOnboarding) {
    dispatch(push(buildOnboardingPageUrl()));
  } else {
    let redirectUrl = getRedirectUrl();
    if (!redirectUrl) {
      redirectUrl = buildHomePageUrl();
    }
    dispatch(replace(redirectUrl));
  }
};

export const localAuth = (formData, callback) => async (dispatch) => {
  dispatch({ type: types.LOCAL_AUTH, payload: { isAuthLoading: true } });
  formData.emailInviteId = getQueryParam('email_invite');
  formData.referringUserId = getCookie('referring_user_id');
  formData.kind = getCookie('user_kind');
  const orgInviteId = getAndDeleteCookie(QUERY_PARAM_ORG_INVITE);
  if (orgInviteId) {
    formData[QUERY_PARAM_ORG_INVITE] = orgInviteId;
  }
  const dealSubmissionId = getAndDeleteCookie(QUERY_PARAM_DEAL_SUBMISSION_ID);
  if (dealSubmissionId) {
    formData[QUERY_PARAM_DEAL_SUBMISSION_ID] = dealSubmissionId;
  }
  formData.trackingParams = getTrackingParams();

  try {
    const res = await postRequest('/auth/local-auth', formData);
    const { isNewUser, token, userDetails } = res.data;
    if (!token) throw new Error('Missing token');
    setAuthToken(token);

    if (userDetails) setUserProperties(userDetails);
    dispatch({
      type: types.SET_USER_DATA,
      payload: {
        isAuthLoading: false,
        isAppLoading: false,
        isInit: true,
        ...res.data,
      },
    });
    dispatch(postLoginTasks(res));
    if (isNewUser) {
      reportEvent('auth', 'register_success');
    }

    reportEvent('auth', 'login_success', {
      isNewUser,
      strategy: 'local',
    });
    redirectAfterLoginOrSignup(dispatch, userDetails);

    clearLatestViewedEntities();
  } catch (e) {
    reportEvent('auth', 'local_auth_api_failed', { source: 'zorba_api' });

    e.response?.data?.reasonText &&
      showErrorToast(e.response?.data?.reasonText);
  }
  dispatch({ type: types.LOCAL_AUTH, payload: { isAuthLoading: false } });
};

export const localAuthOtp = (formData, callback) => async (dispatch) => {
  dispatch({ type: types.LOCAL_AUTH, payload: { isAuthLoading: true } });
  formData.emailInviteId = getQueryParam('email_invite');
  const orgInviteId = getAndDeleteCookie(QUERY_PARAM_ORG_INVITE);
  if (orgInviteId) {
    formData[QUERY_PARAM_ORG_INVITE] = orgInviteId;
  }
  const dealSubmissionId = getAndDeleteCookie(QUERY_PARAM_DEAL_SUBMISSION_ID);
  if (dealSubmissionId) {
    formData[QUERY_PARAM_DEAL_SUBMISSION_ID] = dealSubmissionId;
  }
  formData.trackingParams = getTrackingParams();

  try {
    const res = await postRequest('/auth/local-auth-otp', formData);

    const { isNewUser, token, userDetails } = res.data;
    if (!token) throw new Error('Missing token');

    setAuthToken(token);
    // isNewUser && reportEvent("auth", "register_success");
    reportEvent('auth', 'login_success', {
      isNewUser,
      strategy: 'local',
    });
    userDetails && setUserProperties(userDetails);
    dispatch({
      type: types.SET_USER_DATA,
      payload: {
        isAuthLoading: false,
        isAppLoading: false,
        isInit: true,
        ...res.data,
      },
    });

    redirectAfterLoginOrSignup(dispatch, userDetails);
    dispatch(postLoginTasks(res));
    clearLatestViewedEntities();
  } catch (e) {
    reportEvent('auth', 'local_auth_api_failed_otp', { source: 'zorba_api' });

    e.response?.data?.reasonText &&
      showErrorToast(e.response?.data?.reasonText);
  } finally {
    callback();
  }
  dispatch({ type: types.LOCAL_AUTH, payload: { isAuthLoading: false } });
};

export function setGuest() {
  return {
    type: types.SET_GUEST,
    payload: { isInit: true },
  };
}

export const getUserData = (uid = '', placeFriendlyUrl = '') => async (
  dispatch,
) => {
  let shouldRefreshUrl = false;
  let newUrl;
  const magicToken = getQueryParam(QUERY_PARAM_MAGIC_TOKEN);
  if (uid) {
    newUrl = removeQueryParam(
      window.location.pathname + window.location.search,
      'uid',
    );
    shouldRefreshUrl = true;
  }
  // if (magicToken) {
  //   newUrl = removeQueryParam(newUrl || (window.location.pathname + window.location.search), QUERY_PARAM_MAGIC_TOKEN);
  //   shouldRefreshUrl = true;
  // }

  if (shouldRefreshUrl) {
    dispatch(replace(newUrl));
  }
  try {
    dispatch({
      type: types.SET_USER_DATA,
      payload: { isAppLoading: true, isAuthLoading: true, isInit: true },
    });

    const requestUrl = buildQueryString('/auth/get-user-data', {
      [QUERY_PARAM_USER_ID]: uid,
      placeFriendlyUrl,
    });

    const res = await getRequest(
      requestUrl,
      {},
      {
        headers: {
          'x-magic-token': magicToken,
          'x-access-token': getAuthToken(),
        },
      },
    );
    res?.data?.userDetails && setUserProperties(res.data.userDetails);
    const organization = res?.data?.userDetails?.defaultOrganization;
    dispatch({
      type: types.SET_USER_DATA,
      payload: {
        ...res.data,
        isAuthLoading: false,
        isAppLoading: false,
        organization,
      },
    });

    if (getQueryParam(QUERY_PARAM_MODAL)) {
      const modalParamName = `is${getQueryParam(QUERY_PARAM_MODAL)}ModalOpen`;
      setTimeout(() => {
        if (ALLOWED_MODALS_TO_OPEN_BY_QUERY.includes(modalParamName)) {
          dispatch(setModalState(modalParamName, true));
        }
      }, 1000);

      const newUrl = removeQueryParam(
        window.location.pathname + window.location.search,
        QUERY_PARAM_MODAL,
      );
      dispatch(push(newUrl));
    } else if (!res?.data?.userDetails?.isCompletedOnboarding) {
      dispatch(setModalState(MODAL_PARAM_ONBOARDING, true));
    }
    if (res?.data?.userDetails?._id) {
      setUserProperty('userId', res?.data?.userDetails?._id);
      setUserProperty('guest', false);
    }
    // if (
    //   ["/", "/o"].includes(window.location.pathname) &&
    //   res?.data?.userDetails?.defaultOrganization?._id &&
    //   res?.data?.userDetails?.isCompletedOnboarding
    // ) {
    //   const orgUrl = buildCRMPageUrl(res.data.userDetails);
    //   dispatch(push(orgUrl));
    // }
  } catch (e) {
    if (e?.response?.status === 401) {
      const redirectUrl = removeQueryParam(
        window.location.pathname + window.location.search,
        QUERY_PARAM_USER_ID,
      );
      logoutUser();

      const loginUrl = buildLoginUrlWithRedirect(false, false, {}, redirectUrl);
      loginUrl && dispatch(push(loginUrl));
      dispatch({
        type: types.SET_USER_DATA,
        payload: { isAuthLoading: false, isAppLoading: false },
      });
    } else {
      showErrorToast('Oops. Network Error');
      logError(e);
    }
  }
};

export const getLatestNotifications = () => async (dispatch, getState) => {
  try {
    const { userDetails } = getState().AuthReducer;
    dispatch({
      type: types.SET_LATEST_NOTIFICATIONS,
      payload: {
        isNotificationLoading: true,
        userDetails: { ...userDetails, numOfUnreadNotifications: 0 },
      },
    });
    const res = await getRequest('/notification/get-latest');
    const updatedUserDetails = getState().AuthReducer.userDetails;
    updatedUserDetails.numOfUnreadNotifications =
      res.data.numOfUnreadNotifications;
    dispatch({
      type: types.SET_LATEST_NOTIFICATIONS,
      payload: {
        isNotificationLoading: false,
        ...res.data,
        userDetails: updatedUserDetails,
      },
    });
  } catch (e) {
    dispatch({
      type: types.SET_LATEST_NOTIFICATIONS,
      payload: { isNotificationLoading: false },
    });
  }
};
export const markNotificationAsRead = (eventId) => async (
  dispatch,
  getState,
) => {
  try {
    const payload = {};
    const res = await postRequest('/notification/mark-as-read', { eventId });
    const updatedNotifications = getState().AuthReducer.notificationList.map(
      (notification) => {
        if (notification._id.toString() === eventId.toString()) {
          notification.isRead = true;
        }
        return notification;
      },
    );
    if (res.data.success) {
      const { userDetails } = getState().AuthReducer;
      userDetails.numOfUnreadNotifications = Math.max(
        0,
        userDetails.numOfUnreadNotifications - 1,
      );
      payload.userDetails = { ...userDetails };
    }
    payload.notificationList = updatedNotifications;
    dispatch({ type: types.SET_LATEST_NOTIFICATIONS, payload });
  } catch (e) {}
};

export const postLoginTasks = (response) => async (dispatch) => {
  if (getQueryParam(QUERY_PARAM_MODAL)) {
    const modalParamName = `is${getQueryParam(QUERY_PARAM_MODAL)}ModalOpen`;
    setTimeout(() => {
      if (ALLOWED_MODALS_TO_OPEN_BY_QUERY.includes(modalParamName)) {
        dispatch(setModalState(modalParamName, true));
      }
    }, 1000);

    const newUrl = removeQueryParam(
      window.location.pathname + window.location.search,
      QUERY_PARAM_MODAL,
    );
    dispatch(push(newUrl));
  } else if (!response?.data?.userDetails?.isCompletedOnboarding) {
    dispatch(setModalState(MODAL_PARAM_ONBOARDING, true));
  }
  if (response?.data?.userDetails?._id) {
    setUserProperty('userId', response?.data?.userDetails?.id);
    setUserProperty('guest', false);
  }
};

export const updateUserDetails = (formData, callback) => async (
  dispatch,
  getState,
) => {
  dispatch({
    type: types.UPDATE_USER_DETAILS,
    payload: { isUpdateUserDetailsLoading: true },
  });
  try {
    const res = await postRequest('/user/update-details', formData);

    const updatedUserDetails = {
      ...getState().AuthReducer.userDetails,
      ...formData,
      defaultOrganization: { ...res.data.userDetails.defaultOrganization },
    };

    if (formData.firstName || formData.lastName) {
      const { firstName, lastName } = updatedUserDetails;
      updatedUserDetails.name = `${firstName} ${lastName}`;
    }

    dispatch({
      type: types.UPDATE_USER_DETAILS,
      payload: {
        userDetails: updatedUserDetails,
        isUpdateUserDetailsLoading: false,
        organization: { ...res.data.userDetails.defaultOrganization },
      },
    });
    if (callback) {
      callback(res.data.userDetails);
    }
  } catch (e) {
    logError(e);
    showErrorToast(
      'Oops. We had an issue updating your profile, plese try again later',
    );
    dispatch({
      type: types.UPDATE_USER_DETAILS,
      payload: { isUpdateUserDetailsLoading: false },
    });
  }
};

export const setUserDetails = (key, value, callback) => async (
  dispatch,
  getState,
) => {
  const { userDetails } = getState().AuthReducer;
  userDetails[key] = value;
  dispatch({ type: types.SET_USER_DATA, payload: { userDetails } });
};

export const createOrganization = (params, isSubmit = false) => async (
  dispatch,
  getState,
) => {
  const updatedUserDetails = getState().AuthReducer.userDetails;
  const prevDetails = updatedUserDetails.defaultOrganization || {};
  updatedUserDetails.defaultOrganization = {
    ...prevDetails,
    ...params,
  };

  dispatch({
    type: types.SET_USER_DATA,
    payload: {
      userDetails: { ...updatedUserDetails },
    },
  });

  if (isSubmit) {
    const res = await postRequest('/organization/create', params);
    const { organization } = res.data;
    updatedUserDetails.defaultOrganization = { ...organization };
    dispatch({
      type: types.SET_USER_DATA,
      payload: {
        userDetails: updatedUserDetails,
        organization: { ...organization },
      },
    });
  }
};

export const updateOrganizationDetails = (params) => async (
  dispatch,
  getState,
) => {
  let res;

  const hasChangedparams = Object.keys(params).length > 0;
  if (!hasChangedparams) return;
  try {
    res = await postRequest('/organization/update', params);
    const organization = res?.data?.organization;
    let updatedUserDetails = getState().AuthReducer.userDetails;
    updatedUserDetails = {
      ...updatedUserDetails,
      defaultOrganization: { ...organization },
    };
    dispatch({
      type: types.SET_USER_DATA,
      payload: { userDetails: updatedUserDetails, organization },
    });
  } catch (e) {
    logError(e);
    showErrorToast('Oops, Please try again later');
  }
};

export const createAnotherOrganization = () => async (dispatch, getState) => {
  const updatedUserDetails = getState().AuthReducer.userDetails;
  updatedUserDetails.defaultOrganization = undefined;
  dispatch({
    type: types.SET_USER_DATA,
    payload: { userDetails: updatedUserDetails, organization: undefined },
  });

  const onboardingUrl = buildOnboardingPageUrl();
  dispatch(push(onboardingUrl));
};

export const updateUserDataParams = (params) => (dispatch, getState) => {
  const updatedUserDetails = {
    ...getState().AuthReducer.userDetails,
    ...params,
  };
  dispatch({
    type: types.SET_USER_DATA,
    payload: { userDetails: updatedUserDetails },
  });
};

export const setOrganization = (organization) => async (dispatch, getState) => {
  const { userDetails } = getState().AuthReducer;
  const updatedUserDetails = {
    ...userDetails,
    defaultOrganizatin: organization,
  };
  dispatch({
    type: types.SET_USER_DATA,
    payload: { organization, userDetails: updatedUserDetails },
  });
};

export const setEmailVerified = () => async (dispatch, getState) => {
  const { userDetails } = getState().AuthReducer;
  const updatedUserDetails = {
    ...userDetails,
    isEmailVerified: true,
  };
  dispatch({
    type: types.SET_USER_DATA,
    payload: { userDetails: updatedUserDetails },
  });
};

export const setPhoneVerified = (updatedNumebr) => async (
  dispatch,
  getState,
) => {
  const { userDetails } = getState().AuthReducer;
  const updatedUserDetails = {
    ...userDetails,
    phoneNumber: {
      ...userDetails.phoneNumber,
      verified: true,
      number: updatedNumebr,
    },
  };
  dispatch({
    type: types.SET_USER_DATA,
    payload: { userDetails: updatedUserDetails },
  });
};
