import { buildAuthPageUrl } from '@zorba-shared/core/Services/urlBuilderService';
import { Badge, Button, Drawer, Dropdown, Menu, Popover } from 'antd';
import { push } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserInfo from '../../../components/auth/UserInfo';
import { setModalState } from '../../../reducers/Modal/actions';
import { logoutUser } from '../../../services/authService';
import { isMobile } from '../../../services/clientService';
import CRMSidebar from '../../crm/CRMSidebar';
import LoginButton from './Components/LoginButton';
import './index.scss';

const Header = (props) => {
  const { userDetails, history, match, isAuthLoading, isInit } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isRequestedDetails, setIsRequestedDetails] = useState(false);
  const shouldShowSidebar = props.userDetails !== undefined;
  const isGuestMode = isInit && !isAuthLoading && !userDetails;
  const isAuthPage = window.location.pathname.startsWith('/auth');
  useEffect(() => {
    if (
      isRequestedDetails &&
      !history.location.pathname.includes('/project/')
    ) {
      setIsRequestedDetails(false);
    }
  }, [match, history]);
  const isMobileLayout = isMobile();
  const isDisabled = window.location.pathname.includes('onboarding');
  const isDrawerVisible = !window.location.pathname.includes('/onboarding');
  const { numOfUnreadMessages, numOfUnreadNotifications } = userDetails || {};
  const isHomeownerMode = window.location.pathname === '/homeowner';
  const getLogoLink = () => {
    if (isHomeownerMode) return '/homeowner';
    return userDetails ? '/' : `${buildAuthPageUrl()}${window.location.search}`;
  };
  return (
    <>
      <div className="layout-header">
        <div className="layout-header-inner">
          <div className="left">
            {(isMobileLayout || isDisabled) && userDetails && isDrawerVisible && (
              <Button
                className="menu-btn"
                onClick={() => shouldShowSidebar && setIsSidebarOpen(true)}
              >
                <i className="fas fa-bars menu-icon" />
              </Button>
            )}
            <img
              className="mobile-logo"
              alt="Zorba"
              src={
                isMobileLayout
                  ? '/img/brand/zorba-logo-black.png'
                  : '/img/brand/zorba-logo-black.png'
              }
            />
          </div>
          <div className="right">
            {!isDisabled && isInit && userDetails && (
              <Dropdown
                placement="bottomLeft"
                overlay={
                  <Menu>
                    <Menu.Item onClick={() => logoutUser(true)}>
                      Logout
                    </Menu.Item>
                  </Menu>
                }
              >
                <div className="ant-dropdown-link">
                  <UserInfo
                    rounded
                    userDetails={userDetails}
                    showtitle={false}
                  />
                </div>
              </Dropdown>
            )}
            {/* When user is checking properties as a guest */}
            {isGuestMode && !isHomeownerMode ? (
              <LoginButton {...props} />
            ) : (
              <div className="login-btn-empty-state"> </div>
            )}
          </div>
        </div>
      </div>
      {isDrawerVisible && !isAuthPage && (
        <Drawer
          className="header-drawer"
          placement="left"
          closable
          onClose={() => setIsSidebarOpen(false)}
          visible={isSidebarOpen}
        >
          <CRMSidebar
            {...props}
            onCloseSidebar={() => setIsSidebarOpen(false)}
            isDisabled={isDisabled}
          />
        </Drawer>
      )}
    </>
  );
};
const mapStateToProps = ({ AuthReducer }) => {
  const { userDetails, notificationList, isAuthLoading, isInit } = AuthReducer;

  return {
    userDetails,

    isAuthLoading,
    isInit,
  };
};
const mapDispatchToProps = {
  push,
  setModalState,
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
