import { getPriceText } from '@zorba-shared/core/Services/textService';

export const UnderwritingResult = ({ selectedDeal }) => (
  <div className="offer-details-container">
    <div className="row-detail">
      <span className="title">ARV:</span>
      <span className="offer-detail">
        {getPriceText(selectedDeal.underwriting?.arv)}
      </span>
    </div>
    <div className="row-detail">
      <span className="title">Estimated rehab:</span>
      <span className="offer-detail">
        {getPriceText(selectedDeal.underwriting?.rehabCost)}
      </span>
    </div>
    <div className="row-detail">
      <span className="title">Estimated rent:</span>
      <span className="offer-detail">
        {getPriceText(selectedDeal.underwriting?.rent)}
      </span>
    </div>
  </div>
);
