import { isDev, isMobile } from '@zorba-shared/client/Services/clientService';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'antd';

import { PlacesAutoComplete } from '../../../../components/forms';
import { getMatchedBuyers } from '../../service';
import { reportEvent } from '../../../../services/analyticsService';
import { getAndDeleteCookie } from '../../../../services/clientService';
import { translateKey } from '../../../../constants/translation';

import './index.scss';

export default (props) => {
  const { setAddress, loading, setLoading, address, step } = props;
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const onPlacesChange = async (newAddress) => {
    if (!newAddress) return;
    setAddress(newAddress);
    setLoading(true);
    reportEvent('pre-deal', 'submit_address', {
      address: newAddress?.formattedAddress,
    });
    const { updatedMatch } = await getMatchedBuyers({
      address: newAddress,
      step,
    });
    props.onContinue(updatedMatch);
    window.scrollTo(0, 0);
    setTimeout(
      () => {
        setLoading(false);
        setAddress();
      },
      isDev() || userDetails?.email === 'acquisitions@getzorba.com' ? 1 : 3500,
    );
  };

  useEffect(() => {
    if (
      userDetails?.isCompletedOnboarding &&
      userDetails?.defaultOrganization?.verification?.verified
    ) {
      const cookieAddress = getAndDeleteCookie('address');
      if (cookieAddress) {
        const formattedCookieAddress = JSON.parse(cookieAddress);
        setAddress(formattedCookieAddress);
        onPlacesChange(formattedCookieAddress);
      }
    }
  }, []);

  return (
    <div className="instructions">
      <Card className="top" bordered={!isMobile()}>
        <h3 className="instructions-title">Get a Pre-lock Offer™</h3>
        <p className="desc">
          {translateKey(userDetails, 'submit_pre_deal_desc')}
        </p>
        <PlacesAutoComplete
          clearInputOnSelect={false}
          className="address-search"
          title={null}
          clearInputOnBlur={false}
          disabled={loading || address}
          loading={loading}
          allowFullAddress
          placeholder="Instantly check an address"
          onChange={(value) => onPlacesChange(value)}
        />
      </Card>
    </div>
  );
};
