import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_PARAM_REQUEST_REEVALUATION } from '../../../../../../reducers/Modal/types';
import { ZorbaButton, ZorbaInput } from '../../../../../../components/forms';
import { requestReevaluation } from '../../../../../../reducers/Property/actions';

import './index.scss';

export const RequestReevaluationModal = ({ dealSubmissionId }) => {
  const { isRequestReevaluationModalOpen } = useSelector(
    ({ ModalReducer }) => ModalReducer,
  );
  const dispatch = useDispatch();
  const [updatedAskingPrice, setUpdatedAskingPrice] = useState();

  const handleSubmit = () => {
    dispatch(requestReevaluation({ dealSubmissionId, updatedAskingPrice }));
  };

  return (
    <Modal
      centered
      open={isRequestReevaluationModalOpen}
      footer={null}
      onCancel={() =>
        dispatch(setModalState(MODAL_PARAM_REQUEST_REEVALUATION, false))
      }
      destroyOnClose
      title="Request reevaluation"
    >
      <div className="request-reevaluation-modal-container">
        <div className="info-container">
          <span>
            Please update the asking price in order to submit a request for
            reevaluation to our underwriting team.
          </span>
        </div>
        <div className="form-container">
          <div className="input-container">
            <ZorbaInput
              label="Updated asking price*"
              onChange={(value) => setUpdatedAskingPrice(value)}
              type="number"
              value={updatedAskingPrice}
              symbol=""
              addonBefore="$"
              fullWidth
              minValue={0}
            />
          </div>
          <div className="button-container">
            <ZorbaButton
              disabled={!updatedAskingPrice || !dealSubmissionId}
              className="request-reevaluation-button"
              fullWidth
              onClick={handleSubmit}
            >
              Submit
            </ZorbaButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
