import Cookies from 'js-cookie';

import {
  ONBOARDING_STEP_BUYING_CRITERIA,
  ONBOARDING_STEP_PHONE,
} from '../containers/onboarding/OnboardingWizard/constants';
import {
  QUERY_PARAM_FORCE_AUTH,
  QUERY_PARAM_REDIRECT_URL,
  QUERY_PARAM_MAGIC_TOKEN,
} from './authService';
import { getQueryParam } from './clientService';

const DEFAULT_BASE_URL = 'https://partners.getzorba.com/';
const BASE_URL_BY_ENV = {
  development: 'https://partners.getzorba.com:3003/',
  staging: 'https://stgpartners.getzorba.com/',
  production: DEFAULT_BASE_URL,
};
const getBaseUrl = (isAbsolute) => {
  const baseUrl =
    BASE_URL_BY_ENV[process.env.REACT_APP_HOST_ENV] || DEFAULT_BASE_URL;

  return isAbsolute ? baseUrl : '/';
};

export const buildAuthPageUrl = ({ isAbsolute, authState }) =>
  `${getBaseUrl(isAbsolute)}auth/${authState || 'login'}`;

export const buildHomePageUrl = (isAbsolute = false) =>
  `${isAbsolute ? 'https://www.getzorba.com' : ''}/`;
export const buildProjectFriendlyUrl = (friendlyUrl, isAbsolute = true) => {
  if (!friendlyUrl) return '/';

  return `${
    isAbsolute ? 'https://www.getzorba.com/project' : '/project'
  }/${friendlyUrl}`;
};

export const buildUserPageUrl = (userDetails, isAbsolute = false) => {
  if (!userDetails?.friendlyUrl) return 'https://www.getzorba.com/';

  return `${isAbsolute ? 'https://www.getzorba.com' : ''}/user/${
    userDetails.friendlyUrl
  }`;
};

export const buildInviteEmailPageUrl = (isAbsolute = false) =>
  `${isAbsolute ? 'https://www.getzorba.com' : ''}/referrals/invite`;

export const removeQueryParam = (url, parameter) => {
  // prefer to use l.search if you have a location/link object
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const prefix = `${encodeURIComponent(parameter)}=`;
    const pars = urlparts[1].split(/[&;]/g);

    // reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0; ) {
      // idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? `?${pars.join('&')}` : '');
  }
  return url;
};

/**
 * Build the login URL with `redirect_url` being set in HTTP query parameters and cookies.
 * It also removes `force_auth` HTTP query parameter.
 *
 * @param {boolean} [absolute=false] - Generate absolute URL. If false, returns a relative URL.
 * @param {boolean} [withoutRedirect=false] - Generate the URL without setting `redirect_url`.
 * @param {Object} [additionalQueryParams={}] - Additional query parameters to fill.
 * @param {string} [override=""] - Override the `redirect_url` with the given value.
 * @return {string} URL that's built.
 *
 * @example
 *
 *    buildLoginUrlWithRedirect()
 *    buildLoginUrlWithRedirect(false, false, {request_details: true})
 *    buildLoginUrlWithRedirect(false, false, {}, "/example")
 */
export const buildLoginUrlWithRedirect = (
  absolute = false,
  withoutRedirect = false,
  additionalQueryParams = {},
  override = '',
) => {
  let baseUrl = buildAuthPageUrl({ isAbsolute: true });
  if (window.location.search) {
    baseUrl += window.location.search;
  }

  const url = new URL(baseUrl);
  if (!withoutRedirect) {
    const redirectUrl = new URL(
      override ||
        window.location.pathname +
          window.location.search +
          window.location.hash,
      window.location.origin,
    );
    redirectUrl.searchParams.delete(QUERY_PARAM_MAGIC_TOKEN);
    const redirectUrlStr =
      redirectUrl.pathname + redirectUrl.search + redirectUrl.hash;
    url.searchParams.set(QUERY_PARAM_REDIRECT_URL, redirectUrlStr);
    Cookies.set(QUERY_PARAM_REDIRECT_URL, redirectUrlStr, { expires: 5 / 24 }); // Expires in 5 hours
  }
  url.searchParams.delete(QUERY_PARAM_FORCE_AUTH);
  url.searchParams.delete(QUERY_PARAM_MAGIC_TOKEN);

  for (const [key, value] of Object.entries(additionalQueryParams)) {
    url.searchParams.set(key, value);
  }

  if (absolute) return url.href;
  return url.pathname + url.search;
};

/**
 * Build the requested auth page URL by preserving the `redirect_url` HTTP query parameter.
 *
 * @param {string} [authState=AUTH_STATE_INITIAL] - Auth page step to iterate AUTH_STATES array.
 * @param {boolean} [absolute=false] - Generate absolute URL. If false, returns a relative URL.
 * @return {string} URL that's built.
 *
 * @example
 *
 *    buildAuthPageUrlWithRedirect()
 *    buildAuthPageUrlWithRedirect(AUTH_STATE_LOGIN_PASSWORD, false)
 */
export const buildAuthPageUrlWithRedirect = (
  authState = AUTH_STATE_INITIAL,
  absolute = false,
) => {
  const baseUrl = buildAuthPageUrl({ isAbsolute: true, authState });
  const url = new URL(baseUrl);
  const redirectUrl = getRedirectUrl();
  if (redirectUrl) {
    url.searchParams.set(QUERY_PARAM_REDIRECT_URL, redirectUrl);
  }

  if (absolute) return url.href;
  return url.pathname + url.search + url.hash;
};

export const getRedirectUrl = () => {
  let result = getQueryParam(QUERY_PARAM_REDIRECT_URL);
  if (!result) {
    result = Cookies.get(QUERY_PARAM_REDIRECT_URL);
  }
  Cookies.remove(QUERY_PARAM_REDIRECT_URL);
  return result;
};

export const buildInboxThreadUrl = (thread, isAbsolute = false) =>
  `${isAbsolute ? 'https://www.getzorba.com' : ''}/inbox/${thread._id}`;

export const buildNotificationPageUrl = () => `/notifications`;

export const buildReferralPageUrl = () => `/referrals`;

export const buildReferralInviteUrl = (userDetails, isAbsolute = true) => {
  if (!userDetails?.friendlyUrl) return '';
  return `${isAbsolute ? 'https://www.getzorba.com' : ''}/invite/${
    userDetails.friendlyUrl
  }`;
};

export const buildOnboardingPageUrl = (stepKey = ONBOARDING_STEP_PHONE) => {
  const url = new URL(
    `/o/onboarding/${stepKey}${window.location.search}`,
    'https://partners.getzorba.com',
  );
  const redirectUrl = getRedirectUrl();
  if (redirectUrl) {
    url.searchParams.set(QUERY_PARAM_REDIRECT_URL, redirectUrl);
  }

  return url.pathname + url.search + url.hash;
};

export const buildPlaceFeedPageUrl = (selectedPlace, page) => {
  const queryParams = page > 1 ? `?p=${page}` : '';
  const baseUrl = buildDiscoverPageUrl();
  if (!selectedPlace) return `${baseUrl}${queryParams}`;

  return `${baseUrl}p/${selectedPlace.friendlyUrl}${queryParams}`;
};

export const buildPlaceFriendlyUrl = (selectedPlace, page) => {
  const baseUrl = buildDiscoverPageUrl();
  const { stateCode, locality } = selectedPlace;

  if (!stateCode) return baseUrl;
  let targetUrl = `${baseUrl}${stateCode}`;
  if (locality) {
    targetUrl += `/${locality}`;
  }

  targetUrl = `${targetUrl}${page > 1 ? `?p=${page}` : ''}`;

  return targetUrl.replace('p/', '');
};

export const buildPlacePlageUrl = (selectedPlace) =>
  `${buildDiscoverPageUrl()}${selectedPlace?.friendlyUrl || ''}`;

export const buildCRMPageUrl = (userDetails) => {
  if (!userDetails?.defaultOrganization?.friendlyUrl)
    return buildDiscoverPageUrl();
  return `/o/${userDetails?.defaultOrganization?.friendlyUrl}`;
};

export const buildDealPageUrl = (userDetails, deal) => {
  // const
  if (!userDetails?.defaultOrganization?.friendlyUrl)
    return buildDiscoverPageUrl();
  return `/o/${userDetails?.defaultOrganization?.friendlyUrl}/properties/${deal?._id}`;
};

export const buildCRMDealsPageUrl = (userDetails) => {
  if (!userDetails?.defaultOrganization?.friendlyUrl)
    return buildDiscoverPageUrl();
  return `/o/${userDetails.defaultOrganization.friendlyUrl}/properties`;
};

export const buildCRMContactsPageUrl = (userDetails) => {
  if (!userDetails?.defaultOrganization?.friendlyUrl)
    return buildDiscoverPageUrl();
  return `/o/${userDetails.defaultOrganization.friendlyUrl}/contacts`;
};

export const buildCRMContactPageUrl = (userDetails, contact) =>
  `/o/${userDetails?.defaultOrganization?.friendlyUrl}/contacts/${contact?._id}`;

export const buildFilePreviewUrl = (fileId, isAbsolute = true) =>
  `${isAbsolute ? 'https://partners.getzorba.com' : ''}/file-preview/${fileId}`;

export const buildDiscoverPageUrl = (page) =>
  `/${page > 1 ? `?p=${page}` : ''}`;

export const buildCampaignsPageUrl = () => `/campaign`;
export const buildCampaignPageUrl = (campaign) => `/campaign/${campaign?._id}`;

export const buildSettingsPageUrl = (userDetails) => {
  if (!userDetails?.defaultOrganization) return buildHomePageUrl();

  return `/o/${userDetails?.defaultOrganization?.friendlyUrl}/settings`;
};
