import { useState } from 'react';
import { Alert, Form } from 'antd';
import { getPriceText } from '@zorba-shared/core/Services/textService';
import { isMobile } from '@zorba-shared/client/Services/clientService';
import { useDispatch, useSelector } from 'react-redux';

import InstructionsStep from './Components/InstructionsStep';
import { ZorbaButton } from '../../components/forms';
import { BUTTON_VARIANT_SECONDARY } from '../../components/forms/ZorbaButton/constants';
import { reportEvent } from '../../services/analyticsService';
import LoadingStep from './Components/LoadingStep';
import './index.scss';
import MyProperties from './Components/MyProperties';
import OurBuybox from './Components/OurBuybox';
import SubmitPropertyModal from './Components/SubmitPropertyModal';
import {
  refreshProperties,
  setAppliedFilters,
} from '../../reducers/Property/actions';
import { PREDEFINED_VIEWS } from './Components/MyProperties/Components/constants';
import UploadContractModal from './Components/UploadContractModal';
import PropertiesStats from './Components/PropertiesStats';

const WIZARD_STEPS = ['address', 'form', 'offer', 'reject', 'success'];

const DealCheckerPage = () => {
  const [address, setAddress] = useState();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(WIZARD_STEPS[0]);
  const [dealSubmission, setDealSubmission] = useState();
  const [
    previouslySubmittedDealSubmissions,
    setPreviouslySubmittedDealSubmissions,
  ] = useState([]);
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const {
    currentPage,
    pageSize,
    appliedFilters,
    previousDealSubmissionsMap,
  } = useSelector(({ PropertyReducer }) => PropertyReducer);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [avoidRefetch, setAvoidRefetch] = useState(false);
  const [predefinedView, setPredefinedView] = useState(
    PREDEFINED_VIEWS.PENDING_ACTION,
  );
  const onContinue = (params, previousDeals) => {
    if (params?.step)
      reportEvent('dealchecker', `step_${params?.step}`, {
        address: address?.formattedAddress,
        user: userDetails?._id,
      });

    const nextStep = params?.step;
    setPreviouslySubmittedDealSubmissions(previousDeals);
    setDealSubmission(params);
    switch (nextStep) {
      case 'draft':
      case 'missing_info':
      case 'review':
      case 'revised_offer':
      case 'pending_agreement':
      case 'pending_inspection':
      case 'closing':
      case 'closed_won':
      case 'rejected_by_seller':
      case 'rejected_by_buyer':
        setAvoidRefetch(true);
        if (params?.address?.formattedAddress) {
          form.setFieldValue('query', params?.address?.formattedAddress);
          dispatch(
            setAppliedFilters('query', {
              regex: params?.address?.formattedAddress,
            }),
          );
          setPredefinedView(PREDEFINED_VIEWS.ALL_DEALS);
        }
        break;
      default:
        break;
    }
    window.scrollTo(0, 0);
  };
  const onReset = () => {
    dispatch(
      refreshProperties({ page: currentPage, pageSize, appliedFilters }),
    );
    setAddress();
    setLoading(false);
    setDealSubmission();
    setPreviouslySubmittedDealSubmissions([]);
    setStep('draft');
  };
  const updateDealSubmission = (newDeal) => {
    setDealSubmission(newDeal);
    setStep(newDeal?.step);
  };
  return (
    <>
      <div className="buybox-checker-page">
        {loading && (
          <LoadingStep
            features={[
              { title: 'Pulling county records', rating: 2 },
              { title: 'Neighborhood', rating: 3 },
              { title: 'Property condition', rating: 4 },
              { title: 'Property comps', rating: 3 },
            ]}
            subtitles={[
              'Collecting data from several databases',
              'Analyzing property details',
              'Analyzing property details',
              'Pulling sales comparables',
            ]}
            className="loading-step"
            spinner="/img/layout/forms/spinner.svg"
            onContinue={() => {}}
          />
        )}
        {!loading && (
          <>
            <InstructionsStep
              address={address}
              onContinue={onContinue}
              setLoading={setLoading}
              loading={loading}
              step={step}
              onReset={onReset}
              setAddress={setAddress}
              setDealSubmission={setDealSubmission}
              dealSubmission={dealSubmission}
            />
            <PropertiesStats dealSubmission={dealSubmission} />
            <MyProperties
              updateDealSubmission={updateDealSubmission}
              setPreviouslySubmittedDealSubmissions={
                setPreviouslySubmittedDealSubmissions
              }
              form={form}
              avoidRefetch={avoidRefetch}
              predefinedView={predefinedView}
              setPredefinedView={setPredefinedView}
              previousDealSubmissionsMap={previousDealSubmissionsMap}
            />
            {!isMobile() && <OurBuybox userDetails={userDetails} />}

            {step === 'missing_info' ? (
              <div className="offer-step">
                <div className="offer-info">
                  <div className="offer-amount">
                    <div className="amount-title">
                      Your estimated cash offer
                    </div>
                    <div className="amount-val">
                      {getPriceText(parseInt(dealSubmission?.offer, 10))}
                    </div>
                  </div>
                </div>

                <div className="instructions">
                  <Alert
                    message=""
                    description={
                      <>
                        <p>
                          Many offers increase after we see the property and
                          analyze the numbers, as we include a healthy rehab
                          budget in our initial offers.
                        </p>
                        <b>Is this the amount I will be netting?</b>

                        <p>
                          {`Yes. Since we cover the seller's closing costs this
                          would be the amount you will be netting, no hidden
                          fees.`}
                        </p>

                        <b>What to expect next</b>

                        <p>
                          Our team will be in touch via email within 24 hours
                          (usually within just a couple of hours!) with the next
                          steps to finalize the offer and proceed with a
                          contract.
                        </p>
                      </>
                    }
                    type="info"
                    showIcon
                  />
                  <br />
                  <ZorbaButton
                    onClick={() => onReset()}
                    variant={BUTTON_VARIANT_SECONDARY}
                  >
                    Submit another property
                  </ZorbaButton>
                </div>
              </div>
            ) : null}

            {/* <FAQ /> */}
          </>
        )}
      </div>
      <UploadContractModal dealSubmission={dealSubmission} />
      <SubmitPropertyModal
        previouslySubmittedDealSubmissions={previouslySubmittedDealSubmissions}
        dealSubmission={dealSubmission}
        onReset={onReset}
        onContinue={onContinue}
        step={step}
        updateDealSubmission={updateDealSubmission}
        address={address}
        form={form}
      />
    </>
  );
};

export default DealCheckerPage;
