import _ from 'lodash';
import { Dropdown, Tag, Tooltip } from 'antd';
import { BsThreeDots } from '@react-icons/all-files/bs/BsThreeDots';
import {
  getFriendlyTime,
  getPriceText,
} from '@zorba-shared/core/Services/textService';

const getStatusTag = (step) => {
  switch (step) {
    case 'confirm_details':
      return (
        <Tooltip title="We are waiting for you to fill the information about the property in order for us to underwrite and send you an official offer">
          <Tag style={{ color: '#12151e', backgroundColor: '#ecf0f3' }}>
            <div>Draft</div>
          </Tag>
        </Tooltip>
      );
    case 'missing_info':
      return (
        <Tooltip title="We are waiting for you to fill the asking price in order for us to underwrite and send you an official offer">
          <Tag style={{ color: '#976c39', backgroundColor: '#fbebbe' }}>
            <div>Missing info</div>
          </Tag>
        </Tooltip>
      );
    case 'review':
      return (
        <Tooltip title="Our underwriters are reviewing your deal using all the information you provided. You will receive an email and a text as soon as the offer is ready.">
          <Tag style={{ color: '#1840a2', backgroundColor: '#cbe3fc' }}>
            <div>Under review</div>
          </Tag>
        </Tooltip>
      );
    case 'rejected_by_uw':
      return <Tag style={{ color: '#CB6060' }}>Rejected by Zorba</Tag>;
    case 'rejected_by_buyer':
      return <Tag style={{ color: '#CB6060' }}>Rejected by Zorba</Tag>;
    case 'rejected_by_seller':
      return <Tag style={{ color: '#CB6060' }}>Rejected by you</Tag>;
    case 'revised_offer':
      return (
        <Tag style={{ color: '#265c4f', backgroundColor: '#bfead8' }}>
          <div>Pre-offer received</div>
        </Tag>
      );
    case 'converted':
      return (
        <Tag style={{ color: '#265c4f', backgroundColor: '#bfead8' }}>
          <div>Offer Accepted</div>
        </Tag>
      );
    default:
      return null;
  }
};

export const getTableColumns = (updatePreDeal) => [
  {
    title: 'Property Address',
    key: 'address',
    render: ({ address }) => address.formattedAddress,
  },
  {
    title: 'Submitted Date',
    key: 'submittedDate',
    render: ({ createdAt }) => getFriendlyTime(createdAt),
  },
  {
    title: 'Asking Price',
    key: 'askingPrice',
    render: ({ userData }) => getPriceText(userData.askingPrice),
  },
  {
    title: 'Pre-Offer',
    key: 'preOffer',
    render: ({ events }) => {
      const lastOfferEvent = _.last(
        events.filter((event) => event.kind === 'offer'),
      );
      const finalOfferEvent = _.last(
        events.filter((event) => event.kind === 'buyer_final_offer'),
      );
      if (finalOfferEvent) {
        return getPriceText(finalOfferEvent.amount);
      }
      return lastOfferEvent?.amount ? getPriceText(lastOfferEvent?.amount) : '';
    },
  },
  {
    title: 'Stage',
    key: 'stage',
    render: ({ step }) => getStatusTag(step),
  },
  {
    key: 'actions',
    title: '',
    render: (preDeal) => {
      const { step } = preDeal;
      let items = [];
      switch (step) {
        case 'confirm_details':
          items = [
            {
              key: 0,
              label: (
                <div
                  onClick={() => {
                    updatePreDeal(preDeal);
                  }}
                >
                  Confirm details about the property
                </div>
              ),
            },
          ];
          break;
        case 'missing_info':
          items = [
            {
              key: 0,
              label: (
                <div onClick={() => updatePreDeal(preDeal)}>
                  Fill missing info
                </div>
              ),
            },
          ];
          break;
        case 'rejected_by_uw':
          break;
        case 'revised_offer':
          break;
        default:
          return null;
      }
      if (items.length === 0) return null;

      return (
        <Dropdown menu={{ items }} placement="bottom">
          <BsThreeDots style={{ cursor: 'pointer', fontSize: 20 }} />
        </Dropdown>
      );
    },
  },
];

export const PREDEFINED_VIEWS = {
  PENDING_ACTION: 'pending_action',
  MISSING_INFO: 'missing_info',
  ALL_DEALS: 'all_deals',
};
