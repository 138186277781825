import * as types from './types';

const initialState = {
  preDeals: [],
  currentPage: 0,
  pageSize: 50,
  totalPreDeals: 0,
  appliedFilters: {},
  loading: false,
  hasMoreResults: false,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_PRE_DEALS:
    case types.SET_CURRENT_PAGE:
    case types.SET_PAGE_SIZE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
