import * as Sentry from '@sentry/react';
// import "antd/dist/antd.css";
import { ConnectedRouter } from 'connected-react-router';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import { ErrorBoundary } from "react-error-boundary";
// Redux
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import createStore, { history } from './configureStore';
import Layout from './containers/layout/Layout';
// import ErrorFallback from "./ErrorFallback";
import Routes from './routes';
import { initLoggers } from './services/loggingService';
import { TrainingModal } from './containers/onboarding/TrainingModal';

initLoggers();
const createdStore = createStore(history);
const App = (props) => (
  <GoogleOAuthProvider clientId="863393740130-10sntl8lf8pskq2l9bjbuo0m7vbhc2o5.apps.googleusercontent.com">
    <Provider store={createdStore}>
      <ConnectedRouter history={history}>
        {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
        <Layout {...props}>
          <ToastContainer />
          <TrainingModal />

          <Routes {...props} />
        </Layout>
        {/* </ErrorBoundary> */}
      </ConnectedRouter>
    </Provider>
  </GoogleOAuthProvider>
);

export default Sentry.withProfiler(App);
