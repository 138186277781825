import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FullPageSpinner } from './components/layout';

import EmailConfirmationPage from './pages/EmailConfirmationPage';

import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';
import DealcheckerPage from './pages/DealcheckerPage';
import OnboardingPage from './pages/OnboardingPage';
import HowItWorksPage from './pages/HowItWorksPage';
import HowPreLockDealWorksPage from './pages/HowPreLockDealWorksPage';
import { isMobile } from './services/clientService';

const LoginPage = lazy(() =>
  import(/* webpackChunkName: "LoginPage" */ './pages/LoginPage'),
);
const FAQPage = lazy(() =>
  import(/* webpackChunkName: "FAQPage" */ './pages/FAQPage'),
);
const ReferralsPage = lazy(() =>
  import(/* webpackChunkName: "ReferralsPage" */ './pages/ReferralsPage'),
);
const BuyBoxPage = lazy(() =>
  import(/* webpackChunkName: "BuyBoxPage" */ './pages/BuyBoxPage'),
);

export default () => (
  <Suspense fallback={<FullPageSpinner />}>
    <Switch>
      {/* AUTH */}
      <Route path="/auth/:authState" component={LoginPage} />
      <Route path="/auth" component={LoginPage} />
      <Route path="/login" exact component={LoginPage} />

      <PrivateRoute
        exact
        path="/o/onboarding/:stepKey"
        component={OnboardingPage}
        sidebar={!isMobile()}
      />
      <Redirect from="/o/onboarding" to="/o/onboarding/phone" />
      <PublicRoute
        path="/confirm-email"
        exact
        component={EmailConfirmationPage}
      />
      <PrivateRoute path="/buybox" exact component={BuyBoxPage} sidebar />
      <PrivateRoute path="/referrals" exact component={ReferralsPage} sidebar />
      <PrivateRoute path="/faq" exact component={FAQPage} sidebar />
      <PrivateRoute path="/how-it-works" component={HowItWorksPage} sidebar />
      <PrivateRoute
        path="/pre-lock-how-it-works"
        component={HowPreLockDealWorksPage}
        sidebar
      />
      <PrivateRoute path="/" component={DealcheckerPage} sidebar />
    </Switch>
  </Suspense>
);
