import { useEffect } from 'react';

const ScrollToTop = ({ history }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (window && window.scrollTo) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
};

export default ScrollToTop;
