import { getQueryParam } from '@zorba-shared/client/Services/clientService';
import { postRequest } from '@zorba-shared/client/Services/requestService';

/**
 * get buyers whose buybox(address) matches with property's address
 * @param {*} address
 */
export const getMatchedBuyers = async (params) => {
  const { address, step } = params;

  if (!address || !step) return {};
  let res;

  try {
    res = await postRequest(`/dealchecker/search`, {
      ...params,
      admin: getQueryParam('admin') || null,
      email: getQueryParam('email'),
    });
    res = res.data;
    if (res?.data?.reason) {
      console.log('error');
    }
  } catch (error) {
    console.log(error);
  }

  return {
    updatedMatch: res?.dealcheck || {},
    previouslySubmittedDeals: res?.previouslySubmittedDeals || [],
  };
};

export const updateDealSubmissionObj = async (
  currentDealSubmission,
  params,
) => {
  let updatedDealSubmission;
  try {
    const res = await postRequest(
      `/dealchecker/${currentDealSubmission._id}`,
      params,
    );
    updatedDealSubmission = res?.data?.dealSubmission;
  } catch (e) {}

  return updatedDealSubmission;
};
