/* eslint-disable no-param-reassign */
import { Form, Input, InputNumber } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import './index.scss';

const ZorbaInput = (props) => {
  const {
    disabled,
    label = '',
    loading,
    help,
    suffix,
    success,
    variant,
    type,
    maxLength,
    fullWidth = false,
    symbol = '$',
    className = '',
    parserRegex,
    inputId,
    onChange,
    field,
    minValue,
    disableFormatter,
    maxValue,
    hint,
    hintPosition = 'top',
  } = props;
  const validateStatus = props.validateStatus || (help ? 'error' : 'success');
  const itemProps = _.pick(props, ['help', 'prefix', 'tooltip']);
  const inputProps = _.pick(props, [
    'placeholder',
    'value',
    'onChange',
    'prefix',
    'onBlur',
    'autoComplete',
    'disabled',
    'addonAfter',
    'addonBefore',
    'maxLength',
  ]);

  const getSuffix = () => {
    if (suffix) return suffix;
    if (success) return <i className="fas fa-check-circle" />;
    return loading ? <Spinner /> : null;
  };
  const handleOnChange = (newValue) => {
    if (!onChange) return;

    if (type === 'number' && !newValue) {
      onChange(null);
    } else {
      onChange(type === 'number' ? +newValue : newValue);
    }
  };
  let inputComponent;
  switch (type) {
    case 'number':
      inputComponent = (
        <InputNumber
          {...inputProps}
          className={`zorba-input ${variant || ''} ${className || ''}`}
          suffix={getSuffix()}
          formatter={(value) =>
            !disableFormatter
              ? `${symbol && value ? `${symbol} ` : ''}${value}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ',',
                )
              : value
          }
          parser={(value) => value.replace(parserRegex || /\$\s?|(,*)/g, '')}
          onChange={field ? onChange : handleOnChange}
          onBlur={(newVal) => {
            const numVal = newVal.target.value.replace(
              parserRegex || /\$\s?|(,*)/g,
              '',
            );
            if (inputProps.onBlur) inputProps.onBlur(numVal);
            else onChange(numVal ? +numVal : null);
          }}
          {...(field || {})}
          autoComplete="off"
          min={minValue}
          max={maxValue}
        />
      );
      break;
    default:
      inputComponent = (
        <Input
          id={inputId || ''}
          autoComplete="off"
          disabled={!!disabled}
          maxLength={maxLength}
          onFocus={(event) => {
            if (event.target.autocomplete) {
              event.target.autocomplete = 'whatever';
            }
          }}
          {...inputProps}
          type={type || 'text'}
          className={classNames('zorba-input', {
            [variant]: !!variant,
            'full-width': fullWidth,
            [className]: !!className,
          })}
          suffix={getSuffix()}
          {...(field || {})}
        />
      );
      break;
  }

  return label ? (
    <Form.Item
      {...itemProps}
      label={label}
      className={classNames('zorba-input-item', { 'full-width': fullWidth })}
      validateStatus={validateStatus}
    >
      {hint && hintPosition === 'top' && <span className="hint">{hint}</span>}
      {inputComponent}
      {hint && hintPosition === 'bottom' && (
        <span className="hint hint-bottom">{hint}</span>
      )}
    </Form.Item>
  ) : (
    inputComponent
  );
};

ZorbaInput.defaultProps = {
  disabled: false,
  label: '',
  loading: false,
  help: null,
  suffix: null,
  success: false,
  variant: null,
  type: 'text',
  maxLength: null,
  fullWidth: false,
  symbol: '$',
  className: '',
  parserRegex: null,
  validateStatus: null,
  placeholder: null,
  value: null,
  onChange: null,
  onBlur: null,
  autoComplete: null,
  disableFormatter: false,
  addonAfter: null,
  addonBefore: null,
};

ZorbaInput.propTypes = {
  type: PropTypes.oneOf(['text', 'number', 'password', 'tel']),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  autoComplete: PropTypes.string,
  symbol: PropTypes.string,
  help: PropTypes.string,
  variant: PropTypes.string,
  maxLength: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validateStatus: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  success: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  suffix: PropTypes.node,
  parserRegex: PropTypes.instanceOf(RegExp),
  disableFormatter: PropTypes.bool,
  addonAfter: PropTypes.node,
  addonBefore: PropTypes.node,
};

export default ZorbaInput;
