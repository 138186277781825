import { useEffect, useState } from 'react';
import { Form, Input, Divider } from 'antd';
import { useForm, Controller } from 'react-hook-form';

import { ZorbaInput, ZorbaButton } from '../../../../../../components/forms';
import { BUTTON_VARIANT_LINK } from '../../../../../../components/forms/ZorbaButton/constants';
import { isValidURL } from '../../constants';
import { getMatchedBuyers } from '../../../../service';

import './index.scss';

/**
 * Collect the Asking Price & Photos in order to submit to buyers
 */
export default (props) => {
  const { preDeal } = props;
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    reset(preDeal.userData);
  }, [preDeal]);

  const onSubmit = async (formValues) => {
    setLoading(true);
    const { updatedMatch } = await getMatchedBuyers({
      ...preDeal,
      userData: {
        ...formValues,
      },
      slackThreadId: preDeal.slackThreadId,
      step: 'missing_info',
    });

    props.onContinue(updatedMatch);
    setLoading(false);
  };

  return (
    <form className="submit-to-uw-step" onSubmit={handleSubmit(onSubmit)}>
      <div className="underwriting-container">
        <h3 className="pre-deal-title">Get a Pre-lock Offer™</h3>
        <span className="underwriting-title">
          {preDeal?.address?.formattedAddress}
        </span>
      </div>
      <Divider />
      <div className="items">
        <div className="form-row">
          <div className="">
            <Controller
              name="linkToPhotos"
              control={control}
              rules={{
                validate: (newVal) =>
                  !newVal || isValidURL(newVal)
                    ? true
                    : 'Please provide a valid URL',
              }}
              render={({ field }) => (
                <ZorbaInput
                  label="Link to photos/videos (Optional)"
                  field={field}
                  placeholder="Add link to photos"
                  help={errors.linkToPhotos?.message}
                  hint="Please use online storage services like Dropbox, Google Drive, OneDrive, iCloud, etc..."
                  hintPosition="bottom"
                />
              )}
            />
          </div>
        </div>
        <div className="form-item full">
          <Controller
            name="description"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Form.Item label="About this deal (Optional)">
                <Input.TextArea
                  placeholder={`Comps:
123 main St. Tampa, FL
123 main St. Tampa, FL

Condition:
Roof - 2 years
HVAC - 1 year
`}
                  className="text-area"
                  value={field.value}
                  onChange={field.onChange}
                />
              </Form.Item>
            )}
          />
        </div>
      </div>
      <br />
      <div className="buttons">
        <ZorbaButton
          fullWidth={false}
          htmlType="submit"
          variant={BUTTON_VARIANT_LINK}
          className="generateOfferButton"
          disabled={loading}
          onClick={() => props.onReset()}
        >
          Cancel
        </ZorbaButton>
        <div>
          <ZorbaButton
            fullWidth={false}
            htmlType="submit"
            variant={BUTTON_VARIANT_LINK}
            className="generateOfferButton"
            disabled={loading}
            onClick={() => props.onReset()}
          >
            Save draft
          </ZorbaButton>
          <ZorbaButton
            loading={loading}
            fullWidth={false}
            htmlType="submit"
            className="generateOfferButton"
            disabled={loading}
          >
            Get Pre-Lock offer
          </ZorbaButton>
        </div>
      </div>
    </form>
  );
};
