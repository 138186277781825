import { Table } from 'antd';

import './index.scss';
import { getTableColumns } from './constants';
import { ZorbaButton } from '../../../../../../components/forms';
import { BUTTON_VARIANT_LINK } from '../../../../../../components/forms/ZorbaButton/constants';

const PreConfirmDetails = (props) => {
  const { dealSubmission, previouslySubmittedDealSubmissions } = props;
  const handleOnContinue = () => {
    props.onContinue(dealSubmission, []);
  };

  const lowestAskingPrice = Math.min(
    ...previouslySubmittedDealSubmissions
      .map((item) => item.askingPrice)
      .filter(Boolean),
  );

  return (
    <div className="pre-confirm-details">
      <div className="underwriting-container">
        <span className="underwriting-title">
          {dealSubmission.address.formattedAddress}
        </span>
        <span className="underwriting-question">
          Previously submitted deals
        </span>
      </div>
      <Table
        rowKey="_id"
        dataSource={previouslySubmittedDealSubmissions}
        columns={getTableColumns()}
        pagination={false}
        rowClassName={(record) =>
          record.askingPrice === lowestAskingPrice ? 'highlight-row' : ''
        }
      />
      <div className="buttons">
        <ZorbaButton
          fullWidth={false}
          htmlType="submit"
          variant={BUTTON_VARIANT_LINK}
          onClick={() => props.onReset()}
        >
          Cancel
        </ZorbaButton>
        <ZorbaButton fullWidth={false} onClick={handleOnContinue}>
          Continue
        </ZorbaButton>
      </div>
    </div>
  );
};

export default PreConfirmDetails;
