import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import * as dayjs from 'dayjs';
import { Form, DatePicker as LibraryDatePicker } from 'antd';
import { isMobile } from '@zorba-shared/client/Services/clientService';
import './index.scss';

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);
const DatePicker = ({ showTime, label, formClassName, tooltip, ...props }) => {
  const handleChange = (value) => {
    if (value) {
      const localTime = dayjs(value);
      const formattedLocalTime = localTime.format('YYYY-MM-DDTHH:mm:ss');
      const valueInEST = dayjs.tz(
        formattedLocalTime,
        'YYYY-MM-DDTHH:mm:ss',
        'America/New_York',
      );
      const onChangeHandler = props.field
        ? props.field.onChange
        : props.onChange;
      onChangeHandler(valueInEST);
    } else {
      const onChangeHandler = props.field
        ? props.field.onChange
        : props.onChange;
      onChangeHandler(null);
    }
  };

  return label != null ? (
    <Form.Item label={label} className={formClassName} tooltip={tooltip}>
      <LibraryDatePicker
        {...props}
        {...(props.field || {})}
        showTime={showTime ? { format: 'h:mm a' } : false}
        format="YYYY-MM-DD h:mm a"
        inputReadOnly
        position={isMobile() ? 'topLeft' : 'bottomLeft'}
        suffixIcon="EST"
        onChange={handleChange}
      />
    </Form.Item>
  ) : (
    <LibraryDatePicker
      {...props}
      {...(props.field || {})}
      showTime={showTime ? { format: 'h:mm a' } : false}
      format="YYYY-MM-DD h:mm a"
      inputReadOnly
      position={isMobile() ? 'topLeft' : 'bottomLeft'}
      suffixIcon="EST"
      onChange={handleChange}
    />
  );
};

export default DatePicker;
