import { FaPlusCircle } from '@react-icons/all-files/fa/FaPlusCircle';
import { FaMinusCircle } from '@react-icons/all-files/fa/FaMinusCircle';

import './index.scss';

export const PlusMinusInput = ({
  value = 0,
  label,
  help,
  maxValue,
  onChange,
}) => {
  const shouldMinBeDisabled = value === 0;
  const shouldMaxBeDisabled = value === (maxValue || 10);
  const onMinusChangeHandle = () => {
    if (shouldMinBeDisabled) return;

    const newValue = value - 1;
    onChange(newValue);
  };

  const onPlusChangeHandle = () => {
    if (shouldMaxBeDisabled) return;
    const newValue = value + 1;

    onChange(newValue);
  };

  return (
    <>
      <div className="plus-minus-input-container">
        {label && <span className="label">{label}</span>}
        <div className="icons-container">
          <FaMinusCircle
            color={shouldMinBeDisabled ? '#b7c0c8' : '#7388D9FF'}
            size={20}
            className="icon"
            onClick={onMinusChangeHandle}
          />
          {value == null ? <span>-</span> : <span>{value}</span>}
          <FaPlusCircle
            color={shouldMaxBeDisabled ? '#b7c0c8' : '#7388D9FF'}
            size={20}
            className="icon"
            onClick={onPlusChangeHandle}
          />
        </div>
      </div>
      {help && <span className="help-message">{help}</span>}
    </>
  );
};
