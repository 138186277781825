import { useState } from 'react';
import { Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { setDrawerState } from '../../../../reducers/Drawer/actions';
import { DEAL_FILTERS_DRAWER } from '../../../../reducers/Drawer/types';
import { DEAL_FILTER_CONFIG } from './constants';
import { InitialFiltersState } from './Components/InitialFiltersState';
import { FiltersBuilder } from './Components/FiltersBuilder';
import { FilterList } from './Components/FilterList';
import { deleteAppliedFilter } from '../../../../reducers/Property/actions';

import './index.scss';

const FiltersDrawer = ({ setPredefinedView }) => {
  const dispatch = useDispatch();
  const [showFilterList, setShowFilterList] = useState(false);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState('');
  const { appliedFilters } = useSelector(
    ({ PropertyReducer }) => PropertyReducer,
  );
  const { query, ...filtersToApply } = appliedFilters;
  const handleDrawerClose = () => {
    dispatch(setDrawerState(DEAL_FILTERS_DRAWER, false));
    setSelectedCustomFilter('');
    setShowFilterList(false);
  };
  const { isDealFiltersDrawerOpen } = useSelector(
    ({ DrawerReducer }) => DrawerReducer,
  );

  const handleFilterListBack = () => {
    setShowFilterList(false);
  };

  const selectFilter = (value) => {
    setSelectedCustomFilter(value);
    setShowFilterList(false);
  };

  const handleOpenFilterList = () => {
    setShowFilterList(true);
  };

  const filterConfig = DEAL_FILTER_CONFIG.filter(
    (filter) =>
      !Object.keys(appliedFilters).some(
        (appliedFilter) => appliedFilter === filter.value,
      ),
  );
  const handleFilterBuilderBack = () => {
    if (filterConfig.length) {
      setShowFilterList(true);
      setSelectedCustomFilter('');
    } else {
      setSelectedCustomFilter('');
    }
  };

  const onApplyFilter = () => {
    setShowFilterList(false);
    setSelectedCustomFilter('');
  };

  const handleOpenFilterDetail = (filterKey) => {
    setSelectedCustomFilter(filterKey);
    setShowFilterList(false);
  };

  const handleOnDelete = (filterKey) => {
    dispatch(deleteAppliedFilter(filterKey, setPredefinedView));
  };

  return (
    <Drawer
      className="deal-filters-drawer"
      width={420}
      title="All filters"
      placement="right"
      onClose={handleDrawerClose}
      open={isDealFiltersDrawerOpen}
    >
      {!selectedCustomFilter && !showFilterList && (
        <InitialFiltersState
          handleOpenFilterList={handleOpenFilterList}
          appliedFilters={filtersToApply}
          filterConfig={filterConfig}
          handleOpenFilterDetail={handleOpenFilterDetail}
          handleOnDelete={handleOnDelete}
        />
      )}
      {showFilterList && (
        <FilterList
          filterConfig={filterConfig}
          handleBack={handleFilterListBack}
          selectFilter={selectFilter}
        />
      )}
      {selectedCustomFilter && !showFilterList && (
        <FiltersBuilder
          handleBack={handleFilterBuilderBack}
          selectedFilter={selectedCustomFilter}
          onApplyFilter={onApplyFilter}
        />
      )}
    </Drawer>
  );
};

export default FiltersDrawer;
