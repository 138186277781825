import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { MdEmail } from '@react-icons/all-files/md/MdEmail';
import './index.scss';
import {
  isAuthenticationPage,
  isOnboardingPage,
} from '../../../services/authService';
import { setModalState } from '../../../reducers/Modal/actions';
import { MODAL_PARAM_EMAIL_VERIFICATION } from '../../../reducers/Modal/types';
import DisableApp from './Components/DisableApp';
import PhoneNumberUpdateModal from './Components/PhoneUpdateModal';

const Page = (props) => {
  const { className, wide = false, align, title } = props;
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  const isGuest = !userDetails;
  const shouldShowEmailStrip =
    userDetails &&
    !userDetails.isEmailVerified &&
    !isAuthenticationPage() &&
    !isOnboardingPage();
  return (
    <div
      className={classNames(
        'page',
        { [className]: className !== '' },
        { wide, [`align-${align}`]: !!align },
        { guest: isGuest },
      )}
    >
      {title && <h4 className="page-title">{title}</h4>}
      <div className="page-inner">
        <div className="page-inner-content">
          {shouldShowEmailStrip && (
            <div className="confirm-email">
              <MdEmail />
              Activate your email address so we can send you our official offer
              letters. your buy box.
              <a
                onClick={() =>
                  dispatch(setModalState(MODAL_PARAM_EMAIL_VERIFICATION, true))
                }
              >
                Verify email
              </a>
            </div>
          )}
          {props.children}
        </div>
      </div>
      <DisableApp
        isVerified={userDetails?.defaultOrganization?.verification?.verified}
        isBanned={userDetails?.isBanned}
        isCompletedOnboarding={userDetails?.isCompletedOnboarding}
      />
    </div>
  );
};

export default Page;
