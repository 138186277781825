import { Form } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { validateContactProperty } from '../../../services/crm/contactService';
import { isEmailValid } from '../../../services/textService';
import { ZorbaInput } from '..';
import './index.scss';

export default (props) => {
  const {
    defaultValue,
    showErrorMessage,
    errorMessage = '',
    setErrorMessage,
  } = props;
  const [value, setValue] = useState('');
  const [testedValue, setTestedValue] = useState('');
  const [touched, setTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const inputParams = _.pick(props, ['fullWidth', 'variant']);

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, []);

  useEffect(() => {
    setValue(defaultValue);
    setTestedValue(defaultValue);
  }, [defaultValue]);

  const isValidNumber = (input) => input?.toString().length === 10;
  const onChange = (newValue) => {
    const formattedValue = newValue.toString().replace(/\D/g, '');
    setValue(formattedValue);
    setTouched(false);
    props.onChange &&
      props.onChange(
        isValidNumber(formattedValue)
          ? {
              countryCode: '1',
              number: formattedValue,
            }
          : { countryCode: 1, number: '' },
      );
  };

  return (
    <div className={classNames('phone-input', { invalid: !!showErrorMessage })}>
      <div className="input-wrapper">
        <div className="country-wrapper">
          <div className="field-title">Country/Region</div>
          <div className="field-value">United States (+1)</div>
        </div>
        <ZorbaInput
          {...inputParams}
          prefix="+1"
          maxLength={10}
          // formatter={(val) => val}
          type="tel"
          placeholder="Phone number"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          // onBlur={validateInput}
        />
      </div>
      {showErrorMessage && <div className="error">{errorMessage}</div>}
    </div>
  );
};
