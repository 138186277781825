import { Form } from 'antd';
import {
  DEALS_QUESTION_OPTIONS,
  MAIN_INTEREST_OPTIONS,
  MAIN_INTEREST_QUESTIONS,
} from '@zorba-shared/core/Constants/organization';
import { ZorbaInput, ZorbaSelect } from '../../../../../components/forms';
import { capitalizeEveryWord } from '../../../../../services/textService';
import './index.scss';
import PhoneInput from '../../../../../components/forms/PhoneInput';

const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

export default (props) => {
  const {
    params: { organizationName, numOfDealsQuestion, mainInterestQuestion },
    validationObj,
  } = props;
  const onChange = (e) => {
    const newVal = capitalize(e.target.value);
    props.onChange('organizationName', newVal);

    // props.createOrganization({ name: newVal, numOfDealsQuestion });
  };
  return (
    <div className="onboarding-step create">
      <Form.Item
        className="wide"
        help={validationObj.organizationName || ' '}
        validateStatus={
          validationObj?.organizationName?.length > 0 ? 'error' : 'success'
        }
        label="What’s the name of your company or team?"
      >
        <ZorbaInput
          maxLength={26}
          value={organizationName}
          onChange={onChange}
        />
      </Form.Item>

      <Form.Item
        className="wide"
        label="Which of the following best describes you?"
      >
        <ZorbaSelect
          options={MAIN_INTEREST_OPTIONS}
          value={mainInterestQuestion}
          onChange={(newVal) => props.onChange('mainInterestQuestion', newVal)}
        />
      </Form.Item>

      <Form.Item
        className="wide"
        label="How many deals did you complete in the past 12 months?"
      >
        <ZorbaSelect
          options={DEALS_QUESTION_OPTIONS}
          value={numOfDealsQuestion}
          onChange={(newVal) => props.onChange('numOfDealsQuestion', newVal)}
        />
      </Form.Item>
    </div>
  );
};
