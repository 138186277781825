import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MdNavigateNext } from '@react-icons/all-files/md/MdNavigateNext';

import { getFriendlyTime } from '../../../../../../services/textService';
import { ZorbaButton } from '../../../../../../components/forms';
import { setModalState } from '../../../../../../reducers/Modal/actions';
import { MODAL_PARAM_MOBILE_DEAL_DETAILS } from '../../../../../../reducers/Modal/types';

import MobileDealModal from '../MobileDealModal';
import { getStatusTag } from '../constants';
import {
  fetchDealDetails,
  setSelectedDeal,
  setCurrentPage,
} from '../../../../../../reducers/Property/actions';

import './index.scss';

export const MobilePropertyList = ({
  deals,
  loading,
  onRequestContract,
  updateDealSubmission,
  handleOpenModal,
  currentPage,
  hasMoreResults,
  predefinedView,
  isContractLoading,
  previousDealSubmissionsMap,
  setPreviouslySubmittedDealSubmissions,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dealSubmissionId = queryParams.get('deal_submission_id');

  const handleButtonClick = (deal) => {
    if (previousDealSubmissionsMap[deal.address.formattedAddress]) {
      setPreviouslySubmittedDealSubmissions(
        previousDealSubmissionsMap[deal.address.formattedAddress],
      );
    }
    dispatch(setSelectedDeal(deal));
    dispatch(setModalState(MODAL_PARAM_MOBILE_DEAL_DETAILS, true));
  };

  useEffect(() => {
    if (dealSubmissionId) {
      dispatch(fetchDealDetails(dealSubmissionId));
    }
  }, []);

  const handleNext = () => {
    dispatch(setCurrentPage({ page: currentPage + 1 }));
  };

  if (loading && currentPage === 0) {
    return (
      <div className="mobile-property-list-container">
        <Spin />
      </div>
    );
  }

  const dealsToDisplay =
    predefinedView === 'pending_action'
      ? deals.sort((a, b) => {
          if (a.step === 'revised_offer' && b.step !== 'revised_offer') {
            return -1;
          }
          if (a.step !== 'revised_offer' && b.step === 'revised_offer') {
            return 1;
          }
          return 0;
        })
      : deals;

  return (
    <>
      <InfiniteScroll
        next={handleNext}
        hasMore={hasMoreResults}
        className="mobile-property-list-container"
        loader={
          <div className="mobile-property-list-container">
            <Spin />
          </div>
        }
        dataLength={deals.length}
      >
        {dealsToDisplay.map((deal) => (
          <Card
            key={deal._id}
            onClick={() => {
              if (
                deal.step === 'draft' ||
                deal.step === 'missing_info' ||
                deal.step === 'confirm_details'
              ) {
                updateDealSubmission(deal);
              } else {
                handleButtonClick(deal);
              }
            }}
          >
            <div className="left-border" />
            <div className="card-details">
              <span className="last-updated-title">
                {getFriendlyTime(deal.updatedAt)}
              </span>
              <span className="address-title">
                {deal.address.formattedAddress}
              </span>
              <div>{getStatusTag(deal.step)}</div>
            </div>
            <div className="button-container">
              <ZorbaButton
                className="navigate-next-button"
                icon={<MdNavigateNext size={25} color="#7388d9" />}
                rounded
                onClick={() => {
                  if (deal.step === 'draft' || deal.step === 'missing_info') {
                    updateDealSubmission(deal);
                  } else {
                    handleButtonClick(deal);
                  }
                }}
              />
            </div>
          </Card>
        ))}
      </InfiniteScroll>
      <MobileDealModal
        isContractLoading={isContractLoading}
        onRequestContract={onRequestContract}
        handleOpenModal={handleOpenModal}
      />
    </>
  );
};
