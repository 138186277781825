import ReactDOM from 'react-dom';
import App from './App';
function errorBoundaryWrapper() {

  return <App />;
}

const rootElement = document.getElementById('root');
ReactDOM.render(errorBoundaryWrapper(), rootElement);
if (module.hot) {
  module.hot.accept('./App', () => {
    ReactDOM.render(errorBoundaryWrapper(), document.getElementById('root'));
  });
}
