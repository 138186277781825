const getParamName = (flow) => `is${flow}DrawerOpen`;
export const SET_DRAWER_STATE = 'SET_DRAWER_STATE';

export const DRAWER_PARAM_ADD_DEAL = getParamName('AddDeal');

export const DRAWER_PARAM_IMPORT = getParamName('Import');

export const DRAWER_PARAM_DEAL_PREVIEW = getParamName('DealPreview');
export const DRAWER_PARAM_ADD_CONTACT = getParamName('AddContact');
export const DRAWER_PARAM_CONTACT_PREVIEW = getParamName('ContactPreview');
export const DRAWER_PARAM_MATCH_LIST_FILTERS = getParamName('MatchListFilters');

export const DEAL_FILTERS_DRAWER = getParamName('DealFilters');
