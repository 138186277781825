import { useEffect, useState } from 'react';
import { Alert, Divider } from 'antd';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  PROPERTY_TYPE_LAND,
  PROPERTY_TYPE_LOT,
} from '@zorba-shared/core/Constants/deal';
import {
  ZorbaInput,
  ZorbaButton,
  ZorbaSelect,
} from '../../../../../../components/forms';
import { BUTTON_VARIANT_LINK } from '../../../../../../components/forms/ZorbaButton/constants';
import { getMatchedBuyers } from '../../../../service';
import { translateKey } from '../../../../../../constants/translation';
import UnderwritingStatus from '../UnderwritingStatus';
import ZorbaDatePicker from '../../../../../../components/forms/ZorbaDatePicker';

import './index.scss';

export default (props) => {
  const { dealSubmission } = props;
  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      isFlip: false,
    },
  });

  const validateRequired = (value) =>
    ![null, undefined].includes(value) || 'This field is required';
  useEffect(() => {
    reset(dealSubmission.userData);
  }, [dealSubmission]);

  const onSubmit = async (formValues) => {
    setLoading(true);
    const { updatedMatch, previouslySubmittedDeals } = await getMatchedBuyers({
      ...dealSubmission,
      userData: {
        ...formValues,
        arv: Math.round(formValues.arv),
        rent: Math.round(formValues.rent),
        askingPrice: Math.round(formValues.askingPrice),
        rehabCost: Math.round(formValues.rehabCost),
        sellerCommission: formValues.sellerCommission
          ? formValues.sellerCommission / 100
          : null,
        inspectionAvailability1: formValues.inspectionAvailability1 || '',
        inspectionAvailability2: formValues.inspectionAvailability2 || '',
      },
      slackThreadId: dealSubmission.slackThreadId,
      step: 'pending_uw',
    });

    props.onContinue(updatedMatch, previouslySubmittedDeals);
    setLoading(false);
  };

  const watchHoaFees = watch('hoaFees');
  const watchIsUnderContract = watch('isUnderContract');
  const watchInspectionAvailability1 = watch('inspectionAvailability1');

  useEffect(() => {
    if (!watchHoaFees) {
      setValue('hoaPeriod', null);
      setValue('hoaFee', null);
    }
    if (watchHoaFees) {
      setValue('hoaPeriod', 'month');
    }
  }, [watchHoaFees]);

  return (
    <form className="submission-form" onSubmit={handleSubmit(onSubmit)}>
      <UnderwritingStatus dealSubmission={dealSubmission} />
      <p>{translateKey(userDetails, 'submission_form_desc')}</p>
      {/* INITIAL FORM (PRICES, RELATIONSHIP & OCCUPANCY) */}
      <div className="items">
        {/* ARV */}
        <div className="form-item">
          <Controller
            name="arv"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <ZorbaInput
                label="ARV"
                type="number"
                placeholder="1000"
                field={field}
                symbol={null}
                addonBefore="$"
                help={errors.arv ? 'Required' : ''}
              />
            )}
          />
        </div>
        {/* ESTIMATED RENT */}
        {dealSubmission?.lastCalculationParams?.propertyType !==
          PROPERTY_TYPE_LOT &&
          dealSubmission?.lastCalculationParams?.propertyType !==
            PROPERTY_TYPE_LAND && (
            <div className="form-item">
              <Controller
                name="rent"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <ZorbaInput
                    label="Estimated rent"
                    type="number"
                    placeholder="1200"
                    field={field}
                    addonBefore="$"
                    symbol={null}
                    help={errors.rent ? 'Required' : ''}
                  />
                )}
              />
            </div>
          )}
        {dealSubmission?.lastCalculationParams?.propertyType !==
          PROPERTY_TYPE_LOT &&
          dealSubmission?.lastCalculationParams?.propertyType !==
            PROPERTY_TYPE_LAND && (
            <div className="form-item">
              <Controller
                name="rehabCost"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <ZorbaInput
                    label="Estimated rehab cost"
                    type="number"
                    placeholder="1000"
                    field={field}
                    addonBefore="$"
                    symbol={null}
                    help={errors.rehabCost ? 'Required' : ''}
                  />
                )}
              />
            </div>
          )}
        <div className="form-item">
          <Controller
            name="relationship"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <ZorbaSelect
                label="Relationship to the property*"
                onChange={field.onChange}
                value={field.value}
                options={[
                  { text: 'Owner', value: 'owner' },
                  { text: 'Realtor / Agent', value: 'agent' },
                  {
                    text: 'Wholesaler (direct to seller)',
                    value: 'wholesaler_direct',
                  },
                  {
                    text: 'Wholesaler (3rd party)',
                    value: 'wholesaler_daisy_chain',
                  },
                ]}
                help={errors.relationship ? 'Required' : ''}
              />
            )}
          />
        </div>
        {dealSubmission?.lastCalculationParams?.propertyType !==
          PROPERTY_TYPE_LOT &&
          dealSubmission?.lastCalculationParams?.propertyType !==
            PROPERTY_TYPE_LAND && (
            <div className="form-item">
              <Controller
                name="occupancy"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <ZorbaSelect
                    label="Occupancy at closing*"
                    onChange={field.onChange}
                    value={field.value}
                    options={[
                      { text: 'Owner occupied', value: 'owner_occupied' },
                      { text: 'Tenant occupied', value: 'tenant_occupied' },
                      { text: 'Vacant', value: 'vacant' },
                    ]}
                    help={errors.occupancy ? 'Required' : ''}
                  />
                )}
              />
            </div>
          )}
        {userDetails?.email === 'acquisitions@getzorba.com' && (
          <>
            <div className="form-item">
              <Controller
                name="isFlip"
                control={control}
                render={({ field }) => (
                  <ZorbaSelect
                    label="Is flip opportunity?"
                    onChange={field.onChange}
                    value={field.value}
                    options={[
                      { text: 'Yes', value: true },
                      { text: 'No', value: false },
                    ]}
                  />
                )}
              />
            </div>
          </>
        )}
      </div>
      {dealSubmission?.lastCalculationParams?.propertyType !==
        PROPERTY_TYPE_LOT &&
        dealSubmission?.lastCalculationParams?.propertyType !==
          PROPERTY_TYPE_LAND && (
          <>
            <Divider />
            <div className="items">
              <div className="form-item">
                <Controller
                  name="hoaFees"
                  control={control}
                  rules={{ validate: validateRequired }}
                  render={({ field }) => (
                    <ZorbaSelect
                      label="Are there any HOA fees?*"
                      field={field}
                      options={[
                        {
                          text: 'Yes',
                          value: true,
                        },
                        { text: `No`, value: false },
                      ]}
                      help={errors.hoaFees ? 'Required' : ''}
                    />
                  )}
                />
              </div>
              {watchHoaFees && (
                <>
                  <div className="form-item">
                    <Controller
                      name="hoaPeriod"
                      control={control}
                      rules={{ required: watchHoaFees }}
                      render={({ field }) => (
                        <ZorbaSelect
                          label="HOA Frequency*"
                          fullWidth
                          onChange={field.onChange}
                          value={field.value}
                          options={[
                            { text: 'Monthly', value: 'month' },
                            { text: 'Quarterly', value: 'quarter' },
                            { text: 'Annually', value: 'annual' },
                          ]}
                          help={errors.hoaPeriod ? 'Required' : ''}
                        />
                      )}
                    />
                  </div>
                  {watchHoaFees && (
                    <div className="form-item">
                      <Controller
                        name="hoaFee"
                        control={control}
                        rules={{ required: watchHoaFees, min: 1 }}
                        render={({ field }) => (
                          <ZorbaInput
                            label="HOA Amount*"
                            type="number"
                            placeholder="1000"
                            field={field}
                            addonBefore="$"
                            symbol={null}
                            help={errors.hoaFee ? 'Required' : ''}
                          />
                        )}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}
      <Divider />
      {/* UNDER CONTRACT QUESTION */}
      <div className="items">
        <div className="form-item full">
          <Controller
            name="isUnderContract"
            control={control}
            render={({ field }) => (
              <ZorbaSelect
                label="Are you able to sign a PSA today?*"
                options={[
                  {
                    text: 'Yes',
                    value: true,
                  },
                  { text: `Not yet `, value: false },
                ]}
                field={field}
                help={errors.isUnderContract ? 'Required' : ''}
              />
            )}
          />
        </div>
      </div>
      {dealSubmission?.lastCalculationParams?.propertyType !==
        PROPERTY_TYPE_LOT &&
        dealSubmission?.lastCalculationParams?.propertyType !==
          PROPERTY_TYPE_LAND && (
          <>
            <Divider />
            {/* UNDER CONTRACT QUESTION */}
            <div className="items">
              <div className="form-item full calendar-container">
                <Controller
                  name="inspectionAvailability1"
                  control={control}
                  render={({ field }) => (
                    <ZorbaDatePicker
                      field={field}
                      formClassName="full-width"
                      label="When can we walk the property?"
                      showTime
                    />
                  )}
                />
              </div>
              {watchInspectionAvailability1 && (
                <div className="form-item full calendar-container">
                  <Controller
                    name="inspectionAvailability2"
                    control={control}
                    render={({ field }) => (
                      <ZorbaDatePicker
                        field={field}
                        formClassName="full-width"
                        label=""
                        showTime
                      />
                    )}
                  />
                </div>
              )}
              <span>
                Please provide at least one available time slot for us to
                inspect the property. If you have a preferred time, feel free to
                fill it in, otherwise, you can leave it blank. Our inspection
                typically takes less than an hour.
              </span>
            </div>
          </>
        )}
      <Divider />
      <div className="buttons">
        <ZorbaButton
          fullWidth={false}
          htmlType="submit"
          variant={BUTTON_VARIANT_LINK}
          className="generateOfferButton"
          disabled={loading}
          onClick={() => props.onReset()}
        >
          Cancel
        </ZorbaButton>
        {watchIsUnderContract ? (
          <ZorbaButton
            loading={loading}
            fullWidth={false}
            htmlType="submit"
            className="generateOfferButton"
            disabled={loading}
          >
            Continue
          </ZorbaButton>
        ) : (
          <Alert
            message="Please continue only when you are able to accept an offer"
            type="warning"
            showIcon
          />
        )}
      </div>
    </form>
  );
};
