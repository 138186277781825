import * as types from './types';

const initialState = {
  isFetchingUsersToInvite: false,
  usersToInvite: [],
  currentPage: 0,
  hasMoreResults: false,
  total: 0,
  modalStage: 1,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.SET_USERS_TO_INVITE:
    case types.IS_FETCHING_USERS_TO_INVITE:
    case types.SET_CURRENT_PAGE:
    case types.SET_MODAL_STAGE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
