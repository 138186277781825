import { AiFillCheckCircle } from '@react-icons/all-files/ai/AiFillCheckCircle';
import { BUTTON_VARIANT_SECONDARY } from '../../../../../../components/forms/ZorbaButton/constants';
import { ZorbaButton } from '../../../../../../components/forms';
import './index.scss';

export default (props) => {
  const { preDeal } = props;
  if (!preDeal) return null;

  const isValid = !preDeal?.reasons?.length;

  return (
    <div
      className={`submitted-to-underwriting-step ${
        isValid ? 'valid' : 'invalid'
      }`}
    >
      <AiFillCheckCircle />
      <div className="title">Thank you!</div>
      <p>
        Our underwriting team received the info about the property. You will get
        our initial cash offer for this property within 12-72 hours.
      </p>
      <p>
        Our goal is to make sure our offer would be as accurate as possible
        because we always try to avoid price reductions. Please monitor your
        email and phone for any questions from our team.
      </p>

      <div className="buttons">
        <div className="button-wrapper">
          <ZorbaButton onClick={() => props.onReset()}>
            Submit another property
          </ZorbaButton>
        </div>
        <div className="button-wrapper">
          <ZorbaButton
            variant={BUTTON_VARIANT_SECONDARY}
            onClick={() => props.onReset()}
          >
            Back to dashboard
          </ZorbaButton>
        </div>
      </div>
    </div>
  );
};
