import {
  getFriendlyTime,
  getPriceText,
} from '@zorba-shared/core/Services/textService';

export const mapDealSubmissionStepToTitle = {
  confirm_details: 'Confirm details',
  draft: 'Draft',
  missing_info: 'Missing info from the Seller',
  review: 'Underwriting',
  revised_offer: 'Buyer submitted an offer',
  pending_agreement: 'Pending agreement',
  pending_inspection: 'Pending inspection',
  closing: 'Closing',
  rejected_by_uw: 'Rejected by UW',
  rejected_by_seller: 'Rejected by Seller',
  rejected_by_buyer: 'Rejected by Buyer',
};
export const getTableColumns = () => [
  {
    title: 'Submitted At',
    key: 'submittedAt',
    render: ({ submittedAt }) => getFriendlyTime(submittedAt),
  },
  {
    title: 'Submitted By',
    key: 'submittedBy',
    render: ({ submittedBy, email }) => `${submittedBy} ${email}`,
  },

  {
    title: 'Phone Number',
    key: 'phoneNumber',
    render: ({ phoneNumber }) => phoneNumber,
  },
  {
    title: 'Asking Price',
    key: 'askingPrice',
    render: ({ askingPrice }) => getPriceText(askingPrice),
  },
  {
    title: 'Status',
    key: 'status',
    render: ({ status }) => mapDealSubmissionStepToTitle[status],
  },
  {
    title: 'Hubspot stage',
    key: 'hubspotStage',
    render: ({ hubspotDealStage }) => hubspotDealStage,
  },
];
