import { Tag, Tooltip } from 'antd';
import { BsDot } from '@react-icons/all-files/bs/BsDot';
import { BsCheckCircle } from '@react-icons/all-files/bs/BsCheckCircle';

export const PREDEFINED_VIEWS = {
  PENDING_ACTION: 'pending_action',
  MISSING_INFO: 'missing_info',
  ALL_DEALS: 'all_deals',
};

export const DEAL_TABS = {
  YOUR_INFO: 'your_info',
  UNDERWRITING_RESULT: 'underwriting_result',
  OUR_OFFER: 'our_offer',
};

export const getStatusTag = (step) => {
  switch (step) {
    case 'draft':
    case 'confirm_details':
      return (
        <Tooltip title="We are waiting for you to fill the information about the property in order for us to underwrite and send you an official offer">
          <Tag style={{ color: '#12151e', backgroundColor: '#ecf0f3' }}>
            <div>
              <BsDot />
              <div>Draft</div>
            </div>
          </Tag>
        </Tooltip>
      );
    case 'missing_info':
      return (
        <Tooltip title="We are waiting for you to upload the PSA in order for us to underwrite and send you an official offer">
          <Tag style={{ color: '#976c39', backgroundColor: '#fbebbe' }}>
            <div>
              <BsDot />
              <div>Missing info</div>
            </div>
          </Tag>
        </Tooltip>
      );
    case 'review':
      return (
        <Tooltip title="Our underwriters are reviewing your deal using all the information you provided. You will receive an email and a text as soon as the offer is ready.">
          <Tag style={{ color: '#1840a2', backgroundColor: '#cbe3fc' }}>
            <div>
              <BsDot />
              <div>Under review</div>
            </div>
          </Tag>
        </Tooltip>
      );
    case 'rejected_by_uw':
      return <Tag style={{ color: '#CB6060' }}>Rejected by Zorba</Tag>;
    case 'rejected_by_buyer':
      return <Tag style={{ color: '#CB6060' }}>Rejected by Zorba</Tag>;
    case 'rejected_by_seller':
      return <Tag style={{ color: '#CB6060' }}>Rejected by you</Tag>;
    case 'revised_offer':
      return (
        <Tag style={{ color: '#265c4f', backgroundColor: '#bfead8' }}>
          <div>
            <BsCheckCircle />
            <div>Ready for contract</div>
          </div>
        </Tag>
      );
    case 'pending_agreement':
      return (
        <Tooltip title="Our transaction coordination team received your request and will contact you soon to send you our official offer. Please make sure to be responsive via email/SMS">
          <Tag style={{ color: '#265c4f', backgroundColor: '#bfead8' }}>
            <div>
              <BsDot />
              <div>Pending agreement</div>
            </div>
          </Tag>
        </Tooltip>
      );
    case 'pending_inspection':
      return (
        <Tooltip title="Our transaction coordination team has received your request and will contact you shortly to schedule the inspection. Please ensure that you are responsive via email or SMS.">
          <Tag style={{ color: '#265c4f', backgroundColor: '#bfead8' }}>
            <div>
              <BsDot />
              <div>Pending inspection</div>
            </div>
          </Tag>
        </Tooltip>
      );
    case 'closing':
      return (
        <Tag style={{ color: '#265c4f', backgroundColor: '#bfead8' }}>
          <div>
            <BsCheckCircle />
            <div>Closed won</div>
          </div>
        </Tag>
      );
    case 'closed_won':
      return (
        <Tag style={{ color: '#265c4f', backgroundColor: '#bfead8' }}>
          <div>
            <BsCheckCircle />
            <div>Closed won</div>
          </div>
        </Tag>
      );
    default:
      return null;
  }
};
