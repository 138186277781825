import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Table } from 'antd';
import { isMobile } from '@zorba-shared/client/Services/clientService';
import { getTableColumns, PREDEFINED_VIEWS } from './constants';
import {
  fetchPreDeals,
  setCurrentPage,
  setPageSize,
} from '../../../../reducers/PreDeal/actions';
import {
  resetAppliedFilters,
  setAppliedFilters,
} from '../../../../reducers/Property/actions';
import { PreDealsFilters } from './Components/PreDealsFilters';
import { setDrawerState } from '../../../../reducers/Drawer/actions';
import { DEAL_FILTERS_DRAWER } from '../../../../reducers/Drawer/types';
import FiltersDrawer from '../../../DealCheckerPage/Components/FiltersDrawer';
import { EmptyState } from './Components/EmptyState';
import { OfferDetails } from './Components/OfferDetails';

export const MyPreDeals = ({
  form,
  predefinedView,
  setPredefinedView,
  avoidRefetch,
  updatePreDeal,
}) => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const [isContractLoading, setIsContractLoading] = useState(false);
  const {
    loading,
    preDeals,
    currentPage,
    pageSize,
    totalDealsFiltered,
  } = useSelector(({ PreDealReducer }) => PreDealReducer);
  const { appliedFilters } = useSelector(
    ({ PropertyReducer }) => PropertyReducer,
  );
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const onShowSizeChange = (changedPage, changedCurrentPageSize) => {
    if (changedPage - 1 !== currentPage) {
      dispatch(setCurrentPage({ page: changedPage - 1 }));
    }
    if (pageSize !== changedCurrentPageSize) {
      dispatch(setPageSize({ pageSize: changedCurrentPageSize }));
    }
  };

  const pagination = {
    position: ['bottomRight'],
    onChange: onShowSizeChange,
    total: totalDealsFiltered,
    pageSize,
    current: currentPage + 1,
    showTotal: (totalItems, range) =>
      `${range[0]}-${range[1]} of ${totalItems} items`,
  };

  const scroll = {
    scrollToFirstRowOnChange: true,
    x: isMobile() ? true : 'max-content',
  };

  useEffect(() => {
    if (mounted) {
      dispatch(fetchPreDeals({ page: currentPage, pageSize, appliedFilters }));
    } else {
      setMounted(true);
    }
  }, [currentPage, pageSize, appliedFilters]);

  useEffect(() => {
    if (preDeals?.length) {
      const rowsToExpand = preDeals
        .filter((deal) => deal.step === 'revised_offer')
        .map((deal) => deal._id);
      setExpandedRowKeys(rowsToExpand);
    }
  }, [preDeals]);

  const openFiltersDrawer = () => {
    dispatch(setDrawerState(DEAL_FILTERS_DRAWER, true));
  };

  useEffect(() => {
    if (predefinedView === PREDEFINED_VIEWS.PENDING_ACTION && !avoidRefetch) {
      form.resetFields();
      dispatch(resetAppliedFilters());
      dispatch(
        setAppliedFilters('step', {
          isAnyOf: ['revised_offer'],
        }),
      );
    }
    if (predefinedView === PREDEFINED_VIEWS.MISSING_INFO && !avoidRefetch) {
      form.resetFields();
      dispatch(resetAppliedFilters());
      dispatch(
        setAppliedFilters('step', {
          isAnyOf: ['draft', 'missing_info', 'confirm_details'],
        }),
      );
    }
    if (predefinedView === PREDEFINED_VIEWS.ALL_DEALS) {
      dispatch(resetAppliedFilters(false));
    }
  }, [predefinedView]);

  if (preDeals.length === 0 && !loading) {
    return (
      <EmptyState
        appliedFilters={appliedFilters}
        openFiltersDrawer={openFiltersDrawer}
        setPredefinedView={setPredefinedView}
        predefinedView={predefinedView}
        totalDealsFiltered={totalDealsFiltered}
        form={form}
      />
    );
  }

  return (
    <>
      <Card className="my-properties" bordered={!isMobile()}>
        <PreDealsFilters
          openFiltersDrawer={openFiltersDrawer}
          appliedFilters={appliedFilters}
          setPredefinedView={setPredefinedView}
          predefinedView={predefinedView}
          totalDealsFiltered={totalDealsFiltered}
          form={form}
        />
        <Table
          rowKey="_id"
          loading={loading}
          className="my-pre-deals-table"
          columns={getTableColumns(updatePreDeal)}
          dataSource={preDeals}
          pagination={pagination}
          scroll={scroll}
          expandable={{
            expandedRowKeys,
            onExpand: (expandable, record) => {
              if (expandable) {
                setExpandedRowKeys([...expandedRowKeys, record._id]);
              } else {
                setExpandedRowKeys(
                  expandedRowKeys.filter((id) => record._id !== id),
                );
              }
            },
            expandedRowRender: (record) => {
              if (record?.step === 'revised_offer') {
                return (
                  <OfferDetails
                    record={record}
                    isContractLoading={isContractLoading}
                    setIsContractLoading={setIsContractLoading}
                  />
                );
              }

              return '';
            },
            rowExpandable: (record) => record?.step === 'revised_offer',
          }}
        />
      </Card>
      <FiltersDrawer setPredefinedView={setPredefinedView} />
    </>
  );
};
