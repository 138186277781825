import { useLocation, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Tag } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { isMobile } from '@zorba-shared/client/Services/clientService';
import { Page } from '../../components/layout';
import { reportScreen, reportEvent } from '../../services/analyticsService';
import DealCheckerPage from '../../containers/DealCheckerPage';
import PreDealCheckerPage from '../../containers/PreDealCheckerPage';
import { TABS } from './constants';
import { fetchPendingActions } from '../../reducers/Property/actions';
import './index.scss';

export default () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    reportScreen('DealcheckerPage');
  }, []);
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab') || TABS.ACTIVE_DEALS;
  const [selectedTab, setSelectedTab] = useState(initialTab);

  useEffect(() => {
    const currentParams = new URLSearchParams(location.search);
    if (selectedTab !== currentParams.get('tab')) {
      currentParams.set('tab', selectedTab);
      history.push({
        pathname: location.pathname,
        search: currentParams.toString(),
      });
    }
  }, [selectedTab, history, location]);

  useEffect(() => {
    dispatch(fetchPendingActions());
  }, []);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const { userDetails } = useSelector(({ AuthReducer }) => AuthReducer);
  const { pendingPreDealsCounter, pendingDealSubmissionsCounter } = useSelector(
    ({ PropertyReducer }) => PropertyReducer,
  );

  const isPreDealsFeatureActivated =
    userDetails.defaultOrganization.buyersAppFeatureFlags.preDeals.isActive;

  return (
    <Page
      className="dealchecker-page"
      title={!isMobile() ? 'Dashboard' : ''}
      wide
    >
      {isPreDealsFeatureActivated ? (
        <>
          <div className="deal-checker-tabs">
            <div
              className={`deal-checker-tabs-item ${
                selectedTab === TABS.ACTIVE_DEALS ? 'active' : ''
              }`}
              onClick={() => handleTabClick(TABS.ACTIVE_DEALS)}
            >
              <span className="inventory-title">Inventory</span>
              {pendingDealSubmissionsCounter > 0 && (
                <div className="pending-items">
                  {pendingDealSubmissionsCounter}
                </div>
              )}
            </div>
            <div
              className={`deal-checker-tabs-item ${
                selectedTab === TABS.PRE_LOCK_PROPERTIES ? 'active' : ''
              }`}
              onClick={() => {
                handleTabClick(TABS.PRE_LOCK_PROPERTIES);
                reportEvent('predeal_tab_click', 'load');
              }}
            >
              <span>Pre-lock Inventory</span>
              <Tag color="#FAA245" className="deal-checker-tabs-tag">
                Beta
              </Tag>
              {pendingPreDealsCounter > 0 && (
                <div className="pending-items">{pendingPreDealsCounter}</div>
              )}
            </div>
          </div>
          {selectedTab === TABS.ACTIVE_DEALS && <DealCheckerPage />}
          {selectedTab === TABS.PRE_LOCK_PROPERTIES && <PreDealCheckerPage />}
        </>
      ) : (
        <DealCheckerPage />
      )}
    </Page>
  );
};
