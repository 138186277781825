import { BiHomeAlt } from '@react-icons/all-files/bi/BiHomeAlt';
import { AiOutlineCrown } from '@react-icons/all-files/ai/AiOutlineCrown';
import { FaRegQuestionCircle } from '@react-icons/all-files/fa/FaRegQuestionCircle';
import { MdFilterCenterFocus } from '@react-icons/all-files/md/MdFilterCenterFocus';
import { FaPlayCircle } from '@react-icons/all-files/fa/FaPlayCircle';

export const CRM_SIDEBAR_LINKS = (isPreDealsTrainingWatched) => [
  {
    title: 'Dashboard',
    icon: <BiHomeAlt style={{ fontSize: 30 }} />,
    url: `/`,
  },
  {
    title: 'Revenue share',
    icon: <AiOutlineCrown style={{ fontSize: 30 }} />,
    url: `/referrals`,
  },
  {
    title: 'FAQ',
    icon: <FaRegQuestionCircle style={{ fontSize: 30 }} />,
    url: '/faq',
  },
  {
    title: 'Our buybox',
    icon: <MdFilterCenterFocus style={{ fontSize: 30 }} />,
    url: `/buybox`,
  },
  {
    title: 'How Zorba works',
    icon: <FaPlayCircle style={{ fontSize: 30 }} />,
    url: '/how-it-works',
  },
  ...(isPreDealsTrainingWatched
    ? [
        {
          title: 'Pre-lock Offer™',
          icon: <FaPlayCircle style={{ fontSize: 30 }} />,
          url: '/pre-lock-how-it-works',
        },
      ]
    : []),
];
