import _ from 'lodash';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from '../localStorageService';

const getLocalStorageKey = () => `latest_activity`;

export const clearLatestViewedEntities = () => {
  const key = getLocalStorageKey();
  removeLocalStorageItem(key);
};

export const getLatestViewedEntities = () => {
  const key = getLocalStorageKey();

  const result = getLocalStorageItem(key);
  return result ? JSON.parse(result) : [];
};
