import { BiArrowBack } from '@react-icons/all-files/bi/BiArrowBack';
import { ZorbaButton } from '../../../../../../components/forms';
import { BUTTON_VARIANT_LIGHT } from '../../../../../../components/forms/ZorbaButton/constants';
import { DEAL_FILTER_CONFIG } from '../../constants';

const getBuilderComponent = (selectedCustomFilter, props) => {
  const BuilderComponent = DEAL_FILTER_CONFIG.find(
    (customFilter) => customFilter.value === selectedCustomFilter,
  )?.builder;

  if (!BuilderComponent) {
    return null;
  }

  return <BuilderComponent {...props} />;
};
export const FiltersBuilder = ({
  handleBack,
  selectedFilter,
  onApplyFilter,
}) => (
  <div>
    <ZorbaButton
      fullWidth={false}
      onClick={handleBack}
      icon={<BiArrowBack />}
      variant={BUTTON_VARIANT_LIGHT}
      className="btn-filter-back"
    >
      Back
    </ZorbaButton>
    {getBuilderComponent(selectedFilter, { onApplyFilter })}
  </div>
);
